import React from "react";
import AppContext from "../../../context/store";

import { Grid, Column } from "../../../theme/Grid";
import Table from "../../../theme/Table";
import AuthContainer from "../../../components/AuthContainer";
import Axios from "axios";
import ExcelButton from "../../../components/Buttons/ExcelButton";
import RoundedRow from "../../../components/Table/RoundedRow";
import FilterObject from "../../../functions/FilterObject";
import Button from "../../../theme/Button";
import { toast } from "react-toastify";
import { phoneNumberDisplay } from "../../../functions/PhoneNumberDisplay";
import Loader from "../../../assets/images/loader.gif";
import ModalDialog from "../../../theme/CustomMUI/AlertDialog";
import AlertDialog from "../../../theme/CustomMUI/AlertDialog";
import { Alert } from "@material-ui/lab";

class PassiveCustomersIndex extends React.Component {
  static contextType = AppContext;

  state = {
    loaded: false,
    pagination: {
      total_page: 1,
      page: 1,
      onChange: (page) => this.getCustomers(page),
    },
    data: [],

    selectedAllCustomer: false,

    selectedCustomers: [],

    delete_confirm: false,
    delete_all_confirm: false,
  };

  getCustomers = (page = 1) => {
    Axios.get(`${this.context.api_endpoint}/company/customers`, {
      params: { page, is_passive: true, extra: 1 },
    })
      .then(({ data }) => {
        this.setState({
          loaded: true,
          data: [
            ...data.data.records.map((item) => {
              return {
                ...item,
                phone:
                  this.context.state.user.permission === 2 &&
                  this.context.state.limited_permission.customer_info === 0
                    ? phoneNumberDisplay(item.phone)
                    : `0${item.phone.substring(0, 3)} ${item.phone.substring(
                        3,
                        6
                      )} ${item.phone.substring(6, 10)}`,
                extra_detail: {
                  ...item.extra_detail,
                  sum_income: (
                    <RoundedRow color="green">
                      {item.extra_detail.sum_income
                        ? item.extra_detail.sum_income.toFixed(2)
                        : "0.00"}
                      ₺
                    </RoundedRow>
                  ),
                  total_debt: (
                    <RoundedRow color="red">
                      {item.extra_detail.total_debt
                        ? item.extra_detail.total_debt.toFixed(2)
                        : "0.00"}
                      ₺
                    </RoundedRow>
                  ),
                },
              };
            }),
          ],
          // pagination: {
          //   ...this.state.pagination,
          //   total_page: data.data.records.length,
          //   page: data.data.page,
          // },
        });
      })
      .catch((e) => {
        console.log(e);
        // if (e.response.status === 401) {
        //   toast.error(e.response.data.message);
        // } else {
        //   toast.warning(e.response.data.message);
        // }
      });
  };

  makeSelectedCustomersActive() {
    Axios.put(`${this.context.api_endpoint}/company/customer/state`, {
      customer_id: [...this.state.selectedCustomers],
    })
      .then((response) => {
        if (response.status === 201) {
          this.setState({
            selectedCustomers: [],
          });
          toast.success(
            `${this.context.t(
              `['customers/passive'].selectedCustomersSuccessToast`
            )}`
          );
          this.getCustomers();
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  }

  makeAllCustomersActive() {
    Axios.put(
      `${this.context.api_endpoint}/company/customer/make/active/multiple`,
      {
        customer_id: [-1],
      }
    )
      .then((response) => {
        if (response.status === 201) {
          toast.success(
            `${this.context.t(
              `['customers/passive'].allCustomersSuccessToast`
            )}`
          );
          this.getCustomers();
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  }

  makeCustomersDelete = () => {
    Axios.post(
      `${this.context.api_endpoint}/company/customer/delete/multiple`,
      {
        customer_id: [...this.state.selectedCustomers],
        is_unscoped: true,
      }
    )
      .then((response) => {
        if (response.status === 200) {
          toast.success("Seçilen müşteriler başarıyla silindi.");
          this.getCustomers(1);
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(
            "Aktif randevusu bulunmayan müşteriler silinmiştir. Aktif randevusu bulunan müşterileri silmek istiyorsanız,aktif randevularını iptal etmelisiniz."
          );
          this.getCustomers(1);
        }
      });
  };

  makeAllCustomersDelete = async () => {
    await Axios.post(
      `${this.context.api_endpoint}/company/customer/delete/multiple`,
      {
        customer_id: [-1],
        is_unscoped: true,
      }
    )
      .then((response) => {
        if (response.status === 200) {
          toast.success("Bütün müşteriler başarıyla silindi.");
          this.getCustomers(1);
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
          this.getCustomers(1);
        } else {
          toast.warning(
            "Aktif randevusu bulunmayan müşteriler silinmiştir. Aktif randevusu bulunan müşterileri silmek istiyorsanız,aktif randevularını iptal etmelisiniz."
          );
          this.getCustomers(1);
        }
      });
  };

  componentDidMount() {
    this.getCustomers();
  }

  tableDataParser() {
    const dataParser = {
      id: { label: this.context.t(`customers.headings.code`) },
      full_name: {
        label: this.context.t(`customers.headings.fullName`),
        sortable: {
          0: [
            { field: "`customers`.`name`", order: "ASC" },
            { field: "`customers`.`surname`", order: "ASC" },
          ],
          1: [
            { field: "`customers`.`name`", order: "DESC" },
            { field: "`customers`.`surname`", order: "DESC" },
          ],
        },
      },
      phone: {
        label: this.context.t(`customers.headings.phone`),
        style: { width: 200 },
      },
      sex: { label: this.context.t(`customers.headings.sex`) },
      "extra_detail.sum_income": {
        label: this.context.t(`customers.headings.incomeAll`),
        sortable: {
          0: [{ field: "`rp`.`amount`", order: "ASC" }],
          1: [{ field: "`rp`.`amount`", order: "DESC" }],
        },
      },
      "extra_detail.total_debt": {
        label: this.context.t(`customers.headings.debtAll`),
      },
      _: { label: this.context.t(`component.actionHeadingText`) },
    };

    return FilterObject(
      dataParser,
      (key, val) =>
        (this.context.current_flag & this.context.state.FLAGS.SALON_RANDEVU &&
          true) ||
        (key !== "extra_detail.sum_income" && key !== "extra_detail.total_debt")
    );
  }

  render() {
    return (
      <AuthContainer authorities={[1]} limited_permission="customer">
        <Grid className="no-gutters-all">
          <Column className="xs-12 sm-12 md-12 lg-12">
            <div
              style={{
                width: "100%",
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              <Column className="xs-12 sm-12 md-12 lg-2">
                <ExcelButton
                  route="customers"
                  requestData={{ is_excel: true, is_passive: true }}
                />
              </Column>

              <Column className="xs-12 sm-12 md-12 lg-2">
                <Button
                  title={
                    this.state.selectedAllCustomer
                      ? this.context.t(
                          `['customers/risky'].cancelAllButtonTitle`
                        )
                      : this.context.t(
                          `['customers/risky'].selectedAllButtonTitle`
                        )
                  }
                  icon={this.state.selectedAllCustomer ? "close" : "check"}
                  backgroundColor={
                    this.state.selectedAllCustomer ? "red" : "primary"
                  }
                  textColor="white"
                  fullWidth
                  onClick={() => {
                    this.setState({
                      selectedAllCustomer: !this.state.selectedAllCustomer,
                    });
                    if (this.state.selectedAllCustomer) {
                      this.setState({
                        selectedCustomers: [],
                      });
                    } else {
                      this.setState({
                        selectedCustomers: this.state.data.map(
                          (item) => item.id
                        ),
                      });
                    }
                  }}
                />
              </Column>

              <Column className="xs-12 sm-12 md-12 lg-2">
                <Button
                  title={"Seçilen Müşteriyi Sil"}
                  icon="delete"
                  backgroundColor="red"
                  textColor="white"
                  fullWidth
                  disabled={this.state.selectedCustomers.length === 0}
                  onClick={() => this.setState({ delete_confirm: true })}
                />
              </Column>

              <Column className="xs-12 sm-12 md-12 lg-2">
                <Button
                  title={"Tüm Müşterileri Sil"}
                  icon="delete"
                  backgroundColor="red"
                  textColor="white"
                  fullWidth
                  disabled={this.state.data.length === 0}
                  onClick={() => {
                    this.setState({
                      delete_all_confirm: true,
                    });
                  }}
                />
              </Column>

              <Column className="xs-12 sm-12 md-12 lg-2">
                <Button
                  title={this.context.t(
                    `['customers/passive'].makeActiveButtons.selectedButtonTitle`
                  )}
                  icon="check"
                  backgroundColor="primary"
                  textColor="white"
                  fullWidth
                  disabled={this.state.selectedCustomers.length === 0}
                  onClick={() => {
                    this.makeSelectedCustomersActive();
                  }}
                />
              </Column>

              <Column className="xs-12 sm-12 md-12 lg-2">
                <Button
                  title={this.context.t(
                    `['customers/passive'].makeActiveButtons.allButtonTitle`
                  )}
                  icon="check"
                  backgroundColor="primary"
                  textColor="white"
                  fullWidth
                  disabled={this.state.data.length === 0}
                  onClick={() => {
                    this.makeAllCustomersActive();
                  }}
                />
              </Column>
            </div>
          </Column>
        </Grid>
        <ModalDialog
          open={this.state.delete_confirm}
          title={this.context.t(`component.CADialog.delete.title`)}
          closeHandler={() => this.setState({ delete_confirm: false })}
          buttons={[
            {
              title: this.context.t(
                `component.CADialog.delete.confirmButtonTitle`
              ),
              icon: "check",
              backgroundColor: "primary-opacity",
              textColor: "primary",

              onClick: () => this.makeCustomersDelete(),
            },
            {
              title: this.context.t(
                `component.CADialog.delete.discardButtonTitle`
              ),
              icon: "close",
              textColor: "grey",
            },
          ]}
        >
          <Alert severity="warning">
            <b>{this.context.t(`component.CADialog.delete.alertText`)}</b>
            <br />
            {this.context.t(`component.CADialog.delete.alertTitleText`)}
          </Alert>
        </ModalDialog>

        <AlertDialog
          open={this.state.delete_all_confirm}
          title={this.context.t(`component.CADialog.all_delete.title`)}
          closeHandler={() => this.setState({ delete_all_confirm: false })}
          buttons={[
            {
              title: this.context.t(
                `component.CADialog.delete.confirmButtonTitle`
              ),
              icon: "check",
              backgroundColor: "primary-opacity",
              textColor: "primary",
              timer: 5,
              onClick: () => this.makeAllCustomersDelete(),
            },
            {
              title: this.context.t(
                `component.CADialog.delete.discardButtonTitle`
              ),
              icon: "close",
              textColor: "grey",
            },
          ]}
        >
          <Alert severity="warning">
            <b>{this.context.t(`component.CADialog.delete.alertText`)}</b>
            <br />
            {this.context.t(`component.CADialog.all_delete.alertTitleText`)}
          </Alert>
        </AlertDialog>
        <Grid>
          {!this.state.loaded ? (
            <div
              style={{
                textAlign: "center",
                marginTop: "5vh",
                width: "100%",
              }}
            >
              <img src={Loader} height={70} />
            </div>
          ) : (
            <Column className="xs-12 sm-12">
              <Table
                isExist={false}
                loaded={this.state.loaded}
                headings={this.tableDataParser()}
                rows={this.state.data}
                replacements={{
                  sex: {
                    null: this.context.t(`customers.replacements.sex.undef`),
                    0: this.context.t(`customers.replacements.sex.female`),
                    1: this.context.t(`customers.replacements.sex.male`),
                  },
                }}
                pagination={this.state.pagination}
                checkedCheckboxes={this.state.selectedCustomers}
                checkboxOnChange={(row, state) => {
                  if (state) {
                    this.setState({
                      selectedCustomers: [
                        ...this.state.selectedCustomers,
                        row.id,
                      ],
                    });
                  } else {
                    this.setState({
                      selectedCustomers: [
                        ...this.state.selectedCustomers.filter(
                          (id) => id !== row.id
                        ),
                      ],
                    });
                  }
                }}
                buttons={[
                  {
                    title: this.context.t(`component.detailEditButtonTitle`),
                    icon: "launch",
                    textColor: "primary",
                    transitionEffect: true,
                    pushEffect: true,
                    onClick: (row) =>
                      this.props.history.push(`/customers/detail/${row.id}`),
                  },
                ]}
              />
            </Column>
          )}
        </Grid>
      </AuthContainer>
    );
  }
}

export default PassiveCustomersIndex;
