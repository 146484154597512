import React from "react";
import AppContext from "../../../context/store";
import AuthContainer from "../../../components/AuthContainer";

import { Grid, Column } from "../../../theme/Grid";
import Table from "../../../theme/Table";
// import MaskedInput from "../../../theme/CustomMUI/MaskedInput";
import Input from "../../../theme/CustomMUI/Input";
import Button from "../../../theme/Button";
import Select from "../../../theme/CustomMUI/Select";
import CardSection from "../../../components/Cards/CardSection";
import Axios from "axios";
import { toast } from "react-toastify";
import moment from "moment";
import Validate from "../../../functions/Validate";
import ExcelButton from "../../../components/Buttons/ExcelButton";
import CurrencyFormat from "react-currency-format";
import ConvertTimeToZulu from "../../../functions/ConvertTimeToZulu";
import DatePickerInput from "../../../theme/CustomMUI/DatePickerInput";
import { flushSync } from "react-dom";
import AlertDialog from "../../../theme/CustomMUI/AlertDialog";
import { Alert } from "@material-ui/lab";
import { Add, DateRange } from "@material-ui/icons";
import RemainingDebt from "../../../assets/images/svg/remaining_debt.svg";
import styled from "styled-components";
import Loader from "../../../assets/images/loader.gif";
// import { DATE } from "@amcharts/amcharts4/core";

class ExpenseTransactions extends React.Component {
  static contextType = AppContext;

  state = {
    add_new: {
      json_id: null,
      description: null,
      amount: null,
      transaction_date: ConvertTimeToZulu(moment().unix()), //now
    },
    payment: [
      {
        label: "Nakit",
        value: "0",
      },
      {
        label: "Kredi / Banka Kartı",
        value: "1",
      },
      {
        label: "Havale/EFT",
        value: "2",
      },
      {
        label: "Müşteri Puanı",
        value: "3",
      },
    ],
    selected_payment: null,
    spender: "",

    types_loaded: false,
    expense_types: [],

    loaded: false,
    expense_transactions: {
      replacements: {
        json_id: {},
      },
      pagination: {
        page: 1,
        total_page: 1,
        onChange: (page) => this.getExpenseData(page),
      },
      data: [],
    },
    start: this.props.location.state
      ? `${this.props.location.state?.detail.start.split("/")[2]}-${
          this.props.location.state?.detail.start.split("/")[1]
        }-${this.props.location.state?.detail.start.split("/")[0]}`
      : moment().subtract(30, "days").format("YYYY-MM-DD"),
    end: this.props.location.state
      ? `${this.props.location.state?.detail.end.split("/")[2]}-${
          this.props.location.state?.detail.end.split("/")[1]
        }-${this.props.location.state?.detail.end.split("/")[0]}`
      : moment().format("YYYY-MM-DD"),
    reportsDate: {
      between: {
        start: this.props.location.state
          ? `${this.props.location.state?.detail.start.split("/")[0]}/${
              this.props.location.state?.detail.start.split("/")[1]
            }/${this.props.location.state?.detail.start.split("/")[2]}`
          : moment().subtract(1, "days").format("DD/MM/YYYY"),
        end: this.props.location.state
          ? `${this.props.location.state?.detail.end.split("/")[0]}/${
              this.props.location.state?.detail.end.split("/")[1]
            }/${this.props.location.state?.detail.end.split("/")[2]}`
          : moment().format("DD/MM/YYYY"),
      },
    },
    filterPicker: [
      {
        id: 1,
        label: "Bugün",
        offset: 1,
      },
      {
        id: 2,
        label: "Bu Hafta",
        offset: 3,
      },
      {
        id: 3,
        label: "Bu Ay",
        offset: 4,
      },
    ],

    selected_filter_picker: this.props.location.state
      ? null
      : {
          id: 1,
          label: "Bugün",
          offset: 1,
        },

    isInsertNewExpenseButtonClicked: false, // Control that button is checked to stop sending too request.

    delete_confirm: false,

    delete_row_id: null,

    new_expense_dialog_modal: false,

    total_expense: null,

    comingData: this.props.location.state ? this.props.location.state : null,
  };

  constructor(props) {
    super(props);

    this.rAmount = React.createRef();
  }

  //TODO : Get expense types from server
  getExpenseTypes = () => {
    Axios.get(`${this.context.api_endpoint}/company/expense/categories`)
      .then(({ data }) => {
        const types = JSON.parse(data.data.name);
        flushSync(() => {
          this.setState({
            expense_transactions: {
              ...this.state.expense_transactions,
              replacements: {
                json_id: { ...types },
              },
            },

            // eslint-disable-next-line array-callback-return
            expense_types:
              types !== undefined && types !== null
                ? [
                    ...Object.keys(types).map((key) => {
                      if (types[key] !== null && types[key] !== "") {
                        return { json_id: key, label: types[key] };
                      } else {
                        return undefined;
                      }
                    }),
                  ]
                : [],
          });
        });
      })
      .then(() => {
        flushSync(() => {
          this.setState({
            types_loaded: true,
            expense_types: this.state.expense_types.filter(
              (item) => item !== undefined
            ),
          });
        });
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  //TODO : List of Expense Transactions
  getExpenseData = (page) => {
    this.setState({ loaded: false });
    const { selected_filter_picker } = this.state;
    Axios.get(
      `${this.context.api_endpoint}/company/accounting/expenses`,
      selected_filter_picker === null
        ? {
            params: {
              page: page ? page : 1,
              isbetween: true,
              start: moment(this.state.start).format("YYYY-MM-DD"),
              end: moment(this.state.end).format("YYYY-MM-DD"),
            },
          }
        : {
            params: {
              page: page ? page : 1,
              isbetween: false,
              offset: selected_filter_picker.offset,
            },
          }
    )
      .then(({ data }) => {
        this.setState({
          loaded: true,
          reportsDate: {
            between: {
              start: moment(data.data.start_time.split("T")[0])
                .local()
                .format("DD/MM/YYYY"),
              end: moment(data.data.end_time.split("T")[0])
                .local()
                .format("DD/MM/YYYY"),
            },
          },
          expense_transactions: {
            ...this.state.expense_transactions,
            data: [
              ...data.data.records.map((item) => {
                return {
                  ...item,
                  created_at: moment(item.created_at).format("LLL"),
                  transaction_date: moment(item.transaction_date).format("LL"),
                  payment_type:
                    item.payment_type !== null
                      ? this.state.payment.find(
                          (pay) => pay.value === item.payment_type.toString()
                        ).label
                      : "-",
                  spender: item.spender ? item.spender : "",
                };
              }),
            ],
          },
          total_expense: data.data.total_expense,
        });
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  //TODO : Add new expense
  insertNewExpense = () => {
    this.validateNewExpense() &&
      Axios.post(`${this.context.api_endpoint}/company/expense/insert`, {
        ...this.state.add_new,
        payment_type: this.state.selected_payment
          ? parseInt(this.state.selected_payment.value)
          : null,
        spender: this.state.spender ? this.state.spender : "",
      })
        .then((response) => {
          if (response.status === 200) {
            toast.success(this.context.t(`['accounting/expense'].insertToast`));
            this.setState({
              isInsertNewExpenseButtonClicked: false,
            });
            this.getExpenseData();
          }
        })
        .catch((e) => {
          if (e.response.status === 401) {
            toast.error(e.response.data.message);
          } else {
            toast.warning(e.response.data.message);
          }
          this.setState({
            isInsertNewExpenseButtonClicked: false,
          });
        });
  };

  //TODO : Clear Data
  clearData = async () => {
    await this.setState({
      add_new: {
        json_id: null,
        description: "",
        amount: null,
        transaction_date: ConvertTimeToZulu(moment().unix()), //now
      },
      selected_payment: null,
      spender: "",
    });
  };

  //TODO : Delete Expense
  deleteSingleExpense = (expense_id) => {
    Axios.delete(
      `${this.context.api_endpoint}/company/expense/delete/${expense_id}`
    )
      .then((response) => {
        if (response.status === 201) {
          toast.success(this.context.t(`['accounting/expense'].deleteToast`));
          this.getExpenseData();
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  componentDidMount() {
    this.getExpenseTypes();
    this.getExpenseData();
  }

  handleChange(event, maskedvalue, floatvalue) {
    console.log("maskedValue:: ", maskedvalue);
  }

  render() {
    const {
      add_new,
      types_loaded,
      expense_types,
      selected_filter_picker,
      start,
      end,
    } = this.state;
    return (
      <AuthContainer
        makePlanUpgrade={[2]}
        authorities={[1]}
        limited_permission="income_expense"
      >
        <Grid>
          <Column
            className="xs-12 sm-12 md-12 lg-12 mb-1 pb-2"
            style={{ borderBottom: "1px solid #b2b2b2" }}
          >
            <Column
              className="xs-12 sm-12 md-12 lg-6"
              style={{ height: "63px", display: "flex", alignItems: "center" }}
            >
              <ShortButtonContainer>
                <ShortButton
                  onClick={async () => {
                    await this.setState({
                      selected_filter_picker: {
                        id: 1,
                        label: "Bugün",
                        offset: 1,
                      },
                    });
                    await this.getExpenseData();
                  }}
                  style={{
                    backgroundColor: `${
                      selected_filter_picker?.id === 1
                        ? "rgb(49, 169, 243, 0.14)"
                        : ""
                    }`,
                    border: "1px solid rgb(91, 187, 245, 0.41)",
                    color: "rgb(49, 169, 243)",
                  }}
                >
                  Bugün
                </ShortButton>
                <ShortButton
                  onClick={async () => {
                    await this.setState({
                      selected_filter_picker: {
                        id: 2,
                        label: "Bu Hafta",
                        offset: 3,
                      },
                    });
                    await this.getExpenseData();
                  }}
                  style={{
                    backgroundColor: `${
                      selected_filter_picker?.id === 2
                        ? "rgb(29, 139, 62, 0.21)"
                        : ""
                    }`,
                    border: "1px solid rgb(58, 154, 87, 0.35)",
                    color: "rgb(29, 139, 62)",
                  }}
                >
                  Bu Hafta
                </ShortButton>
                <ShortButton
                  onClick={async () => {
                    await this.setState({
                      selected_filter_picker: {
                        id: 3,
                        label: "Bu Ay",
                        offset: 4,
                      },
                    });
                    await this.getExpenseData();
                  }}
                  style={{
                    backgroundColor: `${
                      selected_filter_picker?.id === 3
                        ? "rgb(254, 151, 151, 0.19)"
                        : ""
                    }`,
                    border: "1px solid rgb(185, 178, 178, 0.65)",
                    color: "rgb(254, 111, 111)",
                  }}
                >
                  Bu Ay
                </ShortButton>
              </ShortButtonContainer>
            </Column>
            <Column
              className="xs-12 sm-12 md-12 lg-6"
              style={{ borderLeft: "1px solid #b2b2b2" }}
            >
              <FilterContainer>
                <FilterButton
                  onClick={() => {
                    this.setState({
                      filter_modal_open: true,
                      selected_filter_picker: null,
                      ready_to_use_filter: 1,
                      filter: {
                        start_date: moment()
                          .local()
                          .subtract(1, "days")
                          .toISOString(),
                        end_date: moment().local().toISOString(),
                      },
                      reportsDate: {
                        between: {
                          start: moment()
                            .local()
                            .subtract(1, "days")
                            .format("DD/MM/YYYY"),
                          end: moment().local().format("DD/MM/YYYY"),
                        },
                      },
                    });
                  }}
                >
                  {" "}
                  <DateRange />
                  <span
                    style={{
                      fontWeight: "500",
                      fontSize: "1.2em",
                      paddingLeft: "1vw",
                    }}
                  >
                    {this.state.reportsDate.between.start} -{" "}
                    {this.state.reportsDate.between.end}
                  </span>
                </FilterButton>

                <FilterButton
                  style={{
                    cursor: "auto",
                    backgroundColor: "rgba(208, 233, 233, 0.65)",
                    color: "#54AEAE",
                    border: "1px solid rgba(208, 233, 233, 0.8)",
                  }}
                >
                  <img
                    src={RemainingDebt}
                    alt="remaining_debt"
                    style={{ marginRight: "10px", color: "red" }}
                  />
                  Toplam Gider{" "}
                  <span
                    style={{
                      fontWeight: "500",
                      fontSize: "1.2em",
                      paddingLeft: "1vw",
                    }}
                  >
                    {`${parseInt(this.state.total_expense)
                      .toFixed(2)
                      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}${
                      this.context.state.currency
                        ? this.context.state.currency
                        : "₺"
                    }`}
                  </span>
                </FilterButton>
              </FilterContainer>
            </Column>
          </Column>
        </Grid>

        <Grid>
          <Column
            className="xs-12 sm-12 md-12 lg-12"
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <Column className="xs-12 sm-12 md-12 lg-3">
              <FilterButton
                style={{
                  height: "36px",
                  marginBottom: "0",
                  color: "#3189ec",
                  border: "1px solid #3189ec",
                  background: "rgba(49, 137, 236, 0.08)",
                }}
                onClick={() => {
                  this.setState({
                    new_expense_dialog_modal: true,
                  });
                }}
              >
                <Add />
                <span
                  style={{
                    fontWeight: "500",
                    fontSize: "1.2em",
                    paddingLeft: "1vw",
                  }}
                >
                  Yeni Gider Ekle
                </span>
              </FilterButton>
            </Column>
            <Column className="xs-12 sm-12 md-12 lg-3">
              <ExcelButton
                route="accounting/expenses"
                size="lg"
                fullWidth={true}
                requestData={
                  selected_filter_picker === null
                    ? {
                        start: moment(start).format("YYYY-MM-DD"),
                        end: moment(end).format("YYYY-MM-DD"),
                        isbetween: true,
                        is_excel: true,
                      }
                    : {
                        isbetween: false,
                        offset: selected_filter_picker.offset,
                        is_excel: true,
                      }
                }
              />{" "}
            </Column>
          </Column>
        </Grid>

        <Grid>
          <Column className="xs-12 sm-12">
            <CardSection
              title={this.context.t(`['accounting/expense'].table.title`)}
              className="no-gutters-all"
              includesColumn={false}
            >
              {this.state.loaded ? (
                <Table
                  refreshAction={() =>
                    this.getExpenseData(
                      this.state.expense_transactions.pagination.page
                    )
                  }
                  loaded={this.state.loaded}
                  headings={{
                    created_at: {
                      label: this.context.t(
                        `['accounting/expense'].table.headings.createdAt`
                      ),
                      style: { width: 170 },
                    },
                    transaction_date: {
                      label: this.context.t(
                        `['accounting/expense'].table.headings.transactionDate`
                      ),
                      sortable: {
                        0: [
                          {
                            field: "`expenses`.`transaction_date`",
                            order: "ASC",
                          },
                        ],
                        1: [
                          {
                            field: "`expenses`.`transaction_date`",
                            order: "DESC",
                          },
                        ],
                      },
                    },
                    json_id: {
                      label: this.context.t(
                        `['accounting/expense'].table.headings.type`
                      ),
                    },
                    description: {
                      label: this.context.t(
                        `['accounting/expense'].table.headings.description`
                      ),
                      limited_line: 1,
                      style: { width: 300 },
                    },
                    payment_type: {
                      label: this.context.t(
                        `['accounting/expense'].add.paymentType`
                      ),
                    },
                    spender: {
                      label: this.context.t(
                        `['accounting/expense'].add.spending`
                      ),
                    },
                    amount: {
                      label: this.context.t(
                        `['accounting/expense'].table.headings.amount`
                      ),
                      suffix: this.context.state.currency
                        ? this.context.state.currency
                        : "₺",
                    },
                    _: { label: this.context.t(`component.actionHeadingText`) },
                  }}
                  rows={this.state.expense_transactions.data}
                  pagination={this.state.expense_transactions.pagination}
                  replacements={this.state.expense_transactions.replacements}
                  buttons={[
                    {
                      title: this.context.t(
                        `['accounting/expense'].table.deleteButtonTitle`
                      ),
                      icon: "delete_forever",
                      textColor: "red",
                      onClick: (row) => {
                        this.setState({
                          delete_row_id: row.id,
                          delete_confirm: true,
                        });
                      },
                    },
                  ]}
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                  }}
                >
                  <img src={Loader} width="100" height="100" alt="loading" />
                </div>
              )}
            </CardSection>
          </Column>
        </Grid>

        <AlertDialog
          title={"Yeni Gider"}
          open={this.state.new_expense_dialog_modal}
          closeHandler={() => {
            this.setState({ new_expense_dialog_modal: false });
          }}
          maxWidth="md"
          buttons={[
            {
              title: `${this.context.t(
                `['accounting/expense'].add.buttonTitle`
              )}`,
              icon: "check",
              backgroundColor: "primary",
              textColor: "white",
              fullWidth: true,
              onClick: async () => {
                if (this.state.selected_payment !== null) {
                  this.setState({
                    isInsertNewExpenseButtonClicked: true,
                  });
                  this.insertNewExpense();
                  await this.clearData();
                } else {
                  toast.warning("Lütfen ödeme türünü seçiniz.");
                }
              },
            },
            {
              title: "Vazgeç",
              icon: "close",
              textColor: "#ccc",
              outlined: true,
              onClick: () => {
                this.setState({
                  new_expense_dialog_modal: false,
                });
                this.clearData();
              },
            },
          ]}
        >
          <Grid>
            <Column className="xs-12 sm-12 md-12 lg-12 mb-3">
              <Column className="xs-12 sm-12 md-12 lg-3">
                <Select
                  label={this.context.t(
                    `['accounting/expense'].add.typeInputLabel`
                  )}
                  items={types_loaded ? expense_types : null}
                  labelKey="label"
                  valueKey="json_id"
                  selected={add_new.json_id !== null ? add_new.json_id : ""}
                  handler={(json_id) =>
                    this.setState({
                      add_new: {
                        ...add_new,
                        json_id,
                      },
                    })
                  }
                />
              </Column>

              <Column className="xs-12 sm-12 md-12 lg-3">
                <CurrencyFormat
                  label={this.context.t(
                    `['accounting/expense'].add.amountInputLabel`
                  )}
                  placeholder={this.context.t(
                    `['accounting/expense'].add.amountInputPlaceholder`
                  )}
                  selected={add_new.amount !== null ? add_new.amount : ""}
                  customInput={Input}
                  decimalScale={2}
                  value={add_new.amount ? add_new.amount : ""}
                  suffix={
                    this.context.state.currency
                      ? this.context.state.currency
                      : "₺"
                  }
                  onValueChange={(e) => {
                    let amountValue = parseFloat(e.floatValue) || 0.0;

                    if (Number.isInteger(amountValue)) {
                      amountValue = amountValue.toFixed(2);
                    }

                    if (Number.isNaN(e.floatValue)) {
                      amountValue = 0.0;
                    }

                    if (typeof amountValue === "string") {
                      amountValue = parseFloat(amountValue);
                    }

                    this.setState({
                      add_new: {
                        ...add_new,
                        amount: amountValue,
                      },
                    });
                  }}
                />
              </Column>

              <Column className="xs-12 sm-12 md-12 lg-3">
                <Select
                  label={this.context.t(
                    `['accounting/credit'].payments.typeInputLabel`
                  )}
                  placeholder={this.context.t(
                    `['accounting/credit'].payments.typeInputPlaceholder`
                  )}
                  items={this.state.payment}
                  selected={
                    this.state.selected_payment &&
                    this.state.selected_payment.value
                  }
                  labelKey="label"
                  valueKey="value"
                  handler={async (payment_type) => {
                    await this.setState({
                      selected_payment: {
                        ...this.state.selected_payment,
                        value: payment_type,
                      },
                    });
                  }}
                />
              </Column>

              <Column className="xs-12 sm-12 md-12 lg-3">
                <Input
                  label={this.context.t(`['accounting/expense'].add.spending`)}
                  placeholder={this.context.t(
                    `['accounting/expense'].add.spendingPlaceholder`
                  )}
                  value={this.state.spender ? this.state.spender : ""}
                  onChange={(e) => {
                    this.setState({
                      spender: e.target.value,
                    });
                  }}
                />
              </Column>
            </Column>
            <Column className="xs-12 sm-12 md-12 lg-12">
              <Column className="xs-12 sm-12 md-12 lg-6">
                <Input
                  label={this.context.t(
                    `['accounting/expense'].add.descriptionInputLabel`
                  )}
                  placeholder={this.context.t(
                    `['accounting/expense'].add.descriptionInputHint`
                  )}
                  multiline={true}
                  rows={2}
                  value={
                    this.state.add_new.description
                      ? this.state.add_new.description
                      : ""
                  }
                  onChange={(e) => {
                    this.setState({
                      add_new: {
                        ...add_new,
                        description: e.target.value,
                      },
                    });
                  }}
                />
              </Column>

              <Column className="xs-12 sm-12 md-12 lg-6">
                <DatePickerInput
                  label={this.context.t(
                    `['accounting/expense'].add.processDate`
                  )}
                  value={this.state.add_new.transaction_date}
                  onChange={async (process_date) => {
                    await this.setState({
                      add_new: {
                        ...add_new,
                        transaction_date: ConvertTimeToZulu(
                          moment(process_date).unix()
                        ),
                      },
                    });
                  }}
                />
              </Column>
            </Column>
          </Grid>
        </AlertDialog>

        <AlertDialog
          title={"Gider Silmeyi Onaylıyor musunuz?"}
          fullWidth
          open={this.state.delete_confirm}
          closeHandler={() => this.setState({ delete_confirm: false })}
          buttons={[
            {
              title: this.context.t(`services.delete.confirmButtonTitle`),
              icon: "check",
              backgroundColor: "primary-opacity",
              textColor: "primary",
              timer: 5,
              onClick: () => this.deleteSingleExpense(this.state.delete_row_id),
            },
            {
              title: this.context.t(`services.delete.discardButtonTitle`),
              icon: "close",
              textColor: "grey",
            },
          ]}
        >
          <Alert severity="warning">
            <b>{"Bu işlem geri alınamaz!"}</b>
            <br />
            {"Gider listeden silinecektir."}
          </Alert>
        </AlertDialog>

        <AlertDialog
          title={<h3 style={{ textAlign: "center" }}>Zaman Aralığı Seçiniz</h3>}
          disableBackdropClick={false}
          open={this.state.filter_modal_open}
          maxWidth="sm"
          closeHandler={() => {
            this.setState({
              filter_modal_open: false,
            });
          }}
          buttons={[
            {
              title: "Onayla",
              icon: "check",
              backgroundColor: "primary-opacity",
              textColor: "primary",
              onClick: async () => {
                await this.getExpenseData();
              },
            },
            {
              title: "İptal",
              icon: "close",
              textColor: "grey",
              onClick: () => {
                this.setState({
                  readyToUseFilter: null,
                  selected_filter_picker: {
                    id: 1,
                    label: "Bugün",
                    offset: 1,
                  },
                });
              },
            },
          ]}
        >
          <Grid>
            <Column className="xs-12 sm-12 md-12 lg-12 mt-3 mb-5">
              <Column className="xs-12 sm-12 md-12 lg-6">
                <ShortButtonContainer
                  style={{ flexDirection: "column", alignItems: "flex-start" }}
                >
                  <ShortButton
                    style={{
                      border: "1px solid rgba(249,186,132, 0.51)",
                      color: "rgb(219,146,92)",
                      backgroundColor: `${
                        this.state.readyToUseFilter === 1
                          ? "rgba(249,186,132, 0.21)"
                          : ""
                      }`,
                    }}
                    onClick={() => {
                      this.setState({
                        readyToUseFilter: 1,
                        start: moment()
                          .local()
                          .subtract(1, "days")
                          .toISOString(),
                        end: moment().local().toISOString(),
                        reportsDate: {
                          between: {
                            start: moment()
                              .local()
                              .subtract(1, "days")
                              .format("DD/MM/YYYY"),
                            end: moment().local().format("DD/MM/YYYY"),
                          },
                        },
                      });
                    }}
                  >
                    Dün
                  </ShortButton>
                  <ShortButton
                    style={{
                      border: "1px solid rgb(91, 187, 245, 0.41)",
                      color: "rgb(49, 169, 243)",
                      backgroundColor: `${
                        this.state.readyToUseFilter === 2
                          ? "rgb(49, 169, 243, 0.14)"
                          : ""
                      }`,
                    }}
                    onClick={() => {
                      this.setState({
                        readyToUseFilter: 2,
                        start: moment()
                          .local()
                          .subtract(7, "days")
                          .toISOString(),
                        end: moment().local().toISOString(),
                        reportsDate: {
                          between: {
                            start: moment()
                              .local()
                              .subtract(7, "days")
                              .format("DD/MM/YYYY"),
                            end: moment().local().format("DD/MM/YYYY"),
                          },
                        },
                      });
                    }}
                  >
                    Son 7 gün
                  </ShortButton>
                  <ShortButton
                    style={{
                      border: "1px solid rgb(58, 154, 87, 0.35)",
                      color: "rgb(29, 139, 62)",
                      backgroundColor: `${
                        this.state.readyToUseFilter === 3
                          ? "rgb(29, 139, 62, 0.21)"
                          : ""
                      }`,
                    }}
                    onClick={() => {
                      this.setState({
                        readyToUseFilter: 3,
                        start: moment()
                          .local()
                          .subtract(15, "days")
                          .toISOString(),
                        end: moment().local().toISOString(),
                        reportsDate: {
                          between: {
                            start: moment()
                              .local()
                              .subtract(15, "days")
                              .format("DD/MM/YYYY"),
                            end: moment().local().format("DD/MM/YYYY"),
                          },
                        },
                      });
                    }}
                  >
                    Son 15 gün
                  </ShortButton>
                  <ShortButton
                    style={{
                      border: "1px solid rgb(185, 178, 178, 0.65)",
                      color: "rgb(254, 111, 111)",
                      backgroundColor: `${
                        this.state.readyToUseFilter === 4
                          ? "rgb(254, 151, 151, 0.19)"
                          : ""
                      }`,
                    }}
                    onClick={() => {
                      this.setState({
                        readyToUseFilter: 4,
                        start: moment()
                          .local()
                          .subtract(30, "days")
                          .toISOString(),
                        end: moment().local().toISOString(),
                        reportsDate: {
                          between: {
                            start: moment()
                              .local()
                              .subtract(30, "days")
                              .format("DD/MM/YYYY"),
                            end: moment().local().format("DD/MM/YYYY"),
                          },
                        },
                      });
                    }}
                  >
                    Son 30 gün
                  </ShortButton>
                </ShortButtonContainer>
              </Column>

              <Column
                className="xs-12 sm-12 md-12 lg-6"
                style={{ height: "100%" }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    borderLeft: "1px solid #b2b2b2",
                    height: "100%",
                  }}
                >
                  <Column className="xs-12 sm-12 md-12 lg-12">
                    <DatePickerInput
                      label={this.context.t(`reports.startInputLabel`)}
                      value={new Date(this.state.start)}
                      maxDate={new Date(this.state.end)}
                      onChange={async (date) => {
                        this.setState({
                          readyToUseFilter: null,
                          start: moment(date).local().toISOString(),
                          reportsDate: {
                            ...this.state.reportsDate,
                            between: {
                              ...this.state.reportsDate.between,
                              start: moment(date).local().format("DD/MM/YYYY"),
                            },
                          },
                        });
                      }}
                    />
                  </Column>
                  <Column className="xs-12 sm-12 md-12 lg-12">
                    <DatePickerInput
                      label={this.context.t(`reports.endInputLabel`)}
                      value={new Date(String(this.state.end).split("T")[0])}
                      minDate={this.state.start}
                      onChange={async (date) => {
                        this.setState({
                          readyToUseFilter: null,
                          end: moment(date).local().toISOString(),
                          reportsDate: {
                            ...this.state.reportsDate,
                            between: {
                              ...this.state.reportsDate.between,
                              end: moment(date).local().format("DD/MM/YYYY"),
                            },
                          },
                        });
                      }}
                    />
                  </Column>
                </div>
              </Column>
            </Column>
          </Grid>
        </AlertDialog>
      </AuthContainer>
    );
  }

  validateNewExpense = () => {
    const { add_new } = this.state;
    return Validate([
      {
        field: "Gider Tipi",
        value: add_new.json_id,
        condition: /\S+/,
      },
      {
        field: "Gider Tutarı",
        value: add_new.amount,
        condition: Number,
      },
    ]);
  };
}

const ShortButton = styled.div`
  border-radius: 8px;
  width: 12vw;
  padding: 1vh 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 10px;
`;

const ShortButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const FilterButton = styled.div`
  border-radius: 8px;
  padding: 1vh 2.5vw;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  cursor: pointer;
  margin-bottom: 10px;
  color: rgb(219, 146, 92);
  border: 1px solid rgba(249, 186, 132, 0.51);
  background-color: rgba(249, 186, 132, 0.21);
`;

const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
`;

export default ExpenseTransactions;
