import React from "react";
import { ChromePicker } from "react-color";
import AppContext from "../../../context/store";
import AuthContainer from "../../../components/AuthContainer";
import CardSection from "../../../components/Cards/CardSection";

import BetweenTimeGenerator from "../../../functions/BetweenTimeGenerator";

import styled from "styled-components";
import { Column, Grid } from "../../../theme/Grid";
import Input from "../../../theme/CustomMUI/Input";
import Select from "../../../theme/CustomMUI/Select";
import Button from "../../../theme/Button";
import MaskedInput from "../../../theme/CustomMUI/MaskedInput";
import Axios from "axios";
import {
  ButtonBase,
  FormControlLabel,
  Switch,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import { toast } from "react-toastify";
import ModalDialog from "../../../theme/CustomMUI/AlertDialog";
import { Alert } from "@material-ui/lab";
import Validate from "../../../functions/Validate";

import FormDataGenerator from "../../../functions/FormDataGenerator";
import CompressImage from "../../../functions/CompressImage";
import FCLabel from "../../../theme/FCLabel";
import CSwitch from "../../../theme/CustomMUI/CSwitch";
import LimitedPermission from "../../../functions/LimitedPermissions";
import { flushSync } from "react-dom";
import { CloseOutlined } from "@material-ui/icons";
import EmpoolesPackages from "../../../components/EmpoolesPackages";

export default class SingleEmployee extends React.Component {
  static contextType = AppContext;

  state = {
    delete_confirm: false,
    packets: [],

    selected_day: 1,
    loaded: false,
    week: [
      "Pazartesi",
      "Salı",
      "Çarşamba",
      "Perşembe",
      "Cuma",
      "Cumartesi",
      "Pazar",
    ],

    generated_hours: BetweenTimeGenerator({
      ...this.context.state.company_shift,
    }),

    categories: [],

    company_services: [],

    category_id: 0,
    category_open: [
      {
        id: 0,
        inAcvtive: false,
      },
    ],

    displayColorPicker: false,

    employee: {
      color: "",
      id: null,
      name: null,
      surname: null,
      sex: null,
      password: null,
      send_sms: null,
      show_at_url: null,
      package_ids: [],
      detail: {
        profile_photo: null,
        job_title: null,
        bounty: null,
        phone: null,
        mail: null,
        // birthday: null,
        // format_birthday: null,
      },
      limited_perm: {
        id: null,
        appointment: 2,
        sales: 2,
        customer: 2,
        customer_info: 2,
        services: 2,
        sms: 2,
        income_expense: 2,
        wallet: 2,
        gallery: 2,
        system: 2,
      },
      permission: 0,
      service_ids: [],

      closed_appointments: {
        1: { active: true, hours: [] },
        2: { active: true, hours: [] },
        3: { active: true, hours: [] },
        4: { active: true, hours: [] },
        5: { active: true, hours: [] },
        6: { active: true, hours: [] },
        7: { active: true, hours: [] },
      },
      file: null,
    },
    limited_permissions: [...LimitedPermission()],
    selected_again_service_modal: false,
    selected_again_service_list: [],
    last_services_ids: [],
  };

  getSingleStaff = () => {
    const { params } = this.props.match;

    Axios.post(`${this.context.api_endpoint}/company/staff/bounty`, {
      staff_id: parseInt(params.id),
    });

    Axios.get(`${this.context.api_endpoint}/company/staff/${params.id}`)
      .then(({ data }) => {
        flushSync(() => {
          this.setState({
            loaded: true,
            packets: [...data?.data?.staff_packets.map((m) => m.packet_id)],
            company_services: [
              ...data.data.company_services.map((service, i) => {
                if (
                  service.category_name &&
                  service.category_name.toLowerCase().includes("erkek")
                ) {
                  service.name += " (E)";
                } else if (
                  service.category_name &&
                  service.category_name.toLowerCase().includes("kadın")
                ) {
                  service.name += " (K)";
                }
                return service;
              }),
            ],
            employee_services: [...data.data.services],
            last_services_ids: [...data.data.services].map(
              (s) => s.service && s.service.id
            ),
            employee: {
              ...data.data,
              color: data.data.color,
              // closed_appointments: undefined,
              service_ids: [...data.data.services].map(
                (s) => s.service && s.service.id
              ),
              closed_appointments:
                data.data.closed_appointments.un_appointments &&
                data.data.closed_appointments.un_appointments !== ""
                  ? JSON.parse(data.data.closed_appointments.un_appointments)
                  : {
                      1: { active: true, hours: [] },
                      2: { active: true, hours: [] },
                      3: { active: true, hours: [] },
                      4: { active: true, hours: [] },
                      5: { active: true, hours: [] },
                      6: { active: true, hours: [] },
                      7: { active: true, hours: [] },
                    },
              detail: {
                ...data.data.detail,
                // birthday: moment(data.data.detail.birthday).format("DD/MM/YYYY"),
                // format_birthday:
                //   data.data.detail.birthday !== null
                //     ? new Date(
                //         moment(data.data.detail.birthday).format("YYYY-MM-DD")
                //       )
                //     : null,
              },
            },
          });
        });
        if (data.data.limited_perm === null) {
          this.setState({
            employee: {
              ...this.state.employee,
              limited_perm: {
                id: null,
                appointment: 2,
                sales: 2,
                customer: 2,
                customer_info: 2,
                services: 2,
                sms: 2,
                income_expense: 2,
                wallet: 2,
                gallery: 2,
                system: 2,
              },
            },
          });
        }
      })
      .catch((e) => {
        if (e.response.status === 204) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };
  // update_packets
  updateState = (newStateValue) => {
    this.setState({ packets: newStateValue });
  };

  uploadPhotoSingleStaff = async (e) => {
    const { params } = this.props.match;

    Axios.post(
      `${this.context.api_endpoint}/company/staff/update/${params.id}/photo`,
      FormDataGenerator({
        file: await CompressImage(e.target.files[0], {
          maxSizeMB: 2,
          fileType: "image/jpg",
        }),
      }),
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
      .then((response) => {
        if (response.status === 201) {
          toast.success(this.context.t(`profile.photoUploadToast`));
          this.getSingleStaff();
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  deletePhotoSingleStaff = () => {
    const { params } = this.props.match;

    Axios.delete(
      `${this.context.api_endpoint}/company/staff/delete/${params.id}/photo`
    )
      .then((response) => {
        if (response.status === 200) {
          toast.success(this.context.t(`profile.photoDeleteToast`));
          this.getSingleStaff();
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  handleCategoryClick = (category, open_category) => {
    this.setState({
      category_id: category,
      category_open: [
        ...this.state.category_open.map((c) => {
          return {
            ...c,
            id: category,
            open: open_category,
          };
        }),
      ],
    });
  };
  testFunck = () => {
    this.setState({
      employee: {
        ...this.state.employee,
        package_ids: this.context.state.employeePackages,
      },
    });
  };
  updateSingleStaff = () => {
    const { params } = this.props.match;
    this.testFunck();
    this.validateStaff() &&
      Axios.put(
        `${this.context.api_endpoint}/company/staff/update/${params.id}`,
        {
          ...this.state.employee,
          company_services: undefined,
          detail: {
            ...this.state.employee.detail,
            // birthday: this.state.employee.detail.format_birthday ?? null,
          },
          packet_ids: this.state.packets,
        }
      )
        .then((response) => {
          if (response.status === 201) {
            this.setState({
              selected_again_service_list: response.data.data.map((m) => m),
              employee: {
                ...this.state.employee,
                service_ids: [
                  ...this.state.employee.service_ids,
                  ...response.data.data.map((m) => m.id),
                ],
              },
              selected_again_service_modal: true,
            });
          } else if (response.status === 200) {
            toast.success(this.context.t(`profile.updateToast`));
          } else {
            toast.error("Something went wrong!");
          }
        })

        .catch((e) => {
          if (e?.response?.status === 401) {
            toast.error(e?.response?.data?.message);
          } else {
            toast.error(e?.response?.data?.message);
          }
        });
  };

  deleteSingleStaff = () => {
    const { params } = this.props.match;
    Axios.delete(
      `${this.context.api_endpoint}/company/staff/delete/${params.id}`
    )
      .then((response) => {
        if (response.status === 200) {
          toast.success(this.context.t(`profile.deleteToast`));
          this.props.history.push("/settings/employees");
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message, {
            autoClose: 10000,
          });
        } else {
          toast.error(e.response.data.message, {
            autoClose: 10000,
          });
        }
      });
  };

  handleChange = (event, label) => {
    const { employee } = this.state;
    // console.log(this.state.employee.services);
    const { limited_perm } = this.state.employee;
    this.setState({
      employee: {
        ...employee,
        limited_perm: {
          ...limited_perm,
          [label]: parseInt(event.target.value),
        },
      },
    });
  };

  getServices = () => {
    Axios.get(
      `${this.context.api_endpoint}/company/services/with/category/all`
    ).then(async ({ data }) => {
      this.setState({
        categories: [...data.data.filter((m) => m.services.length !== 0)],
      });
    });
  };

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
  };

  componentDidMount() {
    this.getSingleStaff();
    this.getServices();
  }

  render() {
    const {
      loaded,
      selected_day,
      employee,
      generated_hours,
      displayColorPicker,
    } = this.state;

    return (
      <AuthContainer>
        <Grid>
          <Column
            className="xs-12 mb-3"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
            }}
          >
            {loaded ? (
              <ProfilePhoto
                src={
                  employee.detail.profile_photo
                    ? `${this.context.api_endpoint.replace("api/v1", "")}${
                        this.context.state.company_id
                      }/${employee.detail.profile_photo}`
                    : employee.sex === 0
                    ? require("../../../assets/images/staff_woman.jpg")
                    : employee.sex === 1
                    ? require("../../../assets/images/staff_man.jpg")
                    : require("../../../assets/images/profile_photo.svg")
                }
              />
            ) : (
              <div
                style={{ width: 160, height: 160, borderRadius: 5 }}
                className="skeleton"
              />
            )}
            <input
              id="profile_photo"
              style={{ display: "none" }}
              type="file"
              accept="image/*"
              onChange={(e) => this.uploadPhotoSingleStaff(e)}
            />

            <Button
              className="mt-1"
              icon="add_photo_alternate"
              title={this.context.t(`profile.photo.uploadButtonTitle`)}
              textColor="blue"
              backgroundColor="blue-opacity"
              fullWidth
              onClick={() => document.getElementById("profile_photo").click()}
            />
            {employee.detail.profile_photo && (
              <Button
                className="mt-1"
                icon="delete"
                title={this.context.t(`profile.photo.deleteButtonTitle`)}
                textColor="red"
                backgroundColor="red-opacity"
                fullWidth
                onClick={() => this.deletePhotoSingleStaff()}
              />
            )}
            <Button
              className="mt-1"
              icon="receipt"
              title="Personel işlem kayıtlarına git"
              fullWidth
              onClick={() => {
                this.context.dispatch({
                  type: "LOG_TYPE",
                  payload: { log_type: 3 },
                });
                this.context.dispatch({
                  type: "LOG_ID",
                  payload: {
                    log_id: Number(employee.id),
                  },
                });
                this.props.history.push("/settings/logs");
              }}
            />
          </Column>

          <Column className="col-auto xs-12 sm-12 md-12 lg-12 xl-12">
            <CardSection title={this.context.t(`profile.detail.title`)}>
              {/* Name, Surname, Gender */}
              <Column className="xs-12 sm-12 md-4">
                <Input
                  required
                  label={this.context.t(`profile.detail.nameInputLabel`)}
                  value={employee.name !== null ? employee.name : ""}
                  onChange={(e) =>
                    this.setState({
                      employee: {
                        ...employee,
                        name: e.target.value,
                      },
                    })
                  }
                />
              </Column>
              <Column className="xs-12 sm-12 md-4">
                <Input
                  required
                  label={this.context.t(`profile.detail.surnameInputLabel`)}
                  value={employee.surname !== null ? employee.surname : ""}
                  onChange={(e) =>
                    this.setState({
                      employee: {
                        ...employee,
                        surname: e.target.value,
                      },
                    })
                  }
                />
              </Column>
              <Column className="xs-12 sm-12 md-2">
                <CSwitch
                  title={this.context.t(`customers.headings.sex`)}
                  label={this.context.t(`profile.detail.genderMale`)}
                  labelLeft={this.context.t(`profile.detail.genderFemale`)}
                  checked={!!employee.sex}
                  color="primary"
                  closeColor="purple"
                  onChange={(checked) => {
                    this.setState({
                      employee: {
                        ...employee,
                        sex: checked.target.checked === false ? 0 : 1,
                      },
                    });
                  }}
                />
                {/* <Select
                  required
                  label={this.context.t(`profile.detail.genderInputLabel`)}
                  items={[
                    {
                      value: "0",
                      name: this.context.t(`profile.detail.genderFemale`),
                    },
                    {
                      value: "1",
                      name: this.context.t(`profile.detail.genderMale`),
                    },
                  ]}
                  selected={employee.sex !== null ? `${employee.sex}` : ""}
                  labelKey="name"
                  valueKey="value"
                  handler={(sex) =>
                    this.setState({
                      employee: {
                        ...employee,
                        sex: parseInt(sex),
                      },
                    })
                  }
                /> */}
              </Column>
              <Column className="xs-12 sm-12 md-2">
                <h3>{this.context.t(`customers.headings.colorSelect`)}</h3>
                <div>
                  <div className="swatch" onClick={this.handleClick}>
                    <div
                      className="color"
                      style={{ background: employee.color }}
                    />
                  </div>
                  {displayColorPicker ? (
                    <div className="popover">
                      <div className="cover" onClick={this.handleClose} />
                      <ChromePicker
                        color={employee.color}
                        onChangeComplete={(color) => {
                          this.setState({
                            employee: {
                              ...employee,
                              color: color.hex,
                            },
                          });
                        }}
                      />
                    </div>
                  ) : null}
                </div>
              </Column>

              {/* Birthday? , Job Title , Percentage of Fixed Bonus  */}
              {/* <Column className="xs-12 sm-12 md-4">
                <DatePickerInput
                  className="mb-0"
                  label={this.context.t(`profile.detail.birthdayInputLabel`)}
                  value={this.state.employee.detail.format_birthday ?? null}
                  onChange={async (selected_date) => {
                    this.setState({
                      employee: {
                        ...this.state.employee,
                        detail: {
                          ...this.state.employee.detail,
                          birthday: moment(selected_date).format("DD/MM/YYYY"),
                          format_birthday: selected_date,
                        },
                      },
                    });
                  }}
                />
              </Column> */}
              <Column className="xs-12 sm-12 md-6">
                <Input
                  label={this.context.t(`profile.detail.jobTitleInputLabel`)}
                  value={
                    employee.detail.job_title !== null
                      ? employee.detail.job_title
                      : ""
                  }
                  onChange={(e) =>
                    this.setState({
                      employee: {
                        ...employee,
                        detail: {
                          ...employee.detail,
                          job_title: e.target.value,
                        },
                      },
                    })
                  }
                />
              </Column>
              {(this.context.current_flag &
                this.context.state.FLAGS.SALON_RANDEVU && (
                <Column className="xs-12 sm-12 md-6">
                  <MaskedInput
                    label={this.context.t(`profile.detail.bountyInputLabel`)}
                    mask="99"
                    maskChar=""
                    value={
                      employee.detail.bounty !== null
                        ? employee.detail.bounty
                        : ""
                    }
                    onChange={(e) =>
                      this.setState({
                        employee: {
                          ...employee,
                          detail: {
                            ...employee.detail,
                            bounty:
                              e.target.value.length > 0
                                ? parseInt(e.target.value)
                                : null,
                          },
                        },
                      })
                    }
                  />
                </Column>
              )) ||
                null}

              {/* Panel Authorization, Email Sending, Display Enterprise Url*/}
              <Column
                className={
                  (this.context.current_flag &
                    this.context.state.FLAGS.SALON_RANDEVU &&
                    "xs-12 sm-12 md-4") ||
                  "xs-12 sm-12 md-6"
                }
              >
                <Select
                  required
                  disabled={
                    this.context.state.user.id ===
                      parseInt(this.props.match.params.id) ||
                    this.context.state.user.permission === 2
                  }
                  label={this.context.t(`profile.detail.permissionInputLabel`)}
                  items={
                    this.context.state.user.id !=
                      parseInt(this.props.match.params.id) &&
                    this.context.state.user.permission === 2 &&
                    this.context.state.limited_permission.system == 1
                      ? [{ value: 0, name: " " }]
                      : [
                          {
                            value: 0,
                            name: this.context.t(
                              `profile.detail.permissionStaff`
                            ),
                          },
                          {
                            value: 1,
                            name: this.context.t(
                              `profile.detail.permissionOwner`
                            ),
                          },
                          {
                            value: 2,
                            name: this.context.t(
                              `profile.detail.permissionLimited`
                            ),
                          },
                        ]
                  }
                  selected={
                    employee.permission !== null ? `${employee.permission}` : ""
                  }
                  labelKey="name"
                  valueKey="value"
                  handler={(permission) =>
                    this.setState({
                      employee: {
                        ...employee,
                        permission: parseInt(permission),
                      },
                    })
                  }
                />
              </Column>
              <Column
                className={
                  (this.context.current_flag &
                    this.context.state.FLAGS.SALON_RANDEVU &&
                    "xs-12 sm-12 md-4") ||
                  "xs-12 sm-12 md-6"
                }
              >
                <Select
                  required
                  label={this.context.t(`profile.detail.sendSmsInputLabel`)}
                  items={[
                    {
                      value: true,
                      label: this.context.t(`profile.detail.sendSmsTrue`),
                    },
                    {
                      value: false,
                      label: this.context.t(`profile.detail.sendSmsFalse`),
                    },
                  ]}
                  selected={
                    employee.send_sms !== null ? `${employee.send_sms}` : ""
                  }
                  labelKey="label"
                  valueKey="value"
                  handler={(send_sms) =>
                    this.setState({
                      employee: {
                        ...employee,
                        send_sms: send_sms === "true",
                      },
                    })
                  }
                />
              </Column>
              <Column
                className={
                  (this.context.current_flag &
                    this.context.state.FLAGS.SALON_RANDEVU &&
                    "xs-12 sm-12 md-4") ||
                  "xs-12 sm-12 md-6"
                }
              >
                <Select
                  required
                  label={this.context.t(`profile.detail.urlInputLabel`)}
                  items={[
                    {
                      value: true,
                      label: this.context.t(`profile.detail.showAtUrlTrue`),
                    },
                    {
                      value: false,
                      label: this.context.t(`profile.detail.showAtUrlFalse`),
                    },
                  ]}
                  selected={
                    employee.show_at_url !== null
                      ? `${employee.show_at_url}`
                      : ""
                  }
                  labelKey="label"
                  valueKey="value"
                  handler={(show_at_url) =>
                    this.setState({
                      employee: {
                        ...employee,
                        show_at_url: show_at_url === "true",
                      },
                    })
                  }
                />
              </Column>

              {/* Form Section Settings Limited Permission  */}
              {this.state.employee.permission === 2 &&
                this.context.state.user.permission === 1 && (
                  <Column className="xs-12 sm-12 md-12">
                    <div className="authoritySettings">
                      {this.state.limited_permissions.map(
                        (permission, index) => (
                          <div
                            key={permission.name}
                            className="authoritySection"
                          >
                            <div className="authorityForm">
                              <FormControl component="fieldset">
                                <FormLabel
                                  className="authorityLabel"
                                  component="legend"
                                >
                                  {permission.label}
                                </FormLabel>
                                <RadioGroup
                                  row
                                  aria-label="gender"
                                  name={permission.name}
                                  value={
                                    this.state.employee.limited_perm[
                                      `${permission.name}`
                                    ]
                                  }
                                  onChange={(event) => {
                                    this.handleChange(event, permission.name);
                                  }}
                                >
                                  <FormControlLabel
                                    value={0}
                                    control={<Radio size="small" />}
                                    label={this.context.t(
                                      `profile.limited_permission_label.no_permission`
                                    )}
                                  />
                                  <FormControlLabel
                                    value={1}
                                    control={<Radio size="small" />}
                                    label={this.context.t(
                                      `profile.limited_permission_label.display`
                                    )}
                                  />
                                  <FormControlLabel
                                    value={2}
                                    control={<Radio size="small" />}
                                    label={this.context.t(
                                      `profile.limited_permission_label.full_permission`
                                    )}
                                  />
                                </RadioGroup>
                              </FormControl>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </Column>
                )}
            </CardSection>

            <CardSection
              title={this.context.t(`profile.contact.title`)}
              className="mt-5"
            >
              <Column className="xs-12 sm-12 md-4">
                <Input
                  required
                  label={this.context.t(`profile.contact.mailInputLabel`)}
                  value={
                    employee.detail.mail !== null ? employee.detail.mail : ""
                  }
                  onChange={(e) =>
                    this.setState({
                      employee: {
                        ...employee,
                        detail: {
                          ...employee.detail,
                          mail: e.target.value,
                        },
                      },
                    })
                  }
                />
              </Column>

              <Column className="xs-12 sm-12 md-4">
                <MaskedInput
                  required
                  label={this.context.t(`profile.contact.phoneInputLabel`)}
                  placeholder="500 000 0000"
                  mask="999 999 9999"
                  maskChar=""
                  value={
                    employee.detail.phone !== null ? employee.detail.phone : ""
                  }
                  startAdornment="+90"
                  onChange={(e) =>
                    this.setState({
                      employee: {
                        ...employee,
                        detail: {
                          ...employee.detail,
                          phone:
                            e.target.value &&
                            e.target.value.substring(0, 1) === "5"
                              ? e.target.value.replace(/\s/g, "")
                              : null,
                        },
                      },
                    })
                  }
                />
              </Column>

              <Column className="xs-12 sm-12 md-4">
                <Input
                  label={this.context.t(
                    `profile.contact.newPasswordInputLabel`
                  )}
                  autoComplete={false}
                  type="password"
                  onChange={(e) =>
                    this.setState({
                      employee: {
                        ...employee,
                        password: e.target.value,
                      },
                    })
                  }
                />
              </Column>
            </CardSection>

            <CardSection
              title={this.context.t(`profile.services.title`)}
              className="mt-5"
            >
              <Column className="xs-12 sm-12 mb-3">
                <Alert severity="info">
                  <b>{this.context.t(`profile.services.alertBoldText`)}</b>
                  <br />
                  {this.context.t(`profile.services.alertText`)}
                </Alert>
              </Column>
              <Column className="xs-12 sm-12">
                <Grid>
                  {loaded === true &&
                    this.state.categories.map((category, index) => {
                      return (
                        <Column
                          key={index}
                          className="col xs-12 sm-12 md-12 lg-12 xl-12"
                        >
                          <div className="category-container_divider">
                            <div
                              onClick={() => {
                                this.handleCategoryClick(category.id, true);
                              }}
                              className="mb-2 category-container"
                            >
                              <span>{category.name}</span>
                            </div>
                            <span
                              className="category-container_span"
                              onClick={() => {
                                this.handleCategoryClick(category.id, false);
                              }}
                            >
                              <CloseOutlined />
                            </span>
                          </div>
                          {category.services.map((service) => {
                            return (
                              <>
                                {this.state.category_open.map(
                                  (category_open, index) => {
                                    if (
                                      category_open.id === category.id &&
                                      category_open.open === true
                                    ) {
                                      return (
                                        <Column className="col xs-12 sm-12 md-6 lg-6 xl-6">
                                          <div
                                            key={index}
                                            style={{
                                              cursor: "pointer",
                                              border: `1px solid ${
                                                employee.service_ids.includes(
                                                  service.id
                                                )
                                                  ? "#3189ec"
                                                  : "white"
                                              }`,
                                              borderRadius: "5px",
                                              marginBottom: "12px",
                                              padding: "10px 0px",
                                              width: "100%",
                                              color: `${
                                                employee.service_ids.includes(
                                                  service.id
                                                )
                                                  ? "#3189ec"
                                                  : "black"
                                              }`,
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "center",
                                            }}
                                            onClick={() =>
                                              this.changeServiceStatus(
                                                service.id
                                              )
                                            }
                                          >
                                            {employee.service_ids.includes(
                                              service.id
                                            ) ? (
                                              <CheckIcon
                                                fontSize="small"
                                                style={{ paddingRight: "5px" }}
                                              />
                                            ) : (
                                              <CloseIcon
                                                fontSize="small"
                                                style={{ paddingRight: "5px" }}
                                              />
                                            )}
                                            {service.name}
                                          </div>
                                        </Column>
                                      );
                                    }
                                  }
                                )}
                              </>
                            );
                          })}
                        </Column>
                      );
                    })}
                </Grid>
              </Column>
            </CardSection>
            <CardSection title="Sunduğu Paketler" className="mt-5">
              <Column className="xs-12 sm-12 mb-3">
                <Alert severity="info">
                  <b>{this.context.t(`profile.packages.alertBoldText`)}</b>
                  <br />
                  {this.context.t(`profile.packages.alertText`)}
                </Alert>
              </Column>
              <EmpoolesPackages
                packets={this.state.packets}
                updateState={this.updateState}
              />
            </CardSection>
            <CardSection
              title={this.context.t(`profile.booking.title`)}
              className="mt-5"
            >
              <Column className="xs-12 sm-12">
                <DayTabContainer>
                  {this.context
                    .t(`profile.booking.week`)
                    ?.map((item, index) => (
                      <ButtonBase key={index}>
                        <StyledDayTab
                          key={index}
                          className="ripple"
                          day={index + 1}
                          selected_day={this.state.selected_day}
                          onClick={() =>
                            this.setState({ selected_day: index + 1 })
                          }
                          children={item}
                        />
                      </ButtonBase>
                    ))}
                </DayTabContainer>
                {loaded === true ? (
                  <FormControlLabel
                    className="mb-2"
                    control={
                      <Switch
                        color="primary"
                        checked={
                          employee.closed_appointments[selected_day].active
                        }
                        onChange={() => this.changeDayStatus(selected_day)}
                      />
                    }
                    label={
                      <FCLabel>
                        {employee.closed_appointments[selected_day].active ===
                        true
                          ? this.context.t(`profile.booking.dayCloseText`)
                          : this.context.t(`profile.booking.dayOpenText`)}
                      </FCLabel>
                    }
                  />
                ) : (
                  <div
                    style={{
                      width: 283,
                      maxWidth: "100%",
                      height: 33,
                      borderRadius: 5,
                    }}
                    className="skeleton mb-2 ml-1"
                  />
                )}
              </Column>
              <Column className="xs-12 sm-12">
                {parseInt(this.context.state.company_shift.slice) > 0 ? (
                  generated_hours.map((hour, index) => (
                    <div
                      key={index}
                      className="col xs-6 sm-2 md-2 lg-2 xl-2 mb-2 p-horizontal-1"
                    >
                      <Button
                        disabled={
                          !employee.closed_appointments[selected_day].active
                        }
                        key={index}
                        fullWidth={true}
                        className={
                          employee.closed_appointments[selected_day].active &&
                          !employee.closed_appointments[
                            selected_day
                          ].hours.includes(hour)
                            ? `elevation-2`
                            : ""
                        }
                        onClick={() => this.changeHourStatus(hour)}
                        icon={
                          employee.closed_appointments[selected_day].active &&
                          !employee.closed_appointments[
                            selected_day
                          ].hours.includes(hour)
                            ? "check"
                            : "close"
                        }
                        title={hour}
                        //outlined={employee.appointments[selected_day].active && !employee.appointments[selected_day].hours.includes(hour)}
                        backgroundColor={"white"}
                        textColor={
                          employee.closed_appointments[selected_day].active &&
                          !employee.closed_appointments[
                            selected_day
                          ].hours.includes(hour)
                            ? "green"
                            : "red"
                        }
                      />
                    </div>
                  ))
                ) : (
                  <Alert severity="info">
                    <b>{this.context.t(`profile.booking.alertBoldText`)}</b>
                  </Alert>
                )}
              </Column>
            </CardSection>

            <Button
              className="mt-3 mb-1"
              icon="update"
              title={this.context.t(`profile.updateButtonTitle`)}
              backgroundColor="primary"
              textColor="white"
              fullWidth={true}
              onClick={() => this.updateSingleStaff()}
            />

            <Button
              disabled={
                parseInt(this.props.match.params.id) ===
                this.context.state.user.id
              }
              icon="delete_forever"
              title={this.context.t(`profile.deleteButtonTitle`)}
              backgroundColor="red-opacity"
              textColor="red"
              fullWidth={true}
              onClick={() => this.setState({ delete_confirm: true })}
            />
            <ModalDialog
              open={this.state.delete_confirm}
              closeHandler={() => this.setState({ delete_confirm: false })}
              title={this.context.t(`profile.delete.title`)}
              buttons={[
                {
                  title: this.context.t(`profile.delete.confirmButtonTitle`),
                  icon: "check",
                  timer: 5,
                  backgroundColor: "primary-opacity",
                  textColor: "primary",
                  onClick: () => this.deleteSingleStaff(),
                },
                {
                  title: this.context.t(`profile.delete.discardButtonTitle`),
                  icon: "close",
                  textColor: "grey",
                },
              ]}
            >
              <Alert severity="warning">
                <b>{this.context.t(`profile.delete.alertBoldText`)}</b>
                <br />
                {this.context.t(`profile.delete.alertText`)}
              </Alert>
            </ModalDialog>
            <ModalDialog
              open={this.state.selected_again_service_modal}
              closeHandler={() =>
                this.setState({
                  selected_again_service_modal: false,
                })
              }
              buttons={[
                {
                  title: "Hepsini Ekle",
                  icon: "check",

                  backgroundColor: "primary-opacity",
                  textColor: "primary",
                  onClick: () => {
                    this.setState({
                      employee: {
                        ...this.state.employee,
                        service_ids: [
                          ...this.state.employee.service_ids,
                          ...this.state.selected_again_service_list.map(
                            (m) => m.id
                          ),
                        ],
                      },
                    });
                    this.updateSingleStaff();
                  },
                },
                {
                  title: "Vazgeç",
                  icon: "close",
                  textColor: "grey",
                  onClick: () =>
                    this.setState({
                      employee: {
                        ...this.state.employee,
                        service_ids: this.state.last_services_ids,
                      },
                    }),
                },
              ]}
            >
              <Alert severity="warning">
                Seçtiğiniz paket içerisindeki hizmetler personelin sunduğu
                hizmetler arasında yok. Hizmeti personelin sunduğu hizmetler
                arasına eklemeden devam edemezsiniz
              </Alert>
              <div style={{ width: "100%" }}>
                {this.state.selected_again_service_list.map((m) => (
                  <div style={{ width: "100%" }}>-{m.name} </div>
                ))}
              </div>
            </ModalDialog>
          </Column>
        </Grid>
      </AuthContainer>
    );
  }

  changeHourStatus = (hour) => {
    const { selected_day } = this.state;
    !this.state.employee.closed_appointments[selected_day].hours.includes(hour)
      ? this.setState({
          employee: {
            ...this.state.employee,
            closed_appointments: {
              ...this.state.employee.closed_appointments,
              [selected_day]: {
                ...this.state.employee.closed_appointments[selected_day],
                hours: [
                  ...this.state.employee.closed_appointments[selected_day]
                    .hours,
                  hour,
                ],
              },
            },
          },
        })
      : this.setState({
          employee: {
            ...this.state.employee,
            closed_appointments: {
              ...this.state.employee.closed_appointments,
              [selected_day]: {
                ...this.state.employee.closed_appointments[selected_day],
                hours: [
                  ...this.state.employee.closed_appointments[
                    selected_day
                  ].hours.filter((item) => item !== hour),
                ],
              },
            },
          },
        });
  };

  changeDayStatus = () => {
    const { selected_day, employee } = this.state;
    this.setState({
      employee: {
        ...employee,
        closed_appointments: {
          ...employee.closed_appointments,
          [selected_day]: {
            ...employee.closed_appointments[selected_day],
            active: !employee.closed_appointments[selected_day].active,
          },
        },
      },
    });
  };

  changeServiceStatus = (service_id) => {
    const { employee } = this.state;

    employee.service_ids.includes(service_id)
      ? this.setState({
          employee: {
            ...employee,
            service_ids: employee.service_ids.filter(
              (s_id) => service_id !== s_id
            ),
          },
        })
      : this.setState({
          employee: {
            ...employee,
            service_ids: [...employee.service_ids, service_id],
          },
        });
  };

  validateStaff = () => {
    const { employee } = this.state;

    return Validate([
      {
        field: "İsim",
        value: employee.name,
        condition: /[a-zA-Z0-9ğüşöçİĞÜŞÖÇ]+/,
      },
      {
        field: "Soyisim",
        value: employee.surname,
        condition: /[a-zA-Z0-9ğüşöçİĞÜŞÖÇ]+/,
      },
      {
        field: "Cinsiyet",
        value: employee.sex,
        condition: Number,
      },
      {
        field: "Cep Telefonu",
        value: employee.detail.phone,
        condition: /\S{10}/,
      },
      {
        field: "E-Posta Adresi",
        value: employee.detail.mail,
        condition: /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/,
      },
    ]);
  };
}

const StyledDayTab = styled.div`
  width: auto;
  flex: none;
  text-align: center;
  font-size: 14px;
  padding: 16px;
  font-weight: 500;
  color: ${({ day, selected_day }) =>
    day === selected_day ? "var(--primary)" : "var(--black)"};
  border-bottom: 2px solid
    ${({ day, selected_day }) =>
      day === selected_day ? "var(--primary)" : "var(--white)"};
  box-sizing: border-box;
  cursor: pointer;
`;

const DayTabContainer = styled.div`
  width: -webkit-fill-available;
  position: relative;
  z-index: 100;
  margin: 0;
  padding: 0;
  overflow-x: auto;
  overflow-y: hidden !important;
  list-style: none;
  display: inline-flex;
  margin-bottom: 16px;
  box-sizing: content-box;

  button {
    font-family: "Inter", sans-serif !important;
  }
`;

const ProfilePhoto = styled.img`
  background: #fafafa;
  width: 160px;
  height: 160px;
  max-width: 100%;
  object-fit: cover;
  border-radius: 5px;
`;
