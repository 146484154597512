import React, { PureComponent } from "react";
import AppContext from "../../../../context/store";

import AuthContainer from "../../../../components/AuthContainer";
import Button from "../../../../theme/Button";
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  TextField,
} from "@material-ui/core";
import styled from "styled-components";
import { Column, Grid } from "../../../../theme/Grid";
import { Delete, Edit, WhatsApp } from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import EditIcon from "@material-ui/icons/Edit";
import AlertDialog from "../../../../theme/CustomMUI/AlertDialog";
import { Alert } from "@material-ui/lab";
import Table from "../../../../theme/Table";
import InputAdornment from "@material-ui/core/InputAdornment";
import Checkbox from "@material-ui/core/Checkbox";

import moment from "moment";
import Axios from "axios";
import { toast } from "react-toastify";
import DatePickerInput from "../../../../theme/CustomMUI/DatePickerInput";
import AutoCompleteInput from "../../../../theme/CustomMUI/AutoComplete";
import LinearProgress from "@material-ui/core/LinearProgress";
import { withStyles } from "@material-ui/core/styles";

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#1a90ff",
  },
}))(LinearProgress);

export default class AgreementIndex extends PureComponent {
  static contextType = AppContext;

  state = {
    agreements: [],
    clauses: [],
    tab: 0,
    is_edit: false,

    edit_mode: false,
    update_clause: false,

    selected_clause: {
      id: null,
      title: "",
      content: "",
      type: 1,
    },

    print_dialog: false,

    // tam ekran için seçilen sözleşme
    selected_agreement: null,

    // tam ekran sözleşme pop-up
    selected_agreement_dialog_show: false,

    // kişisel bilgileri göster/gösterme
    personal_info_show: false,

    // kişisel bilgiler
    name_and_surname: true,
    phone: true,
    // birth_date: false,
    // job: false,
    id_number: false,
    tax_number: false,
    address: true,

    // ödeme bilgileri göster/gösterme
    payment_info_show: false,

    // ödeme bilgileri
    staff_name: true,
    service: true,
    total_amount: true,

    // sözleşme maddelerini göster gösterme
    clause_info_show: false,

    // sözleşme maddeleri
    agreement_clauses: [],

    // şablon sözleşme ismi
    new_pattern_name: "",

    // şirket ismi
    company_name: "",
    company_name_show: true,

    // Sözleşme Başlığı
    pattern_header: "Özel Hizmet Sözleşmesi",
    pattern_header_show: true,

    patterns: [],

    company_info_show: false,
    company_info: "",

    delete_agreement_id: null,
    delete_confirm: false,

    // düzenlenecek sözleşme
    edit_agreement: null,

    // imzalanmış ya da yüklenmiş sözleşmeler
    customer_agreements: [],
    filtered_customer_agreements: [],
    // kayıtlı sözleşmeyi silmek için
    customer_agreement_delete_id: null,
    // silmek için açılan dialog
    delete_agreement_dialog: false,
    agreement_load: true,

    selected_customer: null,

    total_byte: 0,
    start_date: moment()
      .subtract("30", "days")
      .tz("Europe/Istanbul")
      .toISOString(),
    end_date: moment().tz("Europe/Istanbul").toISOString(),

    // sms onay dialogu
    sms_aproval_modal: false,
    // gönderilecek sms id,name,agreement name
    send_sms_id: null,
    send_sms_customer_name: null,
    send_sms_agreement_name: null,
    // Whatsapp onay dialogu
    wp_aproval_modal: false,
    // gönderilecek wp id
    send_wp_id: null,
    send_wp_customer_name: null,
    send_wp_agreement_name: null,
    // wp phone number
    send_wp_phone: null,
    send_wp_message: null,

    logo_src: null,

    pagination: {
      total_page: null,
      page: null,
      onChange: (page) => this.getCustomerAgreements(page),
    },
  };

  getLogoSrc = () => {
    Axios.get(`${this.context.api_endpoint}/company/message/credit`)
      .then(({ data }) => {
        this.setState({
          logo_src: data.data.settings.logo_src,
        });
      })
      .catch((e) => console.log(e));
  };

  // send sms to customer
  sendSMSAgreement = (id) => {
    Axios.post(
      `${this.context.api_endpoint}/company/contract/template/pdf/sms`,
      {
        id: id,
        is_wp: 1,
      }
    )
      .then((data) => {
        if (data.data.status === 201) {
          toast.success(data.data.data);
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  // send wp to customer
  sendWPAgreement = (id) => {
    Axios.post(
      `${this.context.api_endpoint}/company/contract/template/pdf/sms`,
      {
        id: id,
        is_wp: 2,
      }
    )
      .then((data) => {
        console.log(data);
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  // delete customer agreement
  deleteCustomerAgreement = (id) => {
    Axios.delete(
      `${this.context.api_endpoint}/company/contract/template/pdf/${id}`
    )
      .then(({ data }) => {
        if (data.status === 200) {
          toast.success(data.data);
          this.getCustomerAgreements();
        }
      })
      .catch((err) => {
        toast.error(
          "Beklenmeyen bir hata meydana geldi. Lütfen müşteri temsilciniz ile iletişime geçiniz."
        );
      });
  };

  // imzalanan sözleşmeleri alı
  getCustomerAgreements = (page) => {
    this.setState({
      agreement_load: false,
    });

    let route;

    if (this.state.selected_customer) {
      route = `${
        this.context.api_endpoint
      }/company/contract/template/pdf?page=${page ? page : 1}&customer_id=${
        this.state.selected_customer.id
      }&start=${moment(this.state.start_date).format(
        "YYYY-MM-DD"
      )}&end=${moment(this.state.end_date).format(
        "YYYY-MM-DD"
      )}&is_between=true`;
    } else {
      route = `${
        this.context.api_endpoint
      }/company/contract/template/pdf?page=${page ? page : 1}&start=${moment(
        this.state.start_date
      ).format("YYYY-MM-DD")}&end=${moment(this.state.end_date).format(
        "YYYY-MM-DD"
      )}&is_between=true`;
    }

    Axios.get(route)
      .then(({ data }) => {
        if (data.status === 200) {
          this.setState({
            total_byte: data.data.total_byte,
            customer_agreements: data.data.pdfs.records.map((item) => {
              return {
                url: item.secure_url,
                name: `${item.customer_name} ${item.customer_surname}`,
                date: moment(item.created_at).format(
                  "DD MMMM dddd, YYYY, HH:mm"
                ),
                agreement_name: item.title,
                id: item.id,
                bytes: (item.bytes / 1024 / 1024).toFixed(2),
                fdate: item.created_at,
                opened: item.is_connection_opened,
              };
            }),
            filtered_customer_agreements: data.data.pdfs.records.map((item) => {
              return {
                url: item.secure_url,
                name: `${item.customer_name} ${item.customer_surname}`,
                date: moment(item.created_at).format(
                  "DD MMMM dddd, YYYY, HH:mm"
                ),
                agreement_name: item.title,
                id: item.id,
                bytes: (item.bytes / 1024 / 1024).toFixed(2),
                fdate: item.created_at,
                opened: item.is_connection_opened,
              };
            }),
            agreement_load: true,
            pagination: {
              ...this.state.pagination,
              page: data.data.pdfs.page,
              total_page: data.data.pdfs.records.length,
            },
          });
          console.log(data.data.pdfs.records.length, data.data.pdfs.page);
        }
      })
      .catch((err) => console.log(err));
  };
  // search for customer by name
  searchCustomerByKey = async (key) => {
    let arr = [];
    await Axios.get(`${this.context.api_endpoint}/company/customers`, {
      params: { key: key ? key : " " },
    }).then(({ data }) => (arr = [...data.data.records]));
    return arr.map((item) => {
      return {
        id: item.id,
        full_name: `${item.full_name} (*** ${item.phone.slice(6, 11)})`,
        send_sms: item.send_sms,
        description: item.description,
      };
    });
  };

  // İşletme Bilgilerini alır
  getCompanyInfo = () => {
    Axios.get(`${this.context.api_endpoint}/company/itself`)
      .then((data) => {
        if (data.status === 201) {
          this.setState({
            company_address: data.data.data.address,
            company_name: data.data.data.name,
          });
        }
      })
      .catch((err) => toast.error(err.message));
  };

  // sözleşme maddelerini alır
  getClauses = () => {
    Axios.get(`${this.context.api_endpoint}/company/contract/clause`)
      .then((data) => {
        this.setState({
          clauses: data.data.data,
        });
      })
      .catch((err) => console.log("hata:", err));
  };

  // Sözleşme maddesini kaydeder
  saveClauses = () => {
    Axios.post(`${this.context.api_endpoint}/company/contract/clause`, {
      title: this.state.selected_clause.title,
      content: this.state.selected_clause.content,
      type: this.state.selected_clause.type,
    })
      .then((data) => {
        if (data.data.status === 201) {
          toast.success("Sözleşme maddesi başarı ile kaydedilmiştir.");
          this.setState({
            clauses: [data.data.data, ...this.state.clauses],
            selected_clause: {},
          });
        }
      })
      .catch((err) => console.log(err));
  };

  // Sözleşme maddesi günceller
  updateClauses = () => {
    Axios.put(
      `${this.context.api_endpoint}/company/contract/clause/${this.state.selected_clause.id}`,
      {
        title: this.state.selected_clause.title,
        content: this.state.selected_clause.content,
        type: this.state.selected_clause.type,
      }
    )
      .then((data) => {
        toast.success("Sözleşme maddesi başarı ile güncellenmiştir.");
        this.setState({
          clauses: [...this.state.clauses].map((el) =>
            el.id === data.data.data.id ? data.data.data : el
          ),
        });
      })
      .catch((err) => console.log(err));
  };

  // Sözleşme maddesi siler
  deleteClauses = () => {
    Axios.delete(
      `${this.context.api_endpoint}/company/contract/clause/${this.state.selected_clause.id}`
    )
      .then((data) => {
        if (data.data.status === 200) {
          toast.success("Sözleşme maddesi başarı ile silinmiştir.");
          this.setState({
            clauses: [...this.state.clauses].filter(
              (cla) => cla.id !== this.state.selected_clause.id
            ),
          });
        }
      })
      .catch((err) => console.log(err));
  };

  getAgreements = () => {
    Axios.get(`${this.context.api_endpoint}/company/contract/template`)
      .then(({ data }) => {
        this.setState({
          patterns: data.data.map((pat) =>
            pat.Clauses === null ? { Template: pat.Template, Clauses: [] } : pat
          ),
        });
      })
      .catch((err) => console.log(err));
  };

  updateAgreement = () => {
    Axios.put(
      `${this.context.api_endpoint}/company/contract/template/${this.state.edit_agreement.Template.id}`,
      {
        id: this.state.edit_agreement.Template.id,
        title: this.state.pattern_header,
        template_name: this.state.new_pattern_name,
        company_name: this.state.company_name,
        company_info: this.state.company_info,
        logo_url: false,
        address: this.state.address,
        nationality_id: this.state.id_number,
        tax_no: this.state.tax_number,
        phone: this.state.phone,
        services_employee_name: this.state.staff_name,
        show_service: this.state.service,
        show_amount: this.state.total_amount,
        clauses: this.state.agreement_clauses,
      }
    )
      .then(({ data }) => {
        if (data.status === 201) {
          if (data.data.Clauses === null) {
            let newPattern;
            newPattern = { Template: data.data.Template, Clauses: [] };
            this.setState({
              patterns: [...this.state.patterns].map((pat) =>
                pat.Template.id === newPattern.Template.id ? newPattern : pat
              ),
              tab: 0,
              is_edit: false,
            });
            toast.success("Sözleşme şablonu başarı ile güncellendi.");
          } else {
            this.setState({
              patterns: [...this.state.patterns].map((pat) =>
                pat.Template.id === data.data.Template.id ? data.data : pat
              ),
              tab: 0,
              is_edit: false,
            });
          }
        } else {
          toast.error(
            "Beklenmeyen bir hata oluştu. Lütfen müşteri temsilciniz ile bağlantıya geçiniz."
          );
        }
      })
      .catch((err) => console.log(err));
  };

  saveAgreement = () => {
    Axios.post(`${this.context.api_endpoint}/company/contract/template`, {
      title: this.state.pattern_header,
      template_name: this.state.new_pattern_name,
      company_name: this.state.company_name,
      company_info: this.state.company_info,
      logo_url: false,
      address: this.state.address,
      nationality_id: this.state.id_number,
      tax_no: this.state.tax_number,
      phone: this.state.phone,
      services_employee_name: this.state.staff_name,
      show_service: this.state.service,
      show_amount: this.state.total_amount,
      clauses: this.state.agreement_clauses,
    })
      .then((data) => {
        if (data.status === 201) {
          toast.success("Şablon başarıyla kaydedildi.");
          if (data.data.data.Clauses === null) {
            this.setState({
              patterns: [
                { Template: data.data.data.Template, Clauses: [] },
                ...this.state.patterns,
              ],
            });
          } else {
            this.setState({
              patterns: [data.data.data, ...this.state.patterns],
            });
          }
          setTimeout(() => {
            this.setState({ tab: 0 });
          }, 250);
        }
      })
      .catch((err) => console.log(err));
  };

  deleteAgreement = (id) => {
    Axios.delete(`${this.context.api_endpoint}/company/contract/template/${id}`)
      .then((data) => {
        if (data.status === 201) {
          this.setState({
            patterns: [...this.state.patterns].filter(
              (pat) => pat.Template.id !== id
            ),
          });
          toast.success(data.data.data);
        }
      })
      .catch((err) => console.log(err));
  };

  componentDidMount() {
    this.getClauses();
    this.getAgreements();
    this.getCompanyInfo();
    this.getLogoSrc();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.selected_customer !== this.state.selected_customer) {
      this.getCustomerAgreements();
    }
  }
  render() {
    const { tab, edit_mode, delete_modal, is_edit } = this.state;

    const TabMenuItem = this._TabMenuItem;
    const Agreements = this._Agreements;
    const CreateNewAgreement = this._CreateNewAgreement;
    const ClauseDetail = this._ClauseDetail;
    const EditClauseDetail = this._EditClauseDetail;
    const CustomerAgreements = this._CustomerAgreements;

    return (
      <AuthContainer authorities={[1]} limited_permission="sms">
        <Grid>
          <Column
            className="sx-12 sm-12 md-12 lg-12"
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Column
              className="sx-12 sm-12 md-12 lg-8"
              style={{ borderBottom: "1px solid #ccc", paddingBottom: "15px" }}
            >
              <Column className="sx-12 sm-12 md-12 lg-3">
                <TabMenuItem
                  tab={0}
                  btnProps={{
                    iconComponent: "",
                    title: "Sözleşme Şablonları",
                    onClick: () => {
                      this.setState({
                        is_edit: false,
                        tab: 0,
                      });
                    },
                  }}
                />
              </Column>
              <Column className="sx-12 sm-12 md-12 lg-3">
                <TabMenuItem
                  tab={1}
                  btnProps={{
                    iconComponent: "",
                    title: `${
                      is_edit ? "Sözleşmeyi Düzenle" : "Yeni Sözleşme Oluştur"
                    }`,
                    onClick: () => {
                      this.setState({
                        tab: 1,
                      });
                    },
                  }}
                />
              </Column>
              <Column className="sx-12 sm-12 md-12 lg-3">
                <TabMenuItem
                  tab={2}
                  btnProps={{
                    iconComponent: "",
                    title: "Sözleşme Maddeleri",
                    onClick: () => {
                      this.setState({
                        is_edit: false,
                        tab: 2,
                      });
                    },
                  }}
                />
              </Column>
              <Column className="sx-12 sm-12 md-12 lg-3">
                <TabMenuItem
                  tab={3}
                  btnProps={{
                    iconComponent: "",
                    title: "Sözleşmeler",
                    onClick: () => {
                      this.setState({
                        is_edit: false,
                        tab: 3,
                      });
                      this.getCustomerAgreements();
                    },
                  }}
                />
              </Column>
            </Column>
            {tab === 1 && (
              <Column className="sx-12 sm-12 md-12 lg-4">
                <Column
                  className="sx-12 sm-12 md-12 lg-8"
                  style={{ borderLeft: "1px solid #ccc" }}
                >
                  <TextField
                    inputProps={{ maxLength: 45 }}
                    required
                    id="outlined-required"
                    label="Yeni Şablon"
                    placeholder="Yeni Sözleşme Şablonu"
                    variant="outlined"
                    fullWidth
                    value={this.state.new_pattern_name}
                    onChange={(e) => {
                      this.setState({
                        new_pattern_name: e.target.value,
                      });
                    }}
                  />
                </Column>
                <Column className="sx-12 sm-12 md-12 lg-4">
                  <Button
                    disabled={
                      this.state.new_pattern_name === "" ||
                      this.state.new_pattern_name === null ||
                      this.state.new_pattern_name.trim().length === 0
                    }
                    fullWidth={true}
                    size="md"
                    icon="save"
                    title={is_edit ? "Şablonu Düzenle" : "Şablonu Kaydet"}
                    textColor="white"
                    backgroundColor="primary"
                    onClick={() => {
                      if (is_edit) {
                        this.updateAgreement();
                      } else {
                        this.saveAgreement();
                      }
                    }}
                  />
                </Column>
              </Column>
            )}
            {tab === 2 && (
              <Column
                className="sx-12 sm-12 md-12 lg-4"
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Column className="sx-12 sm-12 md-12 lg-6"></Column>
                <Column className="sx-12 sm-12 md-12 lg-6">
                  <Button
                    fullWidth={true}
                    title="Yeni Madde Oluştur"
                    backgroundColor="primary"
                    textColor="white"
                    icon="add"
                    onClick={() => {
                      this.setState({
                        edit_mode: true,
                        selected_clause: {
                          ...this.state.selected_clause,
                          type: 1,
                        },
                      });
                    }}
                  />
                </Column>
              </Column>
            )}
            {tab === 3 && (
              <Column
                className="sx-12 sm-12 md-12 lg-4"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Column className="sx-12 sm-12 md-12 lg-4">
                  {(this.state.total_byte / 1024 / 1024).toFixed(2)}mb / 50mb
                </Column>
                <Column className="sx-12 sm-12 md-12 lg-8">
                  <BorderLinearProgress
                    variant="determinate"
                    value={(2 * this.state.total_byte) / 1024 / 1024}
                  />
                </Column>
              </Column>
            )}
          </Column>
        </Grid>
        <Grid>{tab === 0 && <Agreements />}</Grid>
        <Grid>{tab === 1 && <CreateNewAgreement />}</Grid>
        {edit_mode ? (
          <Grid>{tab === 2 && <EditClauseDetail />}</Grid>
        ) : (
          <Grid>{tab === 2 && <ClauseDetail />}</Grid>
        )}
        <Grid>{tab === 3 && <CustomerAgreements />}</Grid>
        <AlertDialog
          title={"Bu Sözleşme Maddesini Silmeyi Onaylıyor musunuz?"}
          open={delete_modal}
          fullWidth
          closeHandler={() =>
            this.setState({
              delete_modal: false,
            })
          }
          disableBackdropClick={false}
          buttons={[
            {
              title: "Sil",
              textColor: "primary",
              backgroundColor: "primary-opacity",
              icon: "check",
              onClick: () => {
                this.deleteClauses();
              },
            },
            {
              title: "İptal",
              textColor: "grey",
              backgroundColor: "white",
              icon: "clear",
            },
          ]}
        >
          <div>
            <Alert severity="warning">
              <b>{"Bu işlem geri alınamaz!"}</b>
              <div style={{ marginTop: "15px" }}>
                {this.state.selected_clause.title}
              </div>
              <div>{this.state.selected_clause.content}</div>
              <br />
              <b>{"Sözleşme maddesi kalıcı olarak silinecektir."}</b>
            </Alert>
          </div>
        </AlertDialog>
      </AuthContainer>
    );
  }

  _TabMenuItem = ({ btnProps, tab }) => {
    return (
      <Button
        {...btnProps}
        fullWidth
        iconSize={20}
        style={{ borderRadius: "0px !important" }}
        containerStyle={{ justifyContent: "center" }}
        backgroundColor={this.state.tab === tab ? "primary-opacity" : ""}
        textColor={this.state.tab === tab ? "primary" : "grey"}
        size="lg"
      />
    );
  };

  _Agreements = () => {
    const {
      patterns,
      selected_agreement_dialog_show,
      selected_agreement,
      edit_agreement,
    } = this.state;
    const inputRef = React.useRef();

    return (
      <>
        <Grid>
          {patterns.map((pattern) => {
            return (
              <Column className="xs-12 sm-12 md-12 lg-4 mt-1">
                <div
                  style={{
                    fontSize: "0.5em",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    this.setState({
                      edit_agreement: pattern,
                      selected_agreement: pattern.Template.id,
                      selected_agreement_dialog_show: true,
                    })
                  }
                >
                  <div style={{ textAlign: "center", fontSize: "2em" }}>
                    {pattern.Template.pattern_name}
                  </div>
                  <Column className="sx-12 sm-12 md-12 lg-12">
                    <div
                      style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: "2em",
                      }}
                      className="mt-4 mb-2"
                    >
                      {pattern.Template.template_name}
                    </div>
                    <div
                      style={{
                        boxShadow: "0 0 2px 2px rgba(0,0,0,0.1)",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "start",
                        flexDirection: "column",
                        fontFamily: "roboto",
                        fontSize: "0.8em",
                        height: "60vh",
                        overflow: "scroll",
                      }}
                    >
                      {/* Sözleşme başlığı */}
                      <div
                        style={{
                          textAlign: "center",
                          marginTop: "30px",
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                          marginBottom: "20px",
                        }}
                      >
                        <div
                          style={{
                            width: "50%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginLeft: "1vw",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              width: "80%",
                              height: "10vh",
                              overflow: "hidden",
                            }}
                          >
                            {this.state.logo_src ? (
                              <img
                                style={{ objectFit: "cover", width: "100%" }}
                                src={`${this.context.api_endpoint}/${this.context.state.company_id}/${this.state.logo_src}`}
                              />
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>

                        <div
                          style={{
                            width: "50%",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <h1>{pattern.Template.company_name}</h1>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              marginTop: "10px",
                            }}
                          >
                            <h2 style={{ display: "block" }}>
                              {pattern.Template.title}
                            </h2>
                          </div>
                          <span
                            style={{
                              fontSize: "1.4em",
                              margin: "0 30px 0 auto",
                            }}
                          >
                            {moment().format("DD/MM/YYYY")}
                          </span>
                        </div>
                      </div>

                      {/* Sözleşmede kişisel bilgilerin olduğu bölüm */}
                      <div
                        style={{
                          border: "1px solid black",
                          width: "80%",
                          borderRadius: "3px",
                          padding: "5px 10px",
                          display: "flex",
                          flexWrap: "wrap",
                        }}
                      >
                        {/* Ad soyad */}
                        <div
                          style={{
                            width: "45%",
                            fontWeight: "bold",
                          }}
                        >
                          {this.context.t(
                            `[settings/agreements].personal_info.name_and_surname`
                          )}
                          :
                        </div>
                        {/* Telefon Numarası */}
                        {pattern.Template.phone ? (
                          <div
                            style={{
                              width: "45%",
                              fontWeight: "bold",
                            }}
                          >
                            {this.context.t(
                              `[settings/agreements].personal_info.phone`
                            )}
                            :
                          </div>
                        ) : (
                          <></>
                        )}
                        {/* Doğum Tarihi */}
                        {pattern.Template.birth_date ? (
                          <div
                            style={{
                              width: "45%",
                              fontWeight: "bold",
                            }}
                          >
                            {this.context.t(
                              `[settings/agreements].personal_info.birth_date`
                            )}
                            :
                          </div>
                        ) : (
                          <></>
                        )}
                        {/* Meslek */}
                        {pattern.Template.job ? (
                          <div
                            style={{
                              width: "45%",
                              fontWeight: "bold",
                            }}
                          >
                            {this.context.t(
                              `[settings/agreements].personal_info.job`
                            )}
                            :
                          </div>
                        ) : (
                          <></>
                        )}
                        {/* tc No */}
                        {pattern.Template.nationality_id ? (
                          <div
                            style={{
                              width: "45%",
                              fontWeight: "bold",
                            }}
                          >
                            {this.context.t(
                              `[settings/agreements].personal_info.id_number`
                            )}
                            :
                          </div>
                        ) : (
                          <></>
                        )}
                        {/* vergi no */}
                        {pattern.Template.tax_no ? (
                          <div
                            style={{
                              width: "45%",
                              fontWeight: "bold",
                            }}
                          >
                            {this.context.t(
                              `[settings/agreements].personal_info.tax_number`
                            )}
                            :
                          </div>
                        ) : (
                          <></>
                        )}
                        {/* adres */}
                        {pattern.Template.address ? (
                          <div
                            style={{
                              width: "90%",
                              fontWeight: "bold",
                            }}
                          >
                            {this.context.t(
                              `[settings/agreements].personal_info.address`
                            )}
                            :
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>

                      {/* Sözleşmede ödeme bilgilerinin olduğu bölüm */}
                      {pattern.Template.services_employee_name ||
                      pattern.Template.show_service ? (
                        <div
                          style={{
                            border: "1px solid black",
                            width: "80%",
                            borderRadius: "3px",
                            margin: "10px 0 0 0",
                            padding: "5px 10px",
                          }}
                        >
                          <div style={{ display: "flex" }}>
                            {pattern.Template.services_employee_name ? (
                              <div style={{ width: "45%", fontWeight: "bold" }}>
                                Personel Adı
                              </div>
                            ) : (
                              <></>
                            )}
                            {pattern.Template.show_service ? (
                              <div style={{ width: "45%", fontWeight: "bold" }}>
                                Hizmet
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>

                          <div style={{ display: "flex" }}></div>
                        </div>
                      ) : (
                        <></>
                      )}
                      {pattern.Template.show_amount ? (
                        <div
                          style={{
                            fontSize: "1.2em",
                            fontWeight: "bold",
                            margin: "10px 30px 10px auto",
                          }}
                        >
                          {" "}
                          Toplam Tutar: ₺
                        </div>
                      ) : (
                        <></>
                      )}

                      {/* Sözleşmenin maddelerinin içerdiği bölüm */}

                      {this.state.clauses
                        .filter((clauseInfo) =>
                          pattern.Clauses.includes(clauseInfo.id)
                        )
                        .filter((el) => el.type === 2).length > 0 && (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                            width: "85%",
                            borderBottom: "1px solid #b3b3b3",
                          }}
                        >
                          <span
                            style={{ paddingRight: "24px", fontSize: "8px" }}
                          >
                            Evet
                          </span>
                          <span
                            style={{ paddingRight: "10px", fontSize: "8px" }}
                          >
                            Hayır
                          </span>
                        </div>
                      )}

                      {pattern.Clauses.map((el, index) => {
                        return (
                          <>
                            {this.state.clauses.filter(
                              (clauseInfo) =>
                                clauseInfo.id === el && clauseInfo.type === 2
                            ).length > 0 ? (
                              <div
                                style={{
                                  width: "85%",
                                  padding: "5px 0px",
                                  display: "flex",
                                  flexWrap: "wrap",
                                  textAlign: "start",
                                  height: "content-fit",
                                }}
                              >
                                <div
                                  style={{
                                    width: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <span>
                                    {this.state.clauses
                                      .filter(
                                        (clauseInfo) => clauseInfo.id === el
                                      )[0]
                                      .content.split("\n")
                                      .map((str) => (
                                        <span
                                          style={{
                                            fontSize: "10px",
                                          }}
                                        >
                                          {str}
                                        </span>
                                      ))}
                                  </span>
                                  <div
                                    style={{
                                      height: "18px",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Checkbox
                                      style={{ transform: "scale(0.2)" }}
                                      checked={true}
                                      fontSize="small"
                                      color="primary"
                                    />
                                    <Checkbox
                                      checked={false}
                                      style={{ transform: "scale(0.2)" }}
                                      fontSize="small"
                                      color="primary"
                                    />
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <></>
                            )}
                          </>
                        );
                      })}

                      {this.state.clauses
                        .filter((clauseInfo) =>
                          pattern.Clauses.includes(clauseInfo.id)
                        )
                        .filter((el) => el.type === 1).length > 0 && (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                            width: "85%",
                            borderBottom: "1px solid #b3b3b3",
                          }}
                        ></div>
                      )}

                      {pattern.Clauses.map((el, index) => {
                        return (
                          <>
                            {this.state.clauses.filter(
                              (clauseInfo) =>
                                clauseInfo.id === el && clauseInfo.type !== 2
                            ).length > 0 ? (
                              <div
                                style={{
                                  width: "85%",
                                  padding: "5px 0px",
                                  display: "flex",
                                  flexWrap: "wrap",
                                  textAlign: "start",
                                }}
                              >
                                <div
                                  style={{
                                    width: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <span>
                                    {this.state.clauses
                                      .filter(
                                        (clauseInfo) => clauseInfo.id === el
                                      )[0]
                                      .content.split("\n")
                                      .map((str) => (
                                        <p
                                          style={{
                                            fontSize: "10px",
                                          }}
                                        >
                                          {str}
                                        </p>
                                      ))}
                                  </span>
                                </div>
                              </div>
                            ) : (
                              <></>
                            )}
                          </>
                        );
                      })}

                      <div
                        style={{
                          width: "85%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          padding: "20px 0",
                        }}
                      >
                        <div
                          style={{
                            width: "70%",
                          }}
                        >
                          <div>{pattern.Template.company_name}</div>
                          <span>{pattern.Template.company_info}</span>
                        </div>
                        <div>
                          <span>İmza</span>
                          <div>{pattern.Template.company_name}</div>
                        </div>
                      </div>
                    </div>
                  </Column>
                </div>
              </Column>
            );
          })}
        </Grid>

        <AlertDialog
          title={""}
          open={selected_agreement_dialog_show}
          fullWidth
          closeHandler={() =>
            this.setState({ selected_agreement_dialog_show: false })
          }
          disableBackdropClick={false}
          buttons={[
            {
              title: "Sil",
              textColor: "white",
              backgroundColor: "red",
              onClick: () => {
                this.setState({
                  delete_agreement_id: selected_agreement,
                  delete_confirm: true,
                });
              },
            },
            {
              title: "Düzenle",
              textColor: "white",
              backgroundColor: "grey",
              onClick: () => {
                this.setState({
                  is_edit: true,
                  tab: 1,
                  // company bilgileri
                  company_name: edit_agreement.Template.company_name,
                  pattern_header: edit_agreement.Template.title,
                  new_pattern_name: edit_agreement.Template.template_name,
                  // Kişisel bilgiler
                  address: edit_agreement.Template.address,
                  tax_number: edit_agreement.Template.tax_no,
                  id_number: edit_agreement.Template.nationality_id,
                  phone: edit_agreement.Template.phone,
                  // Ödeme Bilgileri
                  staff_name: edit_agreement.Template.services_employee_name,
                  service: edit_agreement.Template.show_service,
                  total_amount: edit_agreement.Template.show_amount,
                  // maddeler
                  agreement_clauses: edit_agreement.Clauses,
                });
              },
            },
            {
              title: "Kapat",
              textColor: "white",
              backgroundColor: "primary",
            },
          ]}
        >
          {patterns &&
            patterns
              .filter((pat) => pat.Template.id === selected_agreement)
              .map((pattern) => {
                return (
                  <Column className="xs-12 sm-12 md-12 lg-2 mt-1">
                    <div
                      ref={inputRef}
                      style={{
                        margin: `${this.state.print_dialog ? "50px" : ""}`,
                      }}
                    >
                      {!this.state.print_dialog ? (
                        <div style={{ textAlign: "center" }}>
                          {pattern.Template.pattern_name}
                        </div>
                      ) : (
                        <></>
                      )}
                      <Column className="sx-12 sm-12 md-12 lg-12">
                        <div
                          className="mt-3"
                          style={{
                            boxShadow: "0 0 2px 2px rgba(0,0,0,0.1)",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                            fontFamily: "roboto",
                            fontSize: "0.8em",
                          }}
                        >
                          {/* Sözleşme başlığı */}

                          <div
                            style={{
                              textAlign: "center",
                              marginTop: "30px",
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                              marginBottom: "20px",
                            }}
                          >
                            <div
                              style={{
                                width: "50%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "start",
                                marginLeft: "2vw",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  width: "80%",
                                  height: "13vh",
                                  overflow: "hidden",
                                }}
                              >
                                {this.state.logo_src ? (
                                  <img
                                    style={{
                                      objectFit: "cover",
                                      width: "100%",
                                    }}
                                    src={`${this.context.api_endpoint}/${this.context.state.company_id}/${this.state.logo_src}`}
                                  />
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                            <div
                              style={{
                                width: "50%",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <h1>{pattern.Template.company_name}</h1>
                              </div>

                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  marginTop: "10px",
                                }}
                              >
                                <h2>{pattern.Template.title}</h2>
                              </div>
                              <span
                                style={{
                                  fontSize: "1em",
                                  margin: "0 50px 0 auto",
                                }}
                              >
                                {moment().format("DD/MM/YYYY")}
                              </span>
                            </div>
                          </div>

                          {/* Sözleşmede kişisel bilgilerin olduğu bölüm */}

                          <div
                            style={{
                              border: "1px solid black",
                              width: "80%",
                              borderRadius: "3px",
                              padding: "5px 10px",
                              display: "flex",
                              flexWrap: "wrap",
                            }}
                          >
                            {/* Ad soyad */}
                            <div
                              style={{
                                width: "45%",
                                fontWeight: "bold",
                              }}
                            >
                              {this.context.t(
                                `[settings/agreements].personal_info.name_and_surname`
                              )}
                              :
                            </div>
                            {/* Telefon Numarası */}
                            {pattern.Template.phone ? (
                              <div
                                style={{
                                  width: "45%",
                                  fontWeight: "bold",
                                }}
                              >
                                {this.context.t(
                                  `[settings/agreements].personal_info.phone`
                                )}
                                :
                              </div>
                            ) : (
                              <></>
                            )}
                            {/* Doğum Tarihi */}
                            {pattern.Template.birth_date ? (
                              <div
                                style={{
                                  width: "45%",
                                  fontWeight: "bold",
                                }}
                              >
                                {this.context.t(
                                  `[settings/agreements].personal_info.birth_date`
                                )}
                                :
                              </div>
                            ) : (
                              <></>
                            )}
                            {/* Meslek */}
                            {pattern.Template.job ? (
                              <div
                                style={{
                                  width: "45%",
                                  fontWeight: "bold",
                                }}
                              >
                                {this.context.t(
                                  `[settings/agreements].personal_info.job`
                                )}
                                :
                              </div>
                            ) : (
                              <></>
                            )}
                            {/* tc No */}
                            {pattern.Template.nationality_id ? (
                              <div
                                style={{
                                  width: "45%",
                                  fontWeight: "bold",
                                }}
                              >
                                {this.context.t(
                                  `[settings/agreements].personal_info.id_number`
                                )}
                                :
                              </div>
                            ) : (
                              <></>
                            )}
                            {/* vergi no */}
                            {pattern.Template.tax_no ? (
                              <div
                                style={{
                                  width: "45%",
                                  fontWeight: "bold",
                                }}
                              >
                                {this.context.t(
                                  `[settings/agreements].personal_info.tax_number`
                                )}
                                :
                              </div>
                            ) : (
                              <></>
                            )}
                            {/* adres */}
                            {pattern.Template.address ? (
                              <div
                                style={{
                                  width: "90%",
                                  fontWeight: "bold",
                                }}
                              >
                                {this.context.t(
                                  `[settings/agreements].personal_info.address`
                                )}
                                :
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>

                          {/* Sözleşmede ödeme bilgilerinin olduğu bölüm */}

                          {pattern.Template.services_employee_name ||
                          pattern.Template.show_service ? (
                            <div
                              style={{
                                border: "1px solid black",
                                width: "80%",
                                borderRadius: "3px",
                                margin: "10px 0 0 0",
                                padding: "5px 10px",
                              }}
                            >
                              <div style={{ display: "flex" }}>
                                {pattern.Template.services_employee_name ? (
                                  <div
                                    style={{ width: "45%", fontWeight: "bold" }}
                                  >
                                    Personel Adı
                                  </div>
                                ) : (
                                  <></>
                                )}
                                {pattern.Template.show_service ? (
                                  <div
                                    style={{ width: "45%", fontWeight: "bold" }}
                                  >
                                    Hizmet
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </div>

                              <div style={{ display: "flex" }}></div>
                            </div>
                          ) : (
                            <></>
                          )}
                          {pattern.Template.show_amount ? (
                            <div
                              style={{
                                fontSize: "1.2em",
                                fontWeight: "bold",
                                margin: "10px 40px 10px auto",
                              }}
                            >
                              {" "}
                              Toplam Tutar: ₺
                            </div>
                          ) : (
                            <></>
                          )}

                          {/* Sözleşmenin maddelerinin içerdiği bölüm */}

                          {this.state.clauses
                            .filter((clauseInfo) =>
                              pattern.Clauses.includes(clauseInfo.id)
                            )
                            .filter((el) => el.type === 2).length > 0 && (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-end",
                                width: "85%",
                                borderBottom: "1px solid #b3b3b3",
                              }}
                            >
                              <span
                                style={{
                                  paddingRight: "24px",
                                  fontSize: "8px",
                                }}
                              >
                                Evet
                              </span>
                              <span
                                style={{
                                  paddingRight: "10px",
                                  fontSize: "8px",
                                }}
                              >
                                Hayır
                              </span>
                            </div>
                          )}

                          {pattern.Clauses.map((el, index) => {
                            return (
                              <>
                                {this.state.clauses.filter(
                                  (clauseInfo) =>
                                    clauseInfo.id === el &&
                                    clauseInfo.type === 2
                                ).length > 0 ? (
                                  <div
                                    style={{
                                      width: "85%",
                                      padding: "5px 0px",
                                      display: "flex",
                                      flexWrap: "wrap",
                                      textAlign: "start",
                                      height: "content-fit",
                                    }}
                                  >
                                    <div
                                      style={{
                                        width: "100%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <span>
                                        {this.state.clauses
                                          .filter(
                                            (clauseInfo) => clauseInfo.id === el
                                          )[0]
                                          .content.split("\n")
                                          .map((str) => (
                                            <span
                                              style={{
                                                fontSize: "10px",
                                              }}
                                            >
                                              {str}
                                            </span>
                                          ))}
                                      </span>
                                      <div
                                        style={{
                                          height: "18px",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <Checkbox
                                          style={{ transform: "scale(0.2)" }}
                                          checked={true}
                                          fontSize="small"
                                          color="primary"
                                        />
                                        <Checkbox
                                          style={{ transform: "scale(0.2)" }}
                                          checked={false}
                                          fontSize="small"
                                          color="primary"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </>
                            );
                          })}
                          {this.state.clauses
                            .filter((clauseInfo) =>
                              pattern.Clauses.includes(clauseInfo.id)
                            )
                            .filter((el) => el.type === 1).length > 0 && (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-end",
                                width: "85%",
                                borderBottom: "1px solid #b3b3b3",
                              }}
                            ></div>
                          )}
                          {pattern.Clauses.map((el, index) => {
                            return (
                              <>
                                {this.state.clauses.filter(
                                  (clauseInfo) =>
                                    clauseInfo.id === el &&
                                    clauseInfo.type !== 2
                                ).length > 0 ? (
                                  <div
                                    style={{
                                      width: "85%",
                                      padding: "5px 0px",
                                      display: "flex",
                                      flexWrap: "wrap",
                                      textAlign: "start",
                                    }}
                                  >
                                    <div
                                      style={{
                                        width: "100%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <span>
                                        {this.state.clauses
                                          .filter(
                                            (clauseInfo) => clauseInfo.id === el
                                          )[0]
                                          .content.split("\n")
                                          .map((str) => (
                                            <p
                                              style={{
                                                fontSize: "10px",
                                              }}
                                            >
                                              {str}
                                            </p>
                                          ))}
                                      </span>
                                    </div>
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </>
                            );
                          })}

                          <div
                            style={{
                              width: "85%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              padding: "20px 0",
                            }}
                          >
                            <div
                              style={{
                                width: "70%",
                              }}
                            >
                              <div>{pattern.Template.company_name}</div>
                              <span>{pattern.Template.company_info}</span>
                            </div>
                            <div>
                              <span>İmza</span>
                              <div>Ahmet Karaduman</div>
                            </div>
                          </div>
                        </div>
                      </Column>
                    </div>
                  </Column>
                );
              })}
        </AlertDialog>

        <AlertDialog
          title={"Bu Sözleşme Şablonunu Silmeyi Onaylıyor musunuz?"}
          fullWidth
          open={this.state.delete_confirm}
          closeHandler={() => this.setState({ delete_confirm: false })}
          buttons={[
            {
              title: "Sil",
              icon: "check",
              backgroundColor: "primary-opacity",
              textColor: "primary",
              timer: 5,
              onClick: () =>
                this.deleteAgreement(this.state.delete_agreement_id),
            },
            {
              title: this.context.t(`services.delete.discardButtonTitle`),
              icon: "close",
              textColor: "grey",
            },
          ]}
        >
          <Alert severity="warning">
            <b>{"Bu işlem geri alınamaz!"}</b>
            <br />
            {"Sözleşme şablonu kalıcı olarak silinecektir."}
          </Alert>
        </AlertDialog>
      </>
    );
  };

  _CreateNewAgreement = () => {
    const {
      personal_info_show,
      name_and_surname,
      phone,
      id_number,
      tax_number,
      address,
      payment_info_show,
      staff_name,
      service,
      total_amount,
      clause_info_show,
      agreement_clauses,
      clauses,
      company_name,
      company_info,
      pattern_header,
      company_info_show,
      company_name_show,
      pattern_header_show,
      is_edit,
    } = this.state;

    return (
      <>
        <Column className="xs-12 sm-12 md-12 lg-6">
          {/* Kişisel bilgilerin seçildiği bölüm */}
          <Column className="xs-12 sm-12 md-12 lg-12 mt-2">
            <InfoCard
              onClick={() =>
                this.setState({
                  personal_info_show: !personal_info_show,
                })
              }
            >
              Kişisel Bilgiler
            </InfoCard>
            {personal_info_show ? (
              <InfoCardContent>
                <Column className="xs-12 sm-12 md-12 lg-12">
                  {/* ad soyad */}
                  <Column className="xs-12 sm-12 md-12 lg-6">
                    <Button
                      className="mb-1"
                      fullWidth={true}
                      size="xs"
                      icon={name_and_surname ? "check" : "close"}
                      title={this.context.t(
                        `[settings/agreements].personal_info.name_and_surname`
                      )}
                      outlined={name_and_surname}
                      textColor={name_and_surname ? "primary" : "black"}
                    />
                  </Column>

                  {/* Telefon Numarası */}
                  <Column className="xs-12 sm-12 md-12 lg-6">
                    <Button
                      className="mb-1"
                      fullWidth={true}
                      size="xs"
                      icon={phone ? "check" : "close"}
                      title={this.context.t(
                        `[settings/agreements].personal_info.phone`
                      )}
                      outlined={phone}
                      textColor={phone ? "primary" : "black"}
                      onClick={() =>
                        this.setState({
                          phone: !this.state.phone,
                        })
                      }
                    />
                  </Column>
                </Column>

                {/* Doğum tarihi */}
                {/* <Column className="xs-12 sm-12 md-12 lg-6">
                  <Button
                    className="mb-1"
                    fullWidth={true}
                    size="xs"
                    icon={birth_date ? "check" : "close"}
                    title={this.context.t(
                      `[settings/agreements].personal_info.birth_date`
                    )}
                    outlined={birth_date}
                    textColor={birth_date ? "primary" : "black"}
                    onClick={() =>
                      this.setState({
                        birth_date: !this.state.birth_date,
                      })
                    }
                  />
                </Column> */}

                {/* Meslek */}
                {/* <Column className="xs-12 sm-12 md-12 lg-6">
                  <Button
                    className="mb-1"
                    fullWidth={true}
                    size="xs"
                    icon={job ? "check" : "close"}
                    title={this.context.t(
                      `[settings/agreements].personal_info.job`
                    )}
                    outlined={job}
                    textColor={job ? "primary" : "black"}
                    onClick={() =>
                      this.setState({
                        job: !this.state.job,
                      })
                    }
                  />
                </Column> */}

                <Column className="xs-12 sm-12 md-12 lg-12">
                  <Column className="xs-12 sm-12 md-12 lg-6">
                    <Button
                      className="mb-1"
                      fullWidth={true}
                      size="xs"
                      icon={id_number ? "check" : "close"}
                      title={this.context.t(
                        `[settings/agreements].personal_info.id_number`
                      )}
                      outlined={id_number}
                      textColor={id_number ? "primary" : "black"}
                      onClick={() =>
                        this.setState({
                          id_number: !this.state.id_number,
                        })
                      }
                    />
                  </Column>

                  {/* Vergi no */}
                  <Column className="xs-12 sm-12 md-12 lg-6">
                    <Button
                      className="mb-1"
                      fullWidth={true}
                      size="xs"
                      icon={tax_number ? "check" : "close"}
                      title={this.context.t(
                        `[settings/agreements].personal_info.tax_number`
                      )}
                      outlined={tax_number}
                      textColor={tax_number ? "primary" : "black"}
                      onClick={() =>
                        this.setState({
                          tax_number: !this.state.tax_number,
                        })
                      }
                    />
                  </Column>
                </Column>

                {/* Tc No */}

                {/* Adres */}
                <Column className="xs-12 sm-12 md-12 lg-12">
                  <Column className="xs-12 sm-12 md-12 lg-6">
                    <Button
                      className="mb-1"
                      fullWidth={true}
                      size="xs"
                      icon={address ? "check" : "close"}
                      title={this.context.t(
                        `[settings/agreements].personal_info.address`
                      )}
                      outlined={address}
                      textColor={address ? "primary" : "black"}
                      onClick={() =>
                        this.setState({
                          address: !this.state.address,
                        })
                      }
                    />
                  </Column>
                </Column>
              </InfoCardContent>
            ) : (
              <></>
            )}
          </Column>

          {/* Ödeme bilgilerinin seçildiği bölüm */}
          <Column className="xs-12 sm-12 md-12 lg-12 mt-2">
            <InfoCard
              onClick={() =>
                this.setState({
                  payment_info_show: !payment_info_show,
                })
              }
            >
              Ödeme Bilgileri
            </InfoCard>

            {payment_info_show ? (
              <InfoCardContent>
                <Column className="xs-12 sm-12 md-12 lg-12">
                  {/* Personel ismi */}
                  <Column className="xs-12 sm-12 md-12 lg-6">
                    <Button
                      className="mb-1"
                      fullWidth={true}
                      size="xs"
                      icon={staff_name ? "check" : "close"}
                      title={this.context.t(
                        `[settings/agreements].payment_info.staff_name`
                      )}
                      outlined={staff_name}
                      textColor={staff_name ? "primary" : "black"}
                      onClick={() => {
                        if (service) {
                          this.setState({
                            staff_name: !this.state.staff_name,
                          });
                        }
                      }}
                    />
                  </Column>

                  {/* Servis */}
                  <Column className="xs-12 sm-12 md-12 lg-6">
                    <Button
                      className="mb-1"
                      fullWidth={true}
                      size="xs"
                      icon={service ? "check" : "close"}
                      title={this.context.t(
                        `[settings/agreements].payment_info.service`
                      )}
                      outlined={service}
                      textColor={service ? "primary" : "black"}
                      onClick={() => {
                        if (service === true) {
                          this.setState({
                            service: false,
                            staff_name: false,
                          });
                        } else {
                          this.setState({
                            service: true,
                          });
                        }
                      }}
                    />
                  </Column>
                </Column>

                <Column className="xs-12 sm-12 md-12 lg-12">
                  <Column className="xs-12 sm-12 md-12 lg-6">
                    <Button
                      className="mb-1"
                      fullWidth={true}
                      size="xs"
                      icon={total_amount ? "check" : "close"}
                      title={this.context.t(
                        `[settings/agreements].payment_info.total_amount`
                      )}
                      outlined={total_amount}
                      textColor={total_amount ? "primary" : "black"}
                      onClick={() =>
                        this.setState({
                          total_amount: !this.state.total_amount,
                        })
                      }
                    />
                  </Column>
                </Column>

                {/* Toplam tutar */}
              </InfoCardContent>
            ) : (
              <></>
            )}
          </Column>

          {/* Sözleşme maddelerini seçtiği bölüm */}
          <Column className="xs-12 sm-12 md-12 lg-12 mt-2">
            <InfoCard
              onClick={() =>
                this.setState({
                  clause_info_show: !clause_info_show,
                })
              }
            >
              Madde Bilgileri
            </InfoCard>
            <InfoCardContent>
              {this.state.clause_info_show ? (
                this.state.clauses.map((item) => (
                  <Column className="xs-12 sm-12 md-12 lg-12">
                    <div
                      style={{
                        display: "flex",
                        padding: "5px 10px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        if (agreement_clauses.includes(item.id)) {
                          this.setState({
                            agreement_clauses: agreement_clauses.filter(
                              (el) => el !== item.id
                            ),
                          });
                        } else {
                          this.setState({
                            agreement_clauses: [...agreement_clauses, item.id],
                          });
                        }
                      }}
                    >
                      {agreement_clauses.includes(item.id) ? (
                        <CheckIcon
                          color="primary"
                          fontSize="small"
                          style={{ padding: "5px" }}
                        />
                      ) : (
                        <CloseIcon
                          fontSize="small"
                          style={{ padding: "5px" }}
                        />
                      )}
                      {agreement_clauses.includes(item.id) ? (
                        <span
                          style={{
                            border: "1px solid rgba(49, 137, 236, 0.6)",
                            borderRadius: "5px",
                            color: "rgb(49, 137, 236)",
                            padding: "5px",
                          }}
                        >
                          {item.content.split("\n").map((str) => (
                            <p>{str}</p>
                          ))}
                        </span>
                      ) : (
                        <span
                          style={{
                            border: "1px solid rgb(255, 255, 255)",
                            borderRadius: "5px",
                            padding: "5px",
                          }}
                        >
                          {item.content.split("\n").map((str) => (
                            <p style={{ margin: "0 !important" }}>{str}</p>
                          ))}
                        </span>
                      )}
                      {item.type === 2 ? (
                        <>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              marginLeft: "10px",
                            }}
                          >
                            <span
                              style={{
                                border: "1px solid #3189ec",
                                background: "rgba(13, 78, 108, 0.04)",
                                color: "#3189ec",
                                fontSize: "10px",
                                borderRadius: "10px",
                                height: "fit-content",
                                padding: "5px",
                              }}
                            >
                              Evet/Hayır
                            </span>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </Column>
                ))
              ) : (
                <></>
              )}
            </InfoCardContent>
          </Column>
        </Column>

        {/* PDF yerleşimi */}
        <Column className="xs-12 sm-12 md-12 lg-6 mt-1">
          <Column className="sx-12 sm-12 md-12 lg-12">
            <div
              className="mt-3"
              style={{
                boxShadow: "0 0 2px 2px rgba(0,0,0,0.1)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                fontFamily: "roboto",
                fontSize: "0.8em",
              }}
            >
              {/* Sözleşme başlığı */}
              <div
                style={{
                  textAlign: "center",
                  marginTop: "30px",
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  marginBottom: "20px",
                }}
              >
                <div
                  style={{
                    width: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                    marginLeft: "2vw",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "80%",
                      height: "15vh",
                      overflow: "hidden",
                    }}
                  >
                    {this.state.logo_src ? (
                      <img
                        style={{ objectFit: "cover", width: "100%" }}
                        src={`${this.context.api_endpoint}/${this.context.state.company_id}/${this.state.logo_src}`}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div
                  style={{
                    width: "50%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {!company_name_show && (
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      value={company_name}
                      onChange={(e) =>
                        this.setState({
                          company_name: e.target.value,
                        })
                      }
                      placeholder="İşletme Adı"
                      InputProps={{
                        disableUnderline: true,
                        style: { textAlign: "right" },
                      }}
                      onBlur={() => {
                        this.setState({
                          company_name_show: true,
                        });
                      }}
                    />
                  )}
                  {company_name_show && (
                    <div
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      onClick={() => {
                        this.setState({
                          company_name_show: false,
                        });
                      }}
                    >
                      <h1>{company_name}</h1>
                      <EditIcon
                        fontSize="small"
                        color="primary"
                        style={{ marginBottom: "8px", padding: "0 10px" }}
                      />
                    </div>
                  )}

                  {!pattern_header_show && (
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      value={pattern_header}
                      onChange={(e) =>
                        this.setState({
                          pattern_header: e.target.value,
                        })
                      }
                      onBlur={() => {
                        this.setState({
                          pattern_header_show: true,
                        });
                      }}
                      placeholder="Sözleşme Başlığı"
                      InputProps={{
                        disableUnderline: true,
                      }}
                    />
                  )}
                  {pattern_header_show && (
                    <div
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "10px",
                      }}
                      onClick={() => {
                        this.setState({
                          pattern_header_show: false,
                        });
                      }}
                    >
                      <h2>{pattern_header}</h2>
                      <EditIcon
                        fontSize="small"
                        color="primary"
                        style={{ marginBottom: "8px", padding: "0 10px" }}
                      />
                    </div>
                  )}
                </div>
              </div>

              {/* Tarih */}
              <div style={{ width: "80%", textAlign: "end" }}>
                {moment().format("DD/MM/YYYY")}
              </div>

              {/* Sözleşmede kişisel bilgilerin olduğu bölüm */}
              {name_and_surname ||
              phone ||
              id_number ||
              tax_number ||
              address ? (
                <div
                  style={{
                    border: "2px solid black",
                    width: "90%",
                    borderRadius: "3px",
                    padding: "5px 10px",
                    display: "flex",
                    flexWrap: "wrap",
                  }}
                >
                  {/* Ad soyad */}
                  {name_and_surname ? (
                    <div
                      style={{
                        width: "45%",
                      }}
                    >
                      {this.context.t(
                        `[settings/agreements].personal_info.name_and_surname`
                      )}
                      :
                    </div>
                  ) : (
                    <></>
                  )}
                  {/* Telefon Numarası */}
                  {phone ? (
                    <div
                      style={{
                        width: "45%",
                      }}
                    >
                      {this.context.t(
                        `[settings/agreements].personal_info.phone`
                      )}
                      :
                    </div>
                  ) : (
                    <></>
                  )}
                  {/* tc No */}
                  {id_number ? (
                    <div
                      style={{
                        width: "45%",
                      }}
                    >
                      {this.context.t(
                        `[settings/agreements].personal_info.id_number`
                      )}
                      :
                    </div>
                  ) : (
                    <></>
                  )}
                  {/* vergi no */}
                  {tax_number ? (
                    <div
                      style={{
                        width: "45%",
                      }}
                    >
                      {this.context.t(
                        `[settings/agreements].personal_info.tax_number`
                      )}
                      :
                    </div>
                  ) : (
                    <></>
                  )}
                  {/* adres */}
                  {address ? (
                    <div
                      style={{
                        width: "90%",
                      }}
                    >
                      {this.context.t(
                        `[settings/agreements].personal_info.address`
                      )}
                      :
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              ) : (
                <></>
              )}

              {/* Sözleşmede ödeme bilgilerinin olduğu bölüm */}
              {staff_name || service ? (
                <div
                  style={{
                    border: "2px solid black",
                    width: "90%",
                    borderRadius: "3px",
                    margin: "10px 0",
                    padding: "5px 10px",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    {staff_name ? (
                      <div style={{ width: "45%" }}>Personel Adı</div>
                    ) : (
                      <></>
                    )}
                    {service ? (
                      <div style={{ width: "45%" }}>Hizmet</div>
                    ) : (
                      <></>
                    )}
                  </div>

                  <div style={{ display: "flex" }}></div>
                </div>
              ) : (
                <></>
              )}

              {total_amount ? (
                <div
                  style={{
                    fontSize: "1.2em",
                    fontWeight: "bold",
                    margin: "10px 30px 10px auto",
                  }}
                >
                  {" "}
                  Toplam Tutar: ₺
                </div>
              ) : (
                <></>
              )}

              {/* Sözleşmenin maddelerinin içerdiği bölüm */}

              {this.state.clauses
                .filter((clauseInfo) =>
                  agreement_clauses.includes(clauseInfo.id)
                )
                .filter((el) => el.type === 2).length > 0 && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    width: "90%",
                    borderBottom: "1px solid #b3b3b3",
                  }}
                >
                  <span style={{ paddingRight: "8px", fontSize: "16px" }}>
                    Evet
                  </span>
                  <span style={{ paddingRight: "12px", fontSize: "16px" }}>
                    Hayır
                  </span>
                </div>
              )}

              {agreement_clauses.map((el, index) => {
                return (
                  <>
                    {this.state.clauses.filter(
                      (clauseInfo) =>
                        clauseInfo.id === el && clauseInfo.type === 2
                    ).length > 0 ? (
                      <div
                        style={{
                          width: "90%",
                          borderRadius: "5px",
                          padding: "5px 10px",
                          display: "flex",
                          flexWrap: "wrap",
                          textAlign: "start",
                          height: "content-fit",
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <span>
                            {this.state.clauses
                              .filter((clauseInfo) => clauseInfo.id === el)[0]
                              .content.split("\n")
                              .map((str) => (
                                <p>{str}</p>
                              ))}
                          </span>
                          <div
                            style={{
                              height: "18px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Checkbox
                              style={{ transform: "scale(0.8)" }}
                              checked={true}
                              fontSize="small"
                              color="primary"
                            />
                            <Checkbox
                              checked={false}
                              style={{ transform: "scale(0.8)" }}
                              fontSize="small"
                              color="primary"
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </>
                );
              })}

              {this.state.clauses
                .filter((clauseInfo) =>
                  agreement_clauses.includes(clauseInfo.id)
                )
                .filter((el) => el.type === 1).length > 0 && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    width: "90%",
                    borderBottom: "1px solid #b3b3b3",
                  }}
                ></div>
              )}

              {/* {agreement_clauses.map(el => el.type === 1 ? <>yok</> : <>var</>)} */}
              {agreement_clauses.map((el, index) => {
                return (
                  <div
                    style={{
                      width: "90%",
                      borderRadius: "5px",
                      padding: "5px 10px",
                      display: "flex",
                      flexWrap: "wrap",
                      textAlign: "start",
                    }}
                  >
                    {this.state.clauses.filter(
                      (clauseInfo, index1) =>
                        clauseInfo.id === el && clauseInfo.type === 1
                    ).length > 0 ? (
                      <>
                        <span>
                          {this.state.clauses
                            .filter((clauseInfo) => clauseInfo.id === el)[0]
                            .content.split("\n")
                            .map((str) => (
                              <p>{str}</p>
                            ))}
                        </span>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                );
              })}

              <div
                style={{
                  width: "90%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "20px 0",
                }}
              >
                <div style={{ width: "70%" }}>
                  <p>{company_name}</p>
                  {!company_info_show ? (
                    <TextField
                      margin="normal"
                      multiline
                      required
                      fullWidth
                      value={company_info}
                      onChange={(e) =>
                        this.setState({
                          company_info: e.target.value,
                        })
                      }
                      placeholder="İşletme Bilgisi"
                      InputProps={{
                        disableUnderline: true,
                        style: { textAlign: "right" },
                      }}
                      onBlur={() => {
                        this.setState({
                          company_info_show: true,
                        });
                      }}
                    />
                  ) : (
                    <div
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "start",
                      }}
                      onClick={() => {
                        this.setState({
                          company_info_show: false,
                        });
                      }}
                    >
                      <p>{company_info}</p>
                      <EditIcon
                        fontSize="small"
                        color="primary"
                        style={{ marginBottom: "8px", padding: "0 10px" }}
                      />
                    </div>
                  )}
                </div>
                <div>
                  <p>Ahmet Karaduman</p>
                  <span>İmza</span>
                </div>
              </div>
            </div>
          </Column>
          {is_edit && (
            <Column className="sx-12 sm-12 md-12 lg-12 mt-3">
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Column className="sx-12 sm-12 md-12 lg-4">
                  <Button
                    fullWidth
                    size="md"
                    title="İptal"
                    textColor="white"
                    backgroundColor="red"
                    onClick={() => {
                      this.setState({
                        is_edit: false,
                        tab: 0,
                        // company bilgileri
                        company_name: "",
                        pattern_header: "",
                        new_pattern_name: "",
                        // Kişisel bilgiler
                        address: true,
                        tax_number: false,
                        id_number: false,
                        phone: true,
                        // Ödeme Bilgileri
                        staff_name: true,
                        service: true,
                        total_amount: true,
                        // maddeler
                        agreement_clauses: [],
                      });
                    }}
                  />
                </Column>
              </div>
            </Column>
          )}
        </Column>
      </>
    );
  };

  _EditClauseDetail = () => {
    const { clauses, selected_clause, update_clause } = this.state;
    console.log("Clauses:", clauses);
    return (
      <Grid>
        {/* Maddelerin edit sayfasındaki yeri */}
        <Column className="xs-12 sm-12 md-12 lg-6">
          {clauses.map((item) => (
            <List>
              <ListItem>
                <ListItemText
                  primary={
                    item.type === 2 ? (
                      <TypeShow>
                        {item.title}
                        <span>Evet/Hayır</span>
                      </TypeShow>
                    ) : (
                      item.title
                    )
                  }
                  secondary={item.content.split("\n").map((str) => (
                    <p>{str}</p>
                  ))}
                  style={{
                    paddingRight: "1.75vw",
                  }}
                />
                <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="delete">
                    <Delete
                      onClick={() => {
                        this.setState({
                          selected_clause: {
                            title: item.title,
                            content: item.content,
                            id: item.id,
                            type: item.type,
                          },
                          delete_modal: true,
                        });
                      }}
                    />
                  </IconButton>
                  <IconButton edge="end" aria-label="edit">
                    <Edit
                      onClick={() => {
                        this.setState({
                          selected_clause: {
                            title: item.title,
                            content: item.content,
                            id: item.id,
                            type: item.type,
                          },
                          edit_mode: true,
                          update_clause: true,
                        });
                      }}
                    />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            </List>
          ))}
        </Column>

        {/* Maddelerin editleneceği bölüm */}
        <Column
          className="xs-12 sm-12 md-12 lg-6"
          style={{ borderLeft: "1px solid #b2b2b2" }}
        >
          <Grid>
            <Column className="xs-12 sm-12 md-12 lg-12 mt-3">
              <TextField
                label="Madde Başlığı"
                value={selected_clause.title}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <div
                        onClick={async () => {
                          await this.setState({
                            selected_clause: {
                              ...this.state.selected_clause,
                              type:
                                this.state.selected_clause.type === 1 ? 2 : 1,
                            },
                          });
                        }}
                        style={{
                          background: "#fff",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                          padding: "8px 16px",
                          border: "1px solid #b2b2b2",
                          borderRadius: "15px",
                          zIndex: "99999999",
                          color: "#3189ec",
                        }}
                      >
                        <span>Evet/Hayır sorgusu eklensin</span>
                        <Checkbox
                          style={{
                            transform: "scale(1.5)",
                            padding: "5px 15px",
                          }}
                          checked={selected_clause.type === 1 ? false : true}
                          // onChange={async () => {
                          //   await this.setState({
                          //     clauses_type:
                          //       this.state.clauses_type === 1 ? 2 : 1,
                          //   });
                          // }}
                          color="primary"
                          inputProps={{ "aria-label": "secondary checkbox" }}
                        />
                      </div>
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  this.setState({
                    selected_clause: {
                      ...selected_clause,
                      title: e.target.value,
                    },
                  });
                }}
              />
            </Column>
            <Column className="xs-12 sm-12 md-12 lg-12">
              <TextField
                label="Madde İçeriği"
                multiline
                value={selected_clause.content}
                variant="outlined"
                fullWidth
                onChange={(e) => {
                  this.setState({
                    selected_clause: {
                      ...selected_clause,
                      content: e.target.value,
                    },
                  });
                }}
              />
            </Column>
            <Column className="xs-12 sm-12 md-12 lg-12">
              {update_clause ? (
                <>
                  <Column className="sx-12 sm-12 md-12 lg-6">
                    <Button
                      fullWidth={true}
                      title="Güncelle"
                      backgroundColor="primary"
                      textColor="white"
                      icon="update"
                      onClick={() => {
                        this.updateClauses();
                        this.setState({
                          edit_mode: false,
                          update_clause: false,
                        });
                      }}
                    />
                  </Column>
                  <Column className="sx-12 sm-12 md-12 lg-6">
                    <Button
                      fullWidth={true}
                      title="İptal"
                      backgroundColor="white"
                      textColor="black"
                      outlined
                      icon="close"
                      onClick={() => {
                        this.setState({
                          selected_clause: {},
                          edit_mode: false,
                          update_clause: false,
                        });
                      }}
                    />
                  </Column>
                </>
              ) : (
                <>
                  <Column className="sx-12 sm-12 md-12 lg-6">
                    <Button
                      fullWidth={true}
                      title="Kaydet"
                      backgroundColor="primary"
                      textColor="white"
                      icon="save"
                      onClick={() => {
                        if (
                          selected_clause.title.trim() !== "" &&
                          selected_clause.content.trim() !== ""
                        ) {
                          this.saveClauses();
                          this.setState({
                            edit_mode: false,
                          });
                        } else {
                          toast.warning("Lütfen alanları eksiksiz doldurun.");
                        }
                      }}
                      // onClick={() => {
                      //   console.log(this.state.clauses_type);
                      // }}
                    />
                  </Column>
                  <Column className="sx-12 sm-12 md-12 lg-6">
                    <Button
                      fullWidth={true}
                      title="İptal"
                      backgroundColor="white"
                      textColor="black"
                      outlined
                      icon="close"
                      onClick={() => {
                        this.setState({
                          selected_clause: {},
                          edit_mode: false,
                          clauses_type: 1,
                        });
                      }}
                    />
                  </Column>
                </>
              )}
            </Column>
          </Grid>
        </Column>
      </Grid>
    );
  };

  _ClauseDetail = () => {
    const { clauses, selected_clause } = this.state;
    return (
      <Grid>
        <Column className="xs-12 sm-12 md-12 lg-12">
          {clauses.map((item) => (
            <List>
              <ListItem>
                <ListItemText
                  primary={
                    item.type === 2 ? (
                      <TypeShow>
                        {item.title}
                        <span>Evet/Hayır</span>
                      </TypeShow>
                    ) : (
                      item.title
                    )
                  }
                  secondary={item.content.split("\n").map((str) => (
                    <p>{str}</p>
                  ))}
                  style={{
                    paddingRight: "1.5vw",
                  }}
                />
                <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="delete">
                    <Delete
                      onClick={() => {
                        this.setState({
                          selected_clause: {
                            title: item.title,
                            content: item.content,
                            id: item.id,
                          },
                          delete_modal: true,
                        });
                      }}
                    />
                  </IconButton>
                  <IconButton edge="end" aria-label="edit">
                    <Edit
                      onClick={() => {
                        this.setState({
                          selected_clause: {
                            ...selected_clause,
                            title: item.title,
                            content: item.content,
                            id: item.id,
                            type: item.type,
                          },
                          edit_mode: true,
                          update_clause: true,
                        });
                      }}
                    />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            </List>
          ))}
        </Column>
      </Grid>
    );
  };

  _CustomerAgreements = () => {
    return (
      <Grid>
        <Column className="sx-12 sm-12 md-12 lg-12">
          <Column className="sx-12 sm-12 md-12 lg-4">
            <DatePickerInput
              disableFuture
              className="mb-1"
              required
              label={"Başlangıç Tarihi"}
              value={this.state.start_date}
              onChange={async (date) => {
                await this.setState({
                  start_date: moment(date).tz("Europe/Istanbul").toISOString(),
                });
                await this.getCustomerAgreements();
              }}
            />
          </Column>
          <Column className="sx-12 sm-12 md-12 lg-4">
            <DatePickerInput
              disableFuture
              className="mb-1"
              required
              label={"Bitiş Tarihi"}
              value={this.state.end_date}
              onChange={async (date) => {
                await this.setState({
                  end_date: moment(date).tz("Europe/Istanbul").toISOString(),
                });
                await this.getCustomerAgreements();
              }}
            />
          </Column>
          <Column className="sx-12 sm-12 md-12 lg-4">
            <AutoCompleteInput
              autoHighLight
              label={"Müşteri ara"}
              placeholder={"Müşteri ara"}
              labelKey="full_name"
              onRemove={async () => {
                await this.setState({
                  selected_customer: null,
                });
              }}
              valueKey="id"
              selected={
                this.state.selected_customer !== null
                  ? this.state.selected_customer
                  : ""
              }
              returnFullObject
              selectedHandler={async (selected_customer) => {
                await this.setState({
                  selected_customer: selected_customer,
                });
              }}
              asyncDataService={async (keyword) =>
                await this.searchCustomerByKey(keyword)
              }
            />
          </Column>
        </Column>
        <Column className="sx-12 sm-12 md-12 lg-12">
          <Table
            refreshAction={() => this.getCustomerAgreements()}
            headings={{
              date: {
                label: "Tarih",
              },
              name: {
                label: "Ad Soyad",
              },
              agreement_name: {
                label: "Sözleşme Adı",
              },
              bytes: {
                label: "Boyutu",
                suffix: "mb",
              },
              _: {
                label: "İşlemler",
              },
            }}
            rows={this.state.filtered_customer_agreements}
            loaded={this.state.agreement_load}
            pagination={this.state.pagination}
            buttons={[
              {
                title: "",
                icon: "find_in_page",
                textColor: "blue",
                onClick: (row) => window.open(row.url, "_blank"),
              },

              {
                title: "",
                icon: "sms",
                textColor: "orange",
                onClick: (row) => {
                  this.setState({
                    sms_aproval_modal: true,
                    send_sms_id: row.id,
                    send_sms_customer_name: row.name,
                    send_sms_agreement_name: row.agreement_name,
                  });
                },
              },
              {
                title: "",
                icon: "delete_forever",
                textColor: "red",
                onClick: (row) => {
                  this.setState({
                    agreement_delete_id: row.id,
                    delete_agreement_dialog: true,
                  });
                },
              },
            ]}
          />
        </Column>

        {/* **
        sms onayı için açılan dialog
        */}
        <AlertDialog
          title="Kayıtlı sözleşmeyi SMS ile iletmek ister misiniz?"
          open={this.state.sms_aproval_modal}
          closeHandler={() => this.setState({ sms_aproval_modal: false })}
          buttons={[
            {
              title: "Gönder",
              icon: "send",
              backgroundColor: "primary-opacity",
              textColor: "primary",
              onClick: () => {
                this.sendSMSAgreement(this.state.send_sms_id);
              },
            },
            {
              title: "İptal",
              icon: "clear",
              textColor: "grey",
              onClick: () => {
                this.setState({
                  send_sms_id: null,
                  send_sms_customer_name: null,
                  send_sms_agreement_name: null,
                });
              },
            },
          ]}
        >
          {this.state.send_sms_customer_name} isimli müşteriye SMS ile '
          {this.state.send_sms_agreement_name}' göndermek istediğinize emin
          misiniz?
        </AlertDialog>

        {/* **
        wp onayı için açılan dialog
        */}
        <AlertDialog
          title="Kayıtlı sözleşmeyi Whatsapp ile iletmek ister misiniz?"
          // title="Kayıtlı sözleşmeyi Whatsapp ile iletmek ister misiniz?"
          open={this.state.wp_aproval_modal}
          closeHandler={() => this.setState({ wp_aproval_modal: false })}
          buttons={[
            {
              title: "Gönder",
              icon: "send",
              backgroundColor: "primary-opacity",
              textColor: "primary",
              onClick: () => {
                this.sendWPAgreement(this.state.send_wp_id);
              },
            },
            {
              title: "İptal",
              icon: "clear",
              textColor: "grey",
              onClick: () => {
                this.setState({
                  send_wp_id: null,
                  send_wp_customer_name: null,
                  send_wp_agreement_name: null,
                });
              },
            },
          ]}
        >
          {this.state.send_wp_customer_name} isimli müşteriye '
          {this.state.send_wp_agreement_name}' Whatsapp ile göndermek
          istediğinize emin misiniz?
        </AlertDialog>

        {/* **
        Sözleşmesi silmek için açılan dialog 
        */}
        <AlertDialog
          title="Sözleşmeyi Silmeyi Onaylıyor Musunuz?"
          open={this.state.delete_agreement_dialog}
          closeHandler={() => this.setState({ delete_agreement_dialog: false })}
          buttons={[
            {
              title: "Onayla ve Sil",
              icon: "check",
              backgroundColor: "primary-opacity",
              textColor: "primary",
              onClick: () => {
                this.deleteCustomerAgreement(this.state.agreement_delete_id);
              },
            },
            {
              title: "Vazgeç",
              icon: "close",
              textColor: "grey",
            },
          ]}
        >
          <Alert severity="warning">
            <b>Sözleşme kalıcı olarak silinecektir.</b>
            <br />
            Bu işlemin geri dönüşü olmayacaktır. Sözleşme kalıcı olarak
            silinecektir.
          </Alert>
        </AlertDialog>
      </Grid>
    );
  };
}

const InfoCard = styled.div`
  text-align: center;
  background-color: rgba(49, 137, 236, 0.6);
  border-radius: 5px;
  padding: 10px;
  font-weight: bold;
  color: white;
  cursor: pointer;
`;

const InfoCardContent = styled.div`
  margin-top: 5px;
`;

const Contents = styled.div`
  border: 1px solid red;
  width: 50%;
`;

const TypeShow = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;

  span {
    border: 1px solid #3189ec;
    background: rgba(13, 78, 108, 0.04);
    color: #3189ec;
    font-size: 10px;
    border-radius: 10px;
    height: fit-content;
    padding: 5px;
    margin-left: 10px;
  }
`;
