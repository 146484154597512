import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { ArrowBack, ArrowForward, FilterList } from "@material-ui/icons/";
import { IconButton } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";

const FieldCard = (props) => {
  const [openField, setOpenField] = useState(props.openField ?? false);
  const [openDescription, setOpenDescription] = useState(false);

  useEffect(() => {
    setOpenField(props.openField);
  }, [props.openField]);

  return (
    <div id={props.id}>
      <TitleContainer>
        <SectionTitle>{props.cardTitle}</SectionTitle>
        <DescriptionButton onClick={() => setOpenDescription((prev) => !prev)}>
          <InfoIcon color="primary" fontSize="small" />
          <div className="description-box">{props.helperText}</div>
        </DescriptionButton>
      </TitleContainer>
      <CardContent style={{ ...props.style }}>
        {props.showButtonHandler && (
          <div
            className="show-btn"
            style={{ right: openField ? "50%" : "10px" }}
          >
            <IconButton
              disabled={props.disableOpenField}
              onClick={() => {
                props.showButtonHandler(!openField);
                setOpenField(!openField);
              }}
            >
              {!openField ? <FilterList /> : <ArrowForward />}
            </IconButton>
          </div>
        )}
        {props.showButtonHandler && (
          <div className={`hidden-field ${openField ? "show" : "hide"}`}>
            {props.content}
          </div>
        )}
        {props.children}
      </CardContent>
    </div>
  );
};

export default FieldCard;

const CardContent = styled.div`
  background: white;
  border-radius: 15px;
  position: relative;
  box-shadow: 5px 5px 20px #8080806e;

  .show-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    transition: all 0.3s linear;
    z-index: 2;
  }

  .hidden-field {
    width: 50%;
    height: 100%;
    background: white;
    position: absolute;
    transition: all 0.3s linear;
    right: -100%;
    z-index: 2;

    &.show {
      right: 0;
    }

    &.hide {
      right: -100%;
    }
  }

  @media only screen and (min-width: 576px) {
    /* width */
    ::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: gray;
      border-radius: 10px;
    }
  }
`;

const TitleContainer = styled.div`
  width: max-content;
  height: 35px;
  margin-left: 5px;
  padding: 5px;
  background: white;
  position: relative;
  top: 5px;
  display: flex;
  border-radius: 15px 15px 0 0;
  box-shadow: 5px 5px 20px #8080806e;
`;

const SectionTitle = styled.h3`
  font-size: 16px;
  font-weight: 600;
  padding: 0;
  margin: 0 0 4px;
  color: var(--dark-primary);
`;

const DescriptionButton = styled.div`
  margin-left: 10px;

  .description-box {
    width: 300px;
    height: auto;
    background: gray;
    color: white;
    border: 1px solid; #ccc;
    border-radius: 5px;
    text-align: center;
    padding: 5px;
    display: none;
    position: absolute;
    right: -50%;
    z-index: 1000;
    transition: all 0.3s linear;
  }

  &:hover {
    .description-box {
      display: block;
    }
  }
`;
