import React from "react";
import AppContext from "../../../context/store";

import AuthContainer from "../../../components/AuthContainer";

import Input from "../../../theme/CustomMUI/Input";
import MaskedInput from "../../../theme/CustomMUI/MaskedInput";
import Select from "../../../theme/CustomMUI/Select";
import Dialog from "@material-ui/core/Dialog";

import {
  Checkbox,
  createTheme,
  // CircularProgress,
  FormControlLabel,
  MuiThemeProvider,
  Tooltip,
  Switch,
  Typography,
} from "@material-ui/core";
import Loader from "../../../assets/images/loader.gif";
import { Alert } from "@material-ui/lab";
import { Grid, Column } from "../../../theme/Grid";
import Button from "../../../theme/Button";
import Table from "../../../theme/Table";
import Axios from "axios";
import { toast } from "react-toastify";
import ModalDialog from "../../../theme/CustomMUI/AlertDialog";
import moment from "moment";
import DatePickerInput from "../../../theme/CustomMUI/DatePickerInput";
import Validate from "../../../functions/Validate";
import {
  InfoOutlined,
  Receipt,
  Event,
  Photo,
  DateRangeOutlined,
  Contacts,
  WhatsApp,
} from "@material-ui/icons";
import StatsCard from "../../../components/Cards/StatsCard";
import ConvertTimeToZulu from "../../../functions/ConvertTimeToZulu";
import CSwitch from "../../../theme/CustomMUI/CSwitch";
import AlertDialog from "../../../theme/CustomMUI/AlertDialog";
import BetweenTimeGenerator from "../../../functions/BetweenTimeGenerator";
import getDayIndex from "../../../functions/GetDayIndex";
import DisabledAppointmentsGenerator from "../../../functions/DisabledAppointmentsGenerator";
import CalculateMessageCharCount from "../../../functions/CalculateMessageCharCount";
import CompressImage from "../../../functions/CompressImage";
import styled from "styled-components";
import FCLabel from "../../../theme/FCLabel";
import CloudComputing from "../../../assets/images/cloud-computing.svg";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import SmsOutlinedIcon from "@material-ui/icons/SmsOutlined";
import SmsIcon from "@material-ui/icons/Sms";
import SMSReview from "../../../components/Dialogs/SMSReview";
import NewAppointmentDialog from "../../../components/Dialogs/NewAppointmentDialog";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import loader from "../../../assets/images/loader.gif";

/*
<div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <img src={loader} width="80" height="80" alt="loading" />
            </div> */
const theme = createTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        color: "#3189ec",
        fontSize: "12px",
        backgroundColor: "white",
        boxShadow: "0px 0px 5px rgba(0,0,0,0.1)",
      },
    },
  },
});

let years = [];
for (let i = 1950; i < 2025; i++) {
  years.push({ name: i, value: i });
}
const days = [
  {
    value: "01",
    name: 1,
  },
  {
    value: "02",
    name: 2,
  },
  {
    value: "03",
    name: 3,
  },
  {
    value: "04",
    name: 4,
  },
  {
    value: "05",
    name: 5,
  },
  {
    value: "06",
    name: 6,
  },
  {
    value: "07",
    name: 7,
  },
  {
    value: "08",
    name: 8,
  },
  {
    value: "09",
    name: 9,
  },
  {
    value: "10",
    name: 10,
  },
  {
    value: "11",
    name: 11,
  },
  {
    value: "12",
    name: 12,
  },
  {
    value: "13",
    name: 13,
  },
  {
    value: "14",
    name: 14,
  },
  {
    value: "15",
    name: 15,
  },
  {
    value: "16",
    name: 16,
  },
  {
    value: "17",
    name: 17,
  },
  {
    value: "18",
    name: 18,
  },
  {
    value: "19",
    name: 19,
  },
  {
    value: "20",
    name: 20,
  },
  {
    value: "21",
    name: 21,
  },
  {
    value: "22",
    name: 22,
  },
  {
    value: "23",
    name: 23,
  },
  {
    value: "24",
    name: 24,
  },
  {
    value: "25",
    name: 25,
  },
  {
    value: "26",
    name: 26,
  },
  {
    value: "27",
    name: 27,
  },
  {
    value: "28",
    name: 28,
  },
  {
    value: "29",
    name: 29,
  },
  {
    value: "30",
    name: 30,
  },
  {
    value: "31",
    name: 31,
  },
];
const months = [
  {
    id: 0,
    value: "01",
    name: "Ocak",
  },
  {
    id: 1,
    value: "02",
    name: "Şubat",
  },
  {
    id: 2,
    value: "03",
    name: "Mart",
  },
  {
    id: 3,
    value: "04",
    name: "Nisan",
  },
  {
    id: 4,
    value: "05",
    name: "Mayıs",
  },
  {
    id: 5,
    value: "06",
    name: "Haziran",
  },
  {
    id: 6,
    value: "07",
    name: "Temmuz",
  },
  {
    id: 7,
    value: "08",
    name: "Ağustos",
  },
  {
    id: 8,
    value: "09",
    name: "Eylül",
  },
  {
    id: 9,
    value: "10",
    name: "Ekim",
  },
  {
    id: 10,
    value: "11",
    name: "Kasım",
  },
  {
    id: 11,
    value: "12",
    name: "Aralık",
  },
];
class SingleCustomer extends React.Component {
  static contextType = AppContext;

  state = {
    tab: 0,

    filter_expanded_statistic: false,
    filter_expanded_history: false,

    //todo : customer photos
    loaded_customer: false, // Skeleton gösterim değeri
    photos: [], // İstekten gelen fotoğraflar

    accountings: {
      1: 50, // premium photo limitation
      2: 20, // standart limitation
      3: 4, // free limitation
    },

    delete_modal: false, // Silme işlemi onay kutusu
    selected_photos: [], // Silme işlemi için seçilen fotoğraflar

    unavailableData: {
      single: [],
      between: [],
      day_closed: false,
      all_hours_closed: false,
    },
    availableAppointmentExpanded: false,
    unavaliableHours: [],

    hours: [...BetweenTimeGenerator(this.context.state.company_shift)],
    checkout_data_dialog: false,
    selected_staff: null,
    selected_service: null,
    selected_customer: null,
    customer_id: null,
    selected_description: "",
    selected_time: null,
    selected_date: new Date(),
    send_sms: true,
    note: null,
    services_by_staff: [],
    _staffs: [],

    //Customer Details
    customer: {
      name: "",
      surname: "",
      phone: null,
      dialCode: null,
      s_dialCode: null,
      email: null,
      sex: null,
      point: null,
      description: null,
      birthday: null,

      related_company: null,
      sms_send: null,
      nationalityID: null,
      //Picker
      real_selected_birthday: null,
      selectedPhone: {},
    },
    selected_day: moment(new Date() - 568080000000).format("DD"),
    selected_month: moment(new Date() - 568080000000).format("MM"),
    selected_year: moment(new Date() - 568080000000).format("YYYY"),

    passive_confirm: false,
    delete_confirm: false,

    loaded: false,
    //Single Service Transactions
    last_transactions: {
      //customer receipts
      start: ConvertTimeToZulu(moment().subtract(30, "days").unix()),
      end: ConvertTimeToZulu(moment().unix()),
      filterPicker: [
        {
          id: 1,
          label: "Bugün",
          offset: 1,
        },
        {
          id: 2,
          label: "Bu Hafta",
          offset: 3,
        },
        {
          id: 3,
          label: "Bu Ay",
          offset: 4,
        },
      ],
      selected_filter_picker: null,
      replacements: {
        "customer.full_name": {
          null: "Tanımlanmamış Müşteri",
          "": "Tanımlanmamış Müşteri",
        },
        is_package: {
          true: "Paket Satışı",
          false: "Standart Satış",
        },
      },
      pagination: {
        page: 1,
        total_page: 1,
        onChange: (page) => this.getHistorySingleCustomer(page),
      },
      data: [],
    },

    //Apponitment Transactions
    appointments: {
      pagination: {
        page: 1,
        total_page: 1,
        onChange: (page) => this.getFilterByAppointmentsStatistics(page),
      },
      data: [],
    },

    // Customer appointments detal data
    customerAppointments: [],

    filter_before_insert: [
      {
        id: 1,
        label: "Tümünü Seç",
        choice: null,
      },
      {
        id: 2,
        label: "Önceki",
        choice: "before",
      },
      {
        id: 3,
        label: "Sonraki",
        choice: "after",
      },
    ],
    selected_filter_before_insert: null,

    // Customer appointments detail data filter models
    // customerAppointmentsFilter_offset: 30,
    filterPicker_statistic: [
      {
        id: 1,
        label: "Bugün",
        offset: 1,
      },
      {
        id: 2,
        label: "Bu Hafta",
        offset: 3,
      },
      {
        id: 3,
        label: "Bu Ay",
        offset: 4,
      },
    ],
    selected_filter_picker_statistic: null,
    // customerAppointmentsFilter_isBetween: false,
    customerAppointmentsFilter_startDate: ConvertTimeToZulu(
      moment().subtract(30, "days").unix()
    ),
    customerAppointmentsFilter_endDate: ConvertTimeToZulu(moment().unix()),

    customerAppointmentsFilterData: [],

    showBirthdayDate: false,

    sms_send_dialog: false,
    sms_settings: {
      message: "",
      id: [parseInt(this.props.match.params.id)],
    },

    whatsapp_con_dialog: false,
    whatsapp_settings: {
      message: "",
      phone_number: null,
    },

    sms_templates: [],
    shortCode: null,

    total_number: null,
    total_sms_credit: null,
    open_sms_review_dialog: false,

    // Sözleşmeler
    agreements: [],
    agreement_load: true,
    agreement_delete_id: null,

    delete_appointment_modal: false,
    delete_appointment_id: null,

    // sms onay dialogu
    sms_aproval_modal: false,
    // gönderilecek sms id,name,agreement name
    send_sms_id: null,
    send_sms_customer_name: null,
    send_sms_agreement_name: null,
    // Whatsapp onay dialogu
    wp_aproval_modal: false,
    // gönderilecek wp id
    send_wp_id: null,
    send_wp_customer_name: null,
    send_wp_agreement_name: null,
    // wp phone number
    send_wp_phone: null,
    send_wp_message: null,

    paginationAgreement: {
      page: null,
      total_page: null,
      onChange: (page) => this.getAgreement(page),
    },
    selectPhoneNumberList: [
      {
        id: 1,
        name: this.context.t(`['customers/add'].firstPhoneNumber`),
        type: 0,
      },
      {
        id: 2,
        name: this.context.t(`['customers/add'].secondPhoneNumber`),
        type: 1,
      },
      {
        id: 3,
        name: this.context.t(`['customers/add'].allPhoneNumber`),
        type: 2,
      },
    ],
    selectPhoneNumber: {},
    appointmentsListLoader: false,
  };

  // send sms to customer
  sendSMSAgreement = (id) => {
    Axios.post(
      `${this.context.api_endpoint}/company/contract/template/pdf/sms`,
      {
        id: id,
        is_wp: 1,
      }
    )
      .then((data) => {
        if (data.data.status === 201) {
          toast.success(data.data.data);
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  // send wp to customer
  sendWPAgreement = (id) => {
    Axios.post(
      `${this.context.api_endpoint}/company/contract/template/pdf/sms`,
      {
        id: id,
        is_wp: 2,
      }
    )
      .then((data) => {
        window.open(
          `https://api.whatsapp.com/send?phone=${this.state.customer.phone}&text=${data.data.data}`,
          "_blank"
        );
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  cancelAppointment = (appointment_id) => {
    Axios.post(`${this.context.api_endpoint}/company/appointment/cancel`, {
      appointment_id,
    })
      .then((response) => {
        if (response.status === 201) {
          toast.success("Randevu başarıyla iptal edildi.");
          this.getFilterByAppointmentsStatistics();
          this.setState({ delete_appointment_id: null });
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  getAgreement = (page) => {
    this.setState({ agreement_load: false });
    Axios.get(
      `${this.context.api_endpoint}/company/contract/template/pdf?customer_id=${
        this.state.customer.id
      }&page=${page ? page : 1}`
    )
      .then(({ data }) => {
        if (data.status === 200) {
          this.setState({
            agreements: data.data.pdfs.records.map((item) => {
              return {
                id: item.id,
                url: item.secure_url,
                date: moment(item.created_at).format(
                  "DD MMMM dddd, YYYY, HH:mm"
                ),
                agreement_name: item.title,
                bytes: (item.bytes / 1024 / 1024).toFixed(2),
                opened: item.is_connection_opened,
                name: `${item.customer_name} ${item.customer_surname}`,
              };
            }),
            agreement_load: true,
            paginationAgreement: {
              ...this.state.paginationAgreement,
              page: data.data.pdfs.page,
              total_page: data.data.pdfs.records.length,
            },
          });
        }
      })
      .catch((err) => console.log(err));
  };

  deleteAgreement = (id) => {
    Axios.delete(
      `${this.context.api_endpoint}/company/contract/template/pdf/${id}`
    )
      .then(({ data }) => {
        if (data.status === 200) {
          toast.success(data.data);
          this.getAgreement();
        }
      })
      .catch((err) => {
        toast.error(
          "Beklenmeyen bir hata meydana geldi. Lütfen müşteri temsilciniz ile iletişime geçiniz."
        );
      });

    this.setState({ agreement_delete_id: null });
  };

  getFilterByAppointmentsStatistics = async (page) => {
    this.setState({
      appointmentsListLoader: false,
    });
    const {
      match: { params },
    } = this.props;
    const { selected_filter_picker_statistic, filter_expanded_statistic } =
      this.state;

    Axios.get(
      `${this.context.api_endpoint}/company/customer/${params.id}/appointment/statistics`,
      filter_expanded_statistic
        ? selected_filter_picker_statistic === null
          ? {
              params: {
                page: page ? page : 1,
                isbetween: true,
                start:
                  this.state.customerAppointmentsFilter_startDate.split("T")[0],
                end: this.state.customerAppointmentsFilter_endDate.split(
                  "T"
                )[0],
              },
            }
          : {
              params: {
                page: page ? page : 1,
                isbetween: false,
                offset: selected_filter_picker_statistic.offset,
              },
            }
        : {
            params: {
              page: page ? page : 1,
            },
          }
    )
      .then(async (response) => {
        if (response.status === 200) {
          const maskedData = {
            fail_state_count: {
              label: "highlight_off",
              color: "red",
              title: "dashboard.stats.meta.notArrived",
            },
            success_state_count: {
              label: "check_circle_outline",
              color: "green",
              title: "dashboard.stats.meta.arrived",
            },
            waiting_state_count: {
              label: "hourglass_full",
              color: "orange",
              title: "dashboard.stats.meta.waiting",
            },
            canceled_appointments: {
              label: "close",
              color: "red",
              title: "İptal Edildi",
            },
          };

          const data = response.data.data;
          const transformData = [];

          Object.keys(data).map((item) => {
            Object.keys(maskedData).map((mask) => {
              if (item === mask) {
                transformData.push({
                  ...maskedData[mask],
                  value: data[item],
                });
              }
            });
          });

          transformData.unshift({
            label: "event_note",
            color: "dark-blue",
            title: "dashboard.stats.meta.total",
            value: data.total_appointment_count,
          });

          this.setState({
            customerAppointmentsFilterData: transformData,

            // To be used when the table structure changes

            loaded: true,
            appointments: {
              ...this.state.appointments,
              data: [
                ...data.appointments.map((appointment) => {
                  return appointment
                    ? {
                        ...appointment,
                        created_at: moment(appointment.created_at)
                          .tz("Europe/Istanbul")
                          .format("DD/MM/YYYY HH:mm"),
                        staff:
                          appointment.staff && appointment.staff.full_name ? (
                            appointment.staff.deleted_at !== null ? (
                              <p style={{ color: "red" }}>
                                {appointment.staff.full_name}
                                {"(Silinmiş Personel)"}
                              </p>
                            ) : (
                              `${appointment.staff.full_name}`
                            )
                          ) : (
                            ""
                          ),
                        appointment_start_date: moment(
                          appointment.appointment_start_date
                        )
                          .tz("Europe/Istanbul")
                          .format("DD/MM/YYYY HH:mm"),
                        appointmentDate: moment(
                          appointment.appointment_start_date
                        ).tz("Europe/Istanbul"),
                        service: appointment.service
                          ? appointment.service.name
                          : appointment.packet
                          ? appointment.packet.name
                          : "-",
                        note: appointment.note ? appointment.note : "-",
                        appointment_status: appointment.active,
                        active:
                          appointment.active !== 2 ? (
                            appointment.customer_state === 0 ? (
                              <span
                                className="text-success"
                                style={{
                                  marginLeft: "15px",
                                }}
                              >
                                <i
                                  className="material-icons"
                                  style={{ color: "green" }}
                                >
                                  check_circle_outline
                                </i>
                              </span>
                            ) : appointment.customer_state === 2 ? (
                              <span
                                className="text-warning"
                                style={{
                                  marginLeft: "15px",
                                }}
                              >
                                <i
                                  className="material-icons"
                                  style={{ color: "orange" }}
                                >
                                  hourglass_full
                                </i>
                              </span>
                            ) : (
                              <span
                                className="text-danger"
                                style={{
                                  marginLeft: "15px",
                                }}
                              >
                                <i
                                  className="material-icons"
                                  style={{ color: "red" }}
                                >
                                  highlight_off
                                </i>
                              </span>
                            )
                          ) : (
                            <span
                              className="text-success"
                              title={this.context.t(
                                `['appointments/filter'].canceled`
                              )}
                              style={{
                                marginLeft: "15px",
                              }}
                            >
                              <i
                                className="material-icons"
                                style={{ color: "red" }}
                              >
                                close
                              </i>
                            </span>
                          ),
                      }
                    : null;
                }),
              ],
            },
          });
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      })
      .finally(() => {
        this.setState({
          appointmentsListLoader: true,
        });
      });
  };

  //TODO : Customer get Photos
  /**
   * Veri kaynağı üzerindne fotoğrafları getiren fonksiyon.
   */
  getPhotos = () => {
    Axios.get(
      `${this.context.api_endpoint}/company/customer/photos?customerid=${
        this.props.location.pathname.split("/")[3]
      }`
    )
      .then((response) => {
        const { data } = response;
        if (response.status === 200) {
          this.setState({
            loaded_customer: true,
            photos: [...data.data],
            selected_photos: [],
          });
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  /**
   * Buttonda Seçimi yapılan fotoğrafın yüklenmesi.
   */
  uploadMultiPhotosNext = async (e, id) => {
    e.persist();

    const { accountings } = this.state;

    let currentAccounting = this.context.state.company_license.accounting;
    const appointment_limitation =
      this.context.state.company_license.appointment_limitation;

    if (currentAccounting === 2 && appointment_limitation > 0) {
      currentAccounting = 3;
    }

    if (
      accountings[currentAccounting] <
      [...e.target.files].length + this.state.photos.length
    ) {
      toast.error("Mevcut paket için fotoğraf limitini aştınız.");
    } else {
      const length = [...e.target.files].length;
      const Form = new FormData();

      this.setState({ loaded_customer: false });

      for (let i = 0; i < length; i++) {
        Form.append(
          "files",
          await CompressImage(e.target.files[i], {
            maxSizeMB: 2,
            fileType: "image/jpg",
          })
        );
        Form.append("customer_id", this.state.customer.id);
        Form.append("id", id);
      }

      Axios.post(`${this.context.api_endpoint}/company/customer/photos`, Form, {
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          if (response.status === 201) {
            toast.success(
              this.context.t(`['settings/service-gallery'].insertToast`)
            );
            this.getPhotos();
          }
        })
        .catch((e) => {
          this.setState({ loaded_customer: true });
          if (e.response.status === 401) {
            toast.error(e.response.data.message);
          } else {
            toast.warning(e.response.data.message);
          }
        });
    }

    if (
      currentAccounting === 2 &&
      this.state.business.license.appointment_limitation === 0
    ) {
      toast.error("Fotoğraf yüklemesi yapamazsınız.");
    } else {
    }
  };

  /**
   * Inputta seçimi yapılan fotoğrafları yükleyen fonksiyon.
   */
  uploadMultiPhotos = async (e) => {
    e.persist();

    const { accountings } = this.state;

    let currentAccounting = this.context.state.company_license.accounting;
    const appointment_limitation =
      this.context.state.company_license.appointment_limitation;

    if (currentAccounting === 2 && appointment_limitation > 0) {
      currentAccounting = 3;
    }

    if (
      accountings[currentAccounting] <
      [...e.target.files].length + this.state.photos.length
    ) {
      toast.error("Mevcut paket için fotoğraf limitini aştınız.");
    } else {
      const length = [...e.target.files].length;
      const Form = new FormData();

      this.setState({ loaded_customer: false });

      for (let i = 0; i < length; i++) {
        Form.append(
          "files",
          await CompressImage(e.target.files[i], {
            maxSizeMB: 2,
            fileType: "image/jpg",
          })
        );
        Form.append("customer_id", this.state.customer.id);
      }

      Axios.post(`${this.context.api_endpoint}/company/customer/photos`, Form, {
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          if (response.status === 201) {
            toast.success(
              this.context.t(`['settings/service-gallery'].insertToast`)
            );
            this.getPhotos();
          }
        })
        .catch((e) => {
          this.setState({ loaded_customer: true });
          if (e.response.status === 401) {
            toast.error(e.response.data.message);
          } else {
            toast.warning(e.response.data.message);
          }
        });
    }

    if (
      currentAccounting === 2 &&
      this.state.business.license.appointment_limitation === 0
    ) {
      toast.error("Fotoğraf yüklemesi yapamazsınız.");
    } else {
    }
  };

  /**
   * Multi seçimin sonucunda silme işlemini yapan fonksiyondur.
   */
  deleteMultiPhotos = () => {
    Axios.delete(
      `${this.context.api_endpoint}/company/customer/photos/${this.state.selected_photos[0]}`,
      {
        params: {
          choice: this.state.selected_filter_before_insert
            ? this.state.selected_filter_before_insert.choice
            : null,
        },
      }
    )
      .then((response) => {
        if (response.status === 201) {
          toast.success(
            this.context.t(`['settings/service-gallery'].deleteToast`)
          );
          this.getPhotos();
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  //TODO : staff and service
  // GET Staff
  beforeInsertRequiredFields = () => {
    Axios.get(`${this.context.api_endpoint}/company/appointments/index2`)
      .then(({ data }) => {
        const staffs = [
          ...data.data.map((staff) => {
            staff.services.forEach((service, index) => {
              if (
                service.service.category_name !== null &&
                service.service.category_name.toLowerCase().includes("erkek")
              ) {
                service.service.name += " (E)";
              } else if (
                service.service.category_name !== null &&
                service.service.category_name.toLowerCase().includes("kadın")
              ) {
                service.service.name += " (K)";
              }
            });
            return staff;
          }),
        ];
        this.setState({
          _staffs: [
            ...staffs
              .map((staff) => {
                return staff;
              })
              .filter((staff) => staff !== null),
          ],
        });
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  //TODO : staff hours

  // getStaffUnavailableHours = async () => {
  //   const { selected_staff, selected_date } = this.state;

  //   await Axios.get(
  //     `${this.context.api_endpoint}/company/appointments/staff/available`,
  //     {
  //       params: {
  //         staff_id: parseInt(selected_staff.id),
  //         date: moment(selected_date).format("YYYY-MM-DD"),
  //         day_index: getDayIndex(moment(selected_date).format("YYYY-MM-DD")),
  //       },
  //     }
  //   ).then(({ data }) => {
  //     this.setState({
  //       unavailableData: {
  //         ...data.data,
  //         single: data.data.single !== null ? data.data.single : [],
  //         between: data.data.between ?? [],
  //         all_hours_closed: data.data.all_hours_closed ?? false,
  //       },
  //       availableAppointmentExpanded: true,
  //     });
  //     this.setState({
  //       unavaliableHours: [
  //         ...DisabledAppointmentsGenerator(this.state.unavailableData),
  //       ],
  //     });
  //   });
  // };

  getSingleCustomer = async () => {
    const {
      match: { params },
    } = this.props;
    await Axios.get(
      `${this.context.api_endpoint}/company/customer/${params.id}`
    )
      .then(({ data }) => {
        this.setState({
          selectPhoneNumber:
            data.data.selectedPhone === 0
              ? this.state.selectPhoneNumberList.filter((m) => m.type === 0)
              : this.state.selectPhoneNumberList.filter(
                  (m) => m.type === data.data.selectedPhone
                ),
          customer: {
            ...data.data,
            birthday: moment(data.data.birthday).format("DD/MM/YYYY"),
            real_selected_birthday:
              data.data.birthday !== null
                ? new Date(moment(data.data.birthday).format("YYYY-MM-DD"))
                : null,
            phone:
              this.context.state.user.permission === 2 &&
              this.context.state.limited_permission.customer_info === 0
                ? ""
                : data.data.phone,
            secondary_phone:
              this.context.state.user.permission === 2 &&
              this.context.state.limited_permission.customer_info === 0
                ? ""
                : data.data.s_dialCode + data.data.secondary_phone,
            /*   */

            email:
              this.context.state.user.permission === 2 &&
              this.context.state.limited_permission.customer_info === 0
                ? ""
                : data.data.email,
            nationalityID: data.data.nationalityID
              ? data.data.nationalityID
              : "",
          },
        });

        if (data.data.birthday !== null) {
          this.setState({
            showBirthdayDate: true,
            selected_day: moment(data.data.birthday)
              .tz("Europe/Istanbul")
              .format("DD"),
            selected_month: moment(data.data.birthday)
              .tz("Europe/Istanbul")
              .format("MM"),
            selected_year: moment(data.data.birthday)
              .tz("Europe/Istanbul")
              .format("YYYY"),
          });
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  getHistorySingleCustomer = (page) => {
    const {
      match: { params },
    } = this.props;
    const { last_transactions, filter_expanded_history } = this.state;
    Axios.get(
      `${this.context.api_endpoint}/company/customer/${params.id}/history`,
      filter_expanded_history
        ? last_transactions.selected_filter_picker === null
          ? {
              params: {
                page: page ? page : 1,
                start: moment(last_transactions.start).format("YYYY-MM-DD"),
                end: moment(last_transactions.end).format("YYYY-MM-DD"),
                isbetween: true,
              },
            }
          : {
              params: {
                page: page ? page : 1,
                offset: last_transactions.selected_filter_picker.offset,
                isbetween: false,
              },
            }
        : {
            params: {
              page: page ? page : 1,
            },
          }
    )
      .then(({ data }) => {
        this.setState({
          loaded: true,
          last_transactions: {
            ...last_transactions,
            data: [
              ...data.data.records.map((record) => {
                return {
                  ...record,
                  all_amount: record.total ? record.total.toFixed(0) : "0.00",
                  paid: record.paid > 0 ? record.paid.toFixed(0) : "0.00",
                  created_at: moment(record.created_at)
                    .tz("Europe/Istanbul")
                    .format("DD/MM/YYYY HH:mm"),
                  debt_total:
                    (record.total ?? 0) - (record.paid ?? 0) < 1 &&
                    (record.total ?? 0) - (record.paid ?? 0) > 0
                      ? 0
                      : (record.total ?? 0) - (record.paid ?? 0) > 0
                      ? `${parseFloat(
                          ((record.total ?? 0) - (record.paid ?? 0)).toFixed(0)
                        )}`
                      : "0.00",
                };
              }),
            ],
          },
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  updateSingleCustomer = () => {
    const {
      match: { params },
    } = this.props;
    const { customer } = this.state;

    let birthday;
    if (this.state.showBirthdayDate) {
      // Create a new Date object with the selected year, month, and day
      const selectedDate = new Date(
        `${this.state.selected_year}-${this.state.selected_month}-${this.state.selected_day}`
      );

      // Add 3 hours to the selected date
      selectedDate.setHours(selectedDate.getHours() + 3);

      // Format the date and convert it to ISO string
      birthday = selectedDate.toISOString();
    }

    if (this.validateCustomer()) {
      try {
        Axios.put(
          `${this.context.api_endpoint}/company/customer/update?id:${params.id}`,
          {
            ...customer,
            birthday,
            phone:
              customer.phone && `${customer.phone}`.length === 10
                ? customer.phone
                : customer.secondary_phone
                ? ""
                : "",
            dialCode: this.state.customer.dialCode
              ? this.state.customer.dialCode
              : "+90",
            secondary_phone: this.state.customer.secondary_phone
              .slice(this.state.customer.s_dialCode.length)
              .trim(),
            s_dialCode: this.state.customer.s_dialCode.startsWith("+")
              ? this.state.customer.s_dialCode
              : "+" + this.state.customer.s_dialCode,
            nationalityID: this.state.customer.nationalityID
              ? this.state.customer.nationalityID
              : null,
            selectedPhone:
              this.state.selectPhoneNumber &&
              this.state.selectPhoneNumber[0].type,
          }
        )
          .then((response) => {
            if (response.status === 200) {
              toast.success(
                this.context.t(
                  `[appointments/filter].tabMenuLeft.updateCustomerDetailsTrue`
                )
              );
              setTimeout(() => {
                this.props.history.push("/customers");
              }, 1000);
            } else {
              toast.warn(
                this.context.t(
                  `[appointments/filter].tabMenuLeft.updateCustomerDetailsFalse`
                )
              );
            }
          })
          .catch((e) => {
            if (e.response.status === 404) {
              toast.warn(e.response.data.message);
            } else {
              toast.warn(e.response.data.message);
            }
          });
      } catch (error) {
        toast.warn(
          "Hatalı Tarih Girişi Yaptınız. Lütfen 'Doğum Tarihi'ni Yeniden Seçiniz."
        );
      }
    }
  };

  makePassiveSingleCustomer = () => {
    const {
      match: { params },
    } = this.props;
    const { customer } = this.state;
    Axios.post(
      `${this.context.api_endpoint}/company/customer/delete/multiple`,
      {
        customer_id: [parseInt(params.id)],
      }
    )
      .then((response) => {
        if (response.status === 200) {
          toast.success("Müşteri başarıyla pasif olarak ayarlandı!");
          this.setState({ customer: { ...customer, is_passive: true } });
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  makeActiveSingleCustomer = () => {
    const {
      match: { params },
    } = this.props;
    const { customer } = this.state;
    Axios.put(`${this.context.api_endpoint}/company/customer/state`, {
      customer_id: [parseInt(params.id)],
    })
      .then((response) => {
        if (response.status === 201) {
          toast.success("Müşteri başarıyla aktif olarak ayarlandı!");
          this.setState({ customer: { ...customer, is_passive: false } });
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  deleteSingleCustomer = () => {
    const {
      match: { params },
    } = this.props;
    Axios.delete(
      `${this.context.api_endpoint}/company/customer/delete/${params.id}`
    )
      .then((response) => {
        if (response.status === 200) {
          toast.success("Müşteri başarıyla silindi!");
          this.props.history.push("/customers");
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  validateCustomer = () => {
    let validate_for_phone_number;
    if (
      this.state.customer.secondary_phone === null ||
      this.state.customer.secondary_phone === ""
    ) {
      validate_for_phone_number = {
        field: "Telefon Numarası",
        value: this.state.customer.phone,
        condition: /\S{10}/,
      };
    } else {
      if (
        this.state.customer.phone === false ||
        this.state.customer.phone === true
      ) {
        validate_for_phone_number = {
          field: "Telefon Numarası",
          value: "",
          condition: /^\s*|\S{10}/,
        };
      } else {
        validate_for_phone_number = {
          field: "Telefon Numarası",
          value: this.state.customer.phone,
          condition: /^\s*|\S{10}/,
        };
      }
    }

    return Validate([
      {
        field: "İsim",
        value: this.state.customer.name,
        condition: /.+/,
      },
      {
        field: "Soyisim",
        value: this.state.customer.surname,
        condition: /.+/,
      },
      validate_for_phone_number,
    ]);
  };

  //TODO : insert Appointment
  insertAppointment = () => {
    const {
      selected_date,
      selected_service,
      selected_staff,
      selected_customer,
      customer,
      selected_time,
      send_sms,
      note,
    } = this.state;

    if (selected_time === null) {
      return toast.warning("Lütfen randevu gününe uygun bir saat seçiniz.");
    }
    let appointment_date = selected_date;
    let appointment_time = selected_time.split(":");
    appointment_date = `${moment(appointment_date).format("YYYY-MM-DD")}T${
      appointment_time[0]
    }:${appointment_time[1]}:00Z`;

    Axios.post(`${this.context.api_endpoint}/company/appointment/insert`, {
      staff_id: selected_staff.id,
      customer_id: customer.id,
      service_id: selected_service.id,
      send_sms: selected_customer
        ? selected_customer.send_sms === false
          ? false
          : send_sms
        : send_sms,
      appointment_date,
      note,
      is_web: true,
    })
      .then((response) => {
        if (response.status === 201) {
          toast.success(this.context.t(`appointments.insertToast`));
          this.setState({
            checkout_data_dialog: false,
          });
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  //TODO : add Receipts
  addReceiptBySelectedCustomer = () => {
    Axios.post(`${this.context.api_endpoint}/company/receipt/insert`, {
      customer_id: this.state.customer.id,
      note: "",
    })
      .then((response) => {
        if (response.status === 201) {
          toast.success(this.context.t(`['receipts/add'].insertToast`));
          this.props.history.push(`/receipts/detail/${response.data.data.id}`);
        }
      })
      .catch((e) => {
        toast.error(e.response.data.message);
      });
  };

  // Get templates for sms and whatsapp message

  getTemplates = () => {
    Axios.get(`${this.context.api_endpoint}/company/sms/template/all`).then(
      ({ data }) => {
        this.setState({
          sms_templates: [...data.data],
        });
      }
    );
  };

  // get shortcode

  getShortcode = () => {
    Axios.get(`${this.context.api_endpoint}/company/shortcode/list/keyword`)
      .then(async (response) => {
        if (response.status === 200) {
          await this.setState({
            shortCode: response.data.data.keyword,
          });
        }
      })
      .catch((e) => {});
  };

  // sms review

  getSMSReview = () => {
    const { sms_settings } = this.state;

    Axios.post(
      `${this.context.api_endpoint}/company/message/to/customer/preview`,
      {
        all: false,
        id: [parseInt(this.props.match.params.id)],
        except: [],
        message: sms_settings.message,
        sms_prefer: 1,
      }
    )
      .then(async (response) => {
        if (response.status === 200) {
          await this.setState({
            total_number: response.data.data.total_numbers,
            total_sms_credit: response.data.data.total_sms_credit,
            open_sms_review_dialog: true,
          });
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  // SMS final send
  sendSelectiveSms = () => {
    this.setState({ sms_request_status: true });

    Axios.post(`${this.context.api_endpoint}/company/message/to/customers`, {
      //Tüm müşterilere gönderilecek mi?
      all: false,

      //Müşteri ID listesi,
      id: [parseInt(this.props.match.params.id)],

      //Tüm müşteriler true ise hariç müşterilerin ID listesi,
      except: [],
      sms_prefer: 1,
      //Gönderilecek mesaj içeriği
      message: this.state.sms_settings.message,

      //Gönderilecek tarih
      send_date: null,
    })
      .then((response) => {
        if (response.status === 200) {
          toast.success(response.data.message);
        } else {
          toast.success(response.data.message);
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };
  SecondaryPhone_handleOnChange = (value, data, event, formattedValue) => {
    this.setState({
      customer: {
        ...this.state.customer,
        secondary_phone: value,
        s_dialCode: data.dialCode,
      },
    });
  };

  componentDidMount() {
    if (this.props.history.location.state === undefined) {
      this.getSingleCustomer();
    } else if (this.props.history.location.state.tab === 2) {
      this.setState({ tab: 2 });
      this.getFilterByAppointmentsStatistics();
    }

    this.getTemplates();
    this.getShortcode();
  }

  render() {
    const TabMenuItem = this._TabMenuItem;
    const CustomerInfo = this._CustomerInfoBuilder;
    const CustomerReceipts = this._CustomerReceiptsBuilder;
    const CustomerAppointments = this._CustomerAppointmentsBuilder;
    const CustomerPhotos = this._CustomerPhotosBuilder;
    const AutoAppointmentDialog = this._AutoAppointmentDialogBuilder;
    const CustomersContract = this._CustomersContract;

    const { tab } = this.state;

    return (
      <AuthContainer limited_permission="customer">
        <Grid>
          <Column className="xs-12 sm-12">
            <Column className="xs-12 sm-12 md-3 lg-12">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Column className="xs-12 sm-12 md-12 lg-2">
                  <TabMenuItem
                    tab={0}
                    btnProps={{
                      iconComponent: InfoOutlined,
                      title: this.context.t(
                        `[appointments/filter].tabMenuLeft.customerBill`
                      ),
                      onClick: () => {
                        this.setState({
                          tab: 0,
                        });
                      },
                    }}
                  />
                </Column>
                <Column className="xs-12 sm-12 md-12 lg-2">
                  {(this.context.current_flag &
                    this.context.state.FLAGS.SALON_RANDEVU && (
                    <TabMenuItem
                      tab={1}
                      btnProps={{
                        iconComponent: Receipt,
                        title: this.context.t(
                          `[appointments/filter].tabMenuLeft.customerAppointment`
                        ),
                        onClick: () => {
                          this.getHistorySingleCustomer();
                          this.setState({
                            tab: 1,
                          });
                        },
                      }}
                    />
                  )) ||
                    null}
                </Column>
                <Column className="xs-12 sm-12 md-12 lg-2">
                  <TabMenuItem
                    tab={2}
                    btnProps={{
                      iconComponent: Event,
                      title: this.context.t(
                        `[appointments/filter].tabMenuLeft.customerDetails`
                      ),
                      onClick: () => {
                        this.getFilterByAppointmentsStatistics();
                        this.beforeInsertRequiredFields();
                        this.setState({
                          tab: 2,
                        });
                      },
                    }}
                  />
                </Column>
                <Column className="xs-12 sm-12 md-12 lg-2">
                  <TabMenuItem
                    tab={3}
                    btnProps={{
                      iconComponent: Photo,
                      title: this.context.t(
                        `[appointments/filter].tabMenuLeft.customerPhotos`
                      ),
                      onClick: () => {
                        this.getPhotos();
                        this.setState({
                          tab: 3,
                        });
                      },
                    }}
                  />
                </Column>
                <Column className="xs-12 sm-12 md-12 lg-2">
                  <TabMenuItem
                    tab={4}
                    btnProps={{
                      iconComponent: Contacts,
                      title: this.context.t(
                        `[appointments/filter].tabMenuLeft.customerContract`
                      ),
                      onClick: () => {
                        this.getAgreement();
                        this.setState({
                          tab: 4,
                        });
                      },
                    }}
                  />
                </Column>
                <Column className="xs-12 sm-12 md-12 lg-2">
                  <TabMenuItem
                    tab={4}
                    btnProps={{
                      iconComponent: Receipt,
                      title: "Müşteri İşlem kayıtlarına git",
                      onClick: () => {
                        this.context.dispatch({
                          type: "LOG_TYPE",
                          payload: { log_type: 1 },
                        });
                        this.context.dispatch({
                          type: "LOG_ID",
                          payload: {
                            log_id: Number(this.state.customer.id),
                          },
                        });
                        this.props.history.push("/settings/logs");
                      },
                    }}
                  />
                </Column>
              </div>
            </Column>

            <Column
              className="xs-12 sm-12 md-9 lg-12 mt-1 pt-3"
              style={{
                borderTop: "1px solid #eee",
              }}
            >
              {tab === 0 && <CustomerInfo />}
              {(this.context.current_flag &
                this.context.state.FLAGS.SALON_RANDEVU &&
                tab === 1 && <CustomerReceipts />) ||
                null}
              {tab === 2 && <CustomerAppointments />}
              {tab === 3 && <CustomerPhotos />}
              {tab === 4 && <CustomersContract />}
            </Column>
          </Column>
        </Grid>
        <AutoAppointmentDialog />
      </AuthContainer>
    );
  }

  _TabMenuItem = ({ btnProps, tab }) => {
    return (
      <Button
        {...btnProps}
        iconSize={20}
        style={{ borderRadius: "0px !important" }}
        containerStyle={{ justifyContent: "center" }}
        backgroundColor={this.state.tab === tab ? "primary-opacity" : ""}
        textColor={this.state.tab === tab ? "primary" : "grey"}
        size="lg"
        fullWidth
      />
    );
  };

  _CustomerInfoBuilder = () => {
    const { customer, selected_day, selected_month, selected_year } =
      this.state;

    const { history } = this.props;
    return (
      <>
        <Grid className="mb-3">
          {this.context.state.user.permission === 0 && (
            <Column className="xs-12 sm-12">
              <Alert severity="warning" className="mb-3">
                <b>
                  Yetkileriniz gereğince müşteri bilgilerinde değişiklik
                  yapamazsınız!
                </b>
                <br />
                Müşteri bilgilerini düzenleme ve müşteriyi silme işlemlerini
                yalnızca işletme yetkilileri gerçekleştirebilir.
              </Alert>
            </Column>
          )}

          <Column className="xs-12 sm-12 md-12 lg-3">
            <Input
              required
              label={this.context.t(`component.CADialog.nameInputLabel`)}
              placeholder={this.context.t(
                `component.CADialog.nameInputPlaceholder`
              )}
              value={customer.name !== null ? customer.name : ""}
              onChange={(e) => {
                let nameText = e.target.value;
                this.setState({
                  customer: {
                    ...customer,
                    name:
                      nameText.split(" ")[1] !== undefined
                        ? nameText.split(" ")[0] + " " + nameText.split(" ")[1]
                        : nameText.split(" ")[0] !== undefined
                        ? nameText.split(" ")[0]
                        : nameText,
                  },
                });
              }}
            />
          </Column>

          <Column className="xs-12 sm-12 md-12 lg-3">
            <Input
              required
              label={this.context.t(`component.CADialog.surnameInputLabel`)}
              placeholder={this.context.t(
                `component.CADialog.surnameInputPlaceholder`
              )}
              value={customer.surname !== null ? customer.surname : ""}
              onChange={(e) => {
                let surnameText = e.target.value;
                this.setState({
                  customer: {
                    ...customer,
                    surname:
                      surnameText.split(" ")[1] !== undefined
                        ? surnameText.split(" ")[0] +
                          " " +
                          surnameText.split(" ")[1]
                        : surnameText,
                  },
                });
              }}
            />
          </Column>
          <Column className="xs-12 sm-12 md-12 lg-3">
            <Input
              label={this.context.t(
                `['customers/add/bulk'].preview.nationalID`
              )}
              placeholder={this.context.t(
                `['customers/add/bulk'].preview.nationalID`
              )}
              value={
                customer.nationalityID !== null
                  ? customer.nationalityID.substring(0, 11)
                  : ""
              }
              onChange={(e) => {
                const value = e.target.value.trim();
                if (value.length > 11) {
                  return;
                }
                this.setState({
                  customer: {
                    ...this.state.customer,
                    nationalityID: value,
                  },
                });
              }}
            />
          </Column>

          <Column className="xs-12 sm-12 md-12 lg-3">
            <CSwitch
              title={this.context.t(`component.CADialog.genderInputLabel`)}
              label={this.context.t(`component.CADialog.genderMale`)}
              labelLeft={this.context.t(`component.CADialog.genderFemale`)}
              checked={!!customer.sex}
              color="primary"
              closeColor="purple"
              onChange={(checked) => {
                this.setState({
                  customer: {
                    ...customer,
                    sex: checked.target.checked === false ? 0 : 1,
                  },
                });
              }}
            />
          </Column>
        </Grid>

        <Grid className="mb-3">
          <Column
            className="xs-12 sm-12 md-12 lg-3"
            style={{ position: "relative" }}
          >
            <MaskedInput
              required
              label={this.context.t(`component.CADialog.phoneInputLabel`)}
              disabled={
                this.context.state.user.permission === 2 &&
                (this.context.state.limited_permission.customer_info === 1 ||
                  this.context.state.limited_permission.customer_info === 0)
              }
              maskChar={""}
              mask="999 999 9999"
              startAdornment="+90"
              value={customer.phone !== null ? customer.phone : ""}
              placeholder="500 000 0000"
              onChange={(e) =>
                this.setState({
                  customer: {
                    ...customer,
                    phone:
                      e.target.value.substring(0, 1) === "5" &&
                      e.target.value.replace(/\s/g, ""),
                  },
                })
              }
            />
            {this.state.customer.phone === null ||
            this.state.customer.phone === "" ? (
              <></>
            ) : (
              <div
                style={{
                  position: "absolute",
                  top: "0",
                  right: "0",
                  height: "100%",
                  marginRight: "1vw",
                  display: "flex",
                  paddingTop: "1.5vh",
                  paddingLeft: "5px",
                }}
              >
                <MuiThemeProvider theme={theme}>
                  <Tooltip title={"Whatsapp ile iletişime geç"}>
                    <WhatsAppIcon
                      style={{
                        cursor: "pointer",
                        top: "2vh",
                        right: "3.5vw",
                        color: "green",
                        fontSize: "2.2em",
                        paddingRight: "10px",
                      }}
                      onClick={() => {
                        this.context.state.whatsapp_permission
                          ? this.setState({
                              whatsapp_con_dialog: true,
                              whatsapp_settings: {
                                ...this.state.whatsapp_settings,
                                phone_number: this.state.customer.phone,
                              },
                            })
                          : toast.warn(
                              `Ücretli içerik aktif değil. Lütfen temsilciniz ile iletişime geçiniz.`
                            );
                      }}
                    />
                  </Tooltip>
                </MuiThemeProvider>

                <MuiThemeProvider theme={theme}>
                  <Tooltip title={"Mesaj Gönder"}>
                    <SmsOutlinedIcon
                      style={{
                        cursor: "pointer",
                        top: "2vh",
                        right: "1.5vw",
                        color: "black",
                        fontSize: "2.2em",
                        paddingRight: "10px",
                      }}
                      onClick={() =>
                        this.setState({
                          sms_settings: {
                            ...this.state.sms_settings,
                            id: [this.props.match.params.id],
                          },
                          sms_send_dialog: true,
                        })
                      }
                    />
                  </Tooltip>
                </MuiThemeProvider>
              </div>
            )}
          </Column>

          <Column
            className="xs-12 sm-12 md-12 lg-3"
            style={{ position: "relative" }}
          >
            <PhoneInput
              inputStyle={{ border: "none", width: "100%" }}
              country={"tr"}
              countryCodeEditable={false}
              value={this.state.customer.secondary_phone}
              onClick={() => console.log(this.state.customer.secondary_phone)}
              placeholder="Enter a phone number"
              onChange={this.SecondaryPhone_handleOnChange}
              specialLabel={this.context.t(
                `['customers/add'].secondPhoneInputLabel`
              )}
            />

            {this.state.customer.secondary_phone === null ||
            this.state.customer.secondary_phone === "" ? (
              <></>
            ) : (
              <div
                style={{
                  position: "absolute",
                  top: "0",
                  right: "0",
                  height: "100%",
                  marginRight: "1vw",
                  display: "flex",
                  paddingTop: "1.5vh",
                  paddingLeft: "5px",
                }}
              >
                <MuiThemeProvider theme={theme}>
                  <Tooltip title={"Whatsapp ile iletişime geç"}>
                    <WhatsAppIcon
                      style={{
                        cursor: "pointer",
                        top: "2vh",
                        right: "3.5vw",
                        color: "green",
                        fontSize: "2.2em",
                        paddingRight: "10px",
                      }}
                      onClick={() =>
                        this.context.state.whatsapp_permission
                          ? this.setState({
                              whatsapp_con_dialog: true,
                              whatsapp_settings: {
                                ...this.state.whatsapp_settings,
                                foreign: false,
                                phone_number:
                                  this.state.customer.secondary_phone,
                              },
                            })
                          : toast.warn(
                              `Ücretli içerik aktif değil. Lütfen temsilciniz ile iletişime geçiniz.`
                            )
                      }
                    />
                  </Tooltip>
                </MuiThemeProvider>
              </div>
            )}
          </Column>
          <Column className="xs-12 sm-12 md-12 lg-3">
            <Input
              label={this.context.t(`component.CADialog.mailInputLabel`)}
              disabled={
                this.context.state.user.permission === 2 &&
                (this.context.state.limited_permission.customer_info === 1 ||
                  this.context.state.limited_permission.customer_info === 0)
              }
              value={customer.email !== null ? customer.email : ""}
              placeholder={this.context.t(
                `component.CADialog.mailInputPlaceholder`
              )}
              onChange={(e) =>
                this.setState({
                  customer: {
                    ...customer,
                    email: e.target.value,
                  },
                })
              }
            />
          </Column>
          <Column className="xs-12 sm-12 md-12 lg-3">
            <CSwitch
              title={this.context.t(`component.CADialog.sendSmsInputLabel`)}
              label={`${this.context.t(
                `component.CADialog.sendSmsFalse`
              )}/${this.context.t(`component.CADialog.sendSmsTrue`)}`}
              checked={!!customer.send_sms}
              color="primary"
              onChange={(checked) =>
                this.setState({
                  customer: {
                    ...customer,
                    send_sms: checked.target.checked,
                  },
                })
              }
            />
          </Column>
        </Grid>

        <Grid>
          {this.state.showBirthdayDate ? (
            <Column
              className="xs-12 sm-12 md-12 lg-3"
              style={{ display: "flex", position: "relative" }}
            >
              <div style={{ position: "absolute", top: "-2vh", left: "30px" }}>
                <span style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                  Doğum Tarihi
                </span>
              </div>
              <CostumSelect
                className="mt-1"
                style={{ cursor: "pointer", width: "60%" }}
                value={selected_day ? selected_day : 3}
                onChange={(e) => {
                  this.setState({ selected_day: e.target.value });
                }}
              >
                {days.map((item) => (
                  <option value={item.value}>{item.name}</option>
                ))}
              </CostumSelect>

              <CostumSelect
                className="m-1"
                style={{ cursor: "pointer" }}
                value={
                  selected_month
                    ? selected_month
                    : moment(new Date() - 568080000000).format("MM")
                }
                onChange={(e) => {
                  this.setState({ selected_month: e.target.value });
                }}
              >
                {months.map((item) => (
                  <option value={item.value}>{item.name}</option>
                ))}
              </CostumSelect>

              <CostumSelect
                className="mt-1"
                style={{ cursor: "pointer", width: "75%" }}
                value={
                  selected_year === ""
                    ? moment(new Date() - 568080000000).format("YYYY")
                    : selected_year
                }
                onChange={async (e) => {
                  this.setState({ selected_year: e.target.value });
                }}
              >
                {years.map((item) => (
                  <option value={item.value}>{item.name}</option>
                ))}
              </CostumSelect>
            </Column>
          ) : (
            <Column className="xs-12 sm-12 md-12 lg-3">
              <div
                style={{
                  boxShadow: "0px 2px 12px rgb(0 0 0 / 4%)",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "start",
                  height: "59px",
                }}
                onClick={() => {
                  this.setState({
                    showBirthdayDate: true,
                  });
                }}
              >
                <DateRangeOutlined
                  color="disabled"
                  style={{ padding: "10px" }}
                />
                <span>Doğum Tarihi</span>
              </div>
            </Column>
          )}

          <Column className="xs-12 sm-12 md-12 lg-3">
            <Input
              label={this.context.t(`[receipt/detail/id].payments.typePOINT`)}
              value={customer.point !== null ? customer.point : ""}
              onChange={(e) =>
                this.setState({
                  customer: {
                    ...customer,
                    point: /[0-9,.]+/.test(e.target.value)
                      ? parseFloat(e.target.value)
                      : 0,
                  },
                })
              }
            />
          </Column>

          <Column className="xs-12 sm-12 md-12 lg-3">
            <Input
              label={this.context.t(
                `component.CADialog.relatedBusinessInputLabel`
              )}
              placeholder={this.context.t(
                `component.CADialog.relatedBusinessInputPlaceholder`
              )}
              value={
                customer.related_company !== null
                  ? customer.related_company
                  : ""
              }
              onChange={(e) =>
                this.setState({
                  customer: {
                    ...customer,
                    related_company: e.target.value,
                  },
                })
              }
            />
          </Column>
          <Column className="xs-12 sm-12 md-12 lg-3">
            <Select
              label={this.context.t(
                `['customers/add'].selectPhoneNumberForMessage`
              )}
              items={this.state.selectPhoneNumberList}
              labelKey="name"
              valueKey="id"
              selected={
                this.state.selectPhoneNumber[0]
                  ? this.state.selectPhoneNumber[0].id
                  : this.state.selectPhoneNumberList[0].id
              }
              returnFullObject
              handler={(filterPicker) => {
                this.setState({
                  selectPhoneNumber: [filterPicker],
                });
              }}
            />
          </Column>
        </Grid>

        <Grid>
          <Column className="xs-12 sm-12">
            <Input
              label={this.context.t(`component.CADialog.descriptionInputLabel`)}
              value={customer.description !== null ? customer.description : ""}
              multiline={true}
              rows={3}
              onChange={(e) =>
                this.setState({
                  customer: {
                    ...customer,
                    description: e.target.value,
                  },
                })
              }
            />
          </Column>
        </Grid>

        <Grid>
          <Column className="xs-12 sm-12">
            <Button
              type="submit"
              title={this.context.t(`component.CADialog.updateCustomerDetails`)}
              icon="update"
              backgroundColor="primary"
              textColor="white"
              disabled={
                this.context.state.user.permission === 2 &&
                this.context.state.limited_permission.customer_info === 1
              }
              fullWidth
              onClick={() => this.updateSingleCustomer()}
            />

            {customer.is_passive ? (
              <>
                <Button
                  className="mt-1"
                  type="submit"
                  title={this.context.t(
                    `component.CADialog.changeCutomertoActive`
                  )}
                  icon="check"
                  backgroundColor="primary-opacity"
                  textColor="primary"
                  disabled={
                    this.context.state.user.permission === 2 &&
                    this.context.state.limited_permission.customer_info === 1
                  }
                  fullWidth
                  onClick={() => this.makeActiveSingleCustomer()}
                />

                {/*
                <Button
                  className="mt-1"
                  type="submit"
                  title="Müşteriyi Kalıcı Olarak Sil"
                  icon="block"
                  backgroundColor="red-opacity"
                  textColor="red"
                  disabled={this.context.state.user.permission !== 1}
                  fullWidth
                  onClick={() => this.setState({ delete_confirm: true })}
               />
               */}
              </>
            ) : (
              <>
                <Button
                  className="mt-1"
                  type="submit"
                  title={this.context.t(
                    `component.CADialog.changeCutomertoPassive`
                  )}
                  icon="block"
                  backgroundColor="red-opacity"
                  textColor="red"
                  disabled={
                    this.context.state.user.permission === 2 &&
                    this.context.state.limited_permission.customer_info === 1
                  }
                  fullWidth
                  onClick={() => this.setState({ passive_confirm: true })}
                />
              </>
            )}

            <ModalDialog
              open={this.state.delete_confirm}
              closeHandler={() => this.setState({ delete_confirm: false })}
              title={this.context.t(`component.CADialog.delete.title`)}
              buttons={[
                {
                  title: this.context.t(
                    `component.CADialog.delete.confirmButtonTitle`
                  ),
                  icon: "check",
                  backgroundColor: "primary-opacity",
                  textColor: "primary",
                  onClick: () => this.deleteSingleCustomer(),
                },
                {
                  title: this.context.t(
                    `component.CADialog.delete.discardButtonTitle`
                  ),
                  icon: "close",
                  textColor: "grey",
                },
              ]}
            >
              <Alert severity="warning">
                <b>{this.context.t(`component.CADialog.delete.alertText`)}</b>
                <br />
                {this.context.t(`component.CADialog.delete.alertTitleText`)}
              </Alert>
            </ModalDialog>

            <ModalDialog
              open={this.state.passive_confirm}
              closeHandler={() => this.setState({ passive_confirm: false })}
              title={this.context.t(
                `component.CADialog.confirmChangeCustomerTitle`
              )}
              buttons={[
                {
                  title: this.context.t(
                    `component.CADialog.delete.confirmButtonTitle`
                  ),
                  icon: "check",
                  backgroundColor: "primary-opacity",
                  textColor: "primary",
                  onClick: () => this.makePassiveSingleCustomer(),
                },
                {
                  title: this.context.t(
                    `component.CADialog.delete.discardButtonTitle`
                  ),
                  icon: "close",
                  textColor: "grey",
                },
              ]}
            >
              <Alert severity="warning">
                <b>
                  {this.context.t(`component.CADialog.confirmCustomerTitle`)}
                </b>
                {this.context.t(`component.CADialog.confirmTitle1`)}{" "}
                <b>{this.context.t(`component.CADialog.confirmTitle2`)}</b>{" "}
                {this.context.t(`component.CADialog.confirmTitle3`)}
              </Alert>
            </ModalDialog>
          </Column>
        </Grid>

        <AlertDialog
          title={"SMS Gönder"}
          open={this.state.sms_send_dialog}
          fullWidth
          maxWidth="sm"
          closeHandler={() => this.setState({ sms_send_dialog: false })}
          buttons={[
            {
              title: "Gönder",
              icon: "send",
              backgroundColor: "green",
              style: {
                height: "40px",
              },
              textColor: "white",
              onClick: () => this.getSMSReview(),
            },
            {
              title: "İptal",
              icon: "close",
              backgroundColor: "white",
              style: {
                border: "1px solid #666666",
                height: "40px",
              },
              textColor: "black",
              onClick: () => this.setState({ sms_send_dialog: false }),
            },
          ]}
        >
          <Grid>
            <Column className="sx-12 sm-12 md-12 lg-4">
              <Select
                label={this.context.t(`sms.send.template`)}
                items={[...this.state.sms_templates]}
                selected={this.state.selected_template?.name ?? ""}
                labelKey="name"
                valueKey="name"
                returnFullObject
                handler={(template) =>
                  this.setState({
                    selected_template: template,
                    sms_settings: {
                      ...this.state.sms_settings,
                      message: template.content,
                    },
                  })
                }
              />
            </Column>
            <Column className="sx-12 sm-12 md-12 lg-5"></Column>
            <Column
              className="sx-12 sm-12 md-12 lg-3 mb-2"
              style={{ display: "flex", alignItems: "end" }}
            >
              <Button
                className="mb-1 ml-1"
                title={this.context.t(`sms.send.autoText.shortCode`)}
                size="xs"
                textColor="purple"
                outlined
                disabled={this.state.shortCode === ""}
                onClick={() => {
                  this.setState({
                    sms_settings: {
                      message: `${this.state.sms_settings.message} SMS iptali için ${this.state.shortCode} yazın 4607'a gönderin.`,
                    },
                  });
                }}
              />
            </Column>
            <Column className="sx-12 sm-12 md-12 lg-12">
              <Input
                label={this.context.t(`sms.send.messageInputLabel`)}
                multiline={true}
                rows={10}
                value={this.state.sms_settings.message}
                onChange={(e) =>
                  this.setState({
                    sms_settings: {
                      ...this.state.sms_settings,
                      message: e.target.value,
                    },
                  })
                }
                helperText={CalculateMessageCharCount(
                  this.state.sms_settings.message,
                  "1"
                )}
              />
            </Column>
          </Grid>
        </AlertDialog>

        <AlertDialog
          title={
            <Grid>
              <Column className="xs-12 sm-12 md-12 lg-8">
                Whatsapp ile iletişime geç
              </Column>
              <Column className="xs-12 sm-12 md-12 lg-2"></Column>
              <Column className="xs-12 sm-12 md-12 lg-2">
                <WhatsAppIcon
                  style={{
                    color: "green",
                    fontSize: "2.2em",
                  }}
                />
              </Column>
            </Grid>
          }
          open={this.state.whatsapp_con_dialog}
          fullWidth
          maxWidth="sm"
          closeHandler={() => this.setState({ whatsapp_con_dialog: false })}
          buttons={[
            {
              title: "Gönder",
              icon: "send",
              backgroundColor: "green",
              style: {
                height: "40px",
              },
              textColor: "white",
              onClick: () =>
                window.open(
                  `https://api.whatsapp.com/send?phone=${this.state.whatsapp_settings.phone_number}&text=${this.state.whatsapp_settings.message}`,
                  "_blank"
                ),
            },
            {
              title: "İptal",
              icon: "close",
              backgroundColor: "white",
              style: {
                border: "1px solid #666666",
                height: "40px",
              },
              textColor: "black",
              onClick: () => this.setState({ whatsapp_con_dialog: false }),
            },
          ]}
        >
          <Grid>
            <Column className="sx-12 sm-12 md-12 lg-4">
              <Select
                label={this.context.t(`sms.send.template`)}
                items={[...this.state.sms_templates]}
                selected={this.state.selected_template?.name ?? ""}
                labelKey="name"
                valueKey="name"
                returnFullObject
                handler={(template) =>
                  this.setState({
                    selected_template: template,
                    whatsapp_settings: {
                      ...this.state.whatsapp_settings,
                      message: template.content,
                    },
                  })
                }
              />
            </Column>
            <Column className="sx-12 sm-12 md-12 lg-5"></Column>
            <Column className="sx-12 sm-12 md-12 lg-12">
              <Input
                label={this.context.t(`sms.send.messageInputLabel`)}
                multiline={true}
                rows={10}
                value={this.state.whatsapp_settings.message}
                onChange={(e) =>
                  this.setState({
                    whatsapp_settings: {
                      ...this.state.whatsapp_settings,
                      message: e.target.value,
                    },
                  })
                }
              />
            </Column>
          </Grid>
        </AlertDialog>

        <SMSReview
          open={this.state.open_sms_review_dialog}
          closeHandler={() => {
            this.setState({
              open_sms_review_dialog: false,
            });
          }}
          sendSMS={this.sendSelectiveSms}
          sms_content={this.state.sms_settings.message}
          total_number={this.state.total_number}
          total_sms_credit={this.state.total_sms_credit}
        />
      </>
    );
  };

  _CustomerReceiptsBuilder = () => {
    return (
      <React.Fragment>
        <Grid>
          <Grid className="mb-2">
            <Column
              className={`xs-12 sm-12 md-12 ${
                this.state.filter_expanded_history ? "lg-3" : "lg-10"
              } mt-2`}
            >
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    checked={this.state.filter_expanded_history}
                    onChange={async (e) => {
                      await this.setState({
                        filter_expanded_history: e.target.checked,
                      });
                      await this.getHistorySingleCustomer();
                    }}
                  />
                }
                label={
                  <FCLabel>
                    <i className="material-icons">filter_list</i>{" "}
                    <span>
                      {this.context.t(`['appointments/filter'].filterTitle`)}
                    </span>
                  </FCLabel>
                }
              />
            </Column>
            {this.state.filter_expanded_history && (
              <>
                <Column className="xs-12 sm-12 md-12 lg-3">
                  <DatePickerInput
                    className="mb-0"
                    label={this.context.t(`receipts.filter.startInputLabel`)}
                    value={
                      new Date(
                        moment(this.state.last_transactions.start).toISOString()
                      )
                    }
                    maxDate={this.state.last_transactions.end}
                    onChange={async (start) => {
                      await this.setState({
                        loaded: false,
                        last_transactions: {
                          ...this.state.last_transactions,
                          start: moment(start).toISOString(),
                          selected_filter_picker: null,
                        },
                      });
                      await this.getHistorySingleCustomer();
                    }}
                  />
                </Column>
                <Column className="xs-12 sm-12 md-12 lg-2">
                  <DatePickerInput
                    className="mb-0"
                    label={this.context.t(`receipts.filter.endInputLabel`)}
                    value={this.state.last_transactions.end}
                    minDate={
                      new Date(
                        moment(this.state.last_transactions.start).toISOString()
                      )
                    }
                    onChange={async (end) => {
                      await this.setState({
                        loaded: false,
                        last_transactions: {
                          ...this.state.last_transactions,
                          end: moment(end).toISOString(),
                          selected_filter_picker: null,
                        },
                      });
                      await this.getHistorySingleCustomer();
                    }}
                  />
                </Column>
                <Column className="xs-12 sm-12 md-12 lg-2">
                  <Select
                    label={this.context.t(`appointments.dayWeekSelectLabel`)}
                    items={this.state.last_transactions.filterPicker}
                    labelKey="label"
                    valueKey="id"
                    selected={
                      this.state.last_transactions.selected_filter_picker
                        ? `${this.state.last_transactions.selected_filter_picker.id}`
                        : null
                    }
                    returnFullObject
                    handler={async (filterPicker) => {
                      await this.setState({
                        loaded: false,
                        last_transactions: {
                          ...this.state.last_transactions,
                          selected_filter_picker: filterPicker,
                        },
                      });
                      this.getHistorySingleCustomer();
                    }}
                  />
                </Column>
              </>
            )}
            <Column className="xs-12 sm-12 md-12 lg-2">
              <Button
                className="mt-1"
                type="submit"
                title={this.context.t(`scaffold.QAItems.createReceipt`)}
                icon="content_paste"
                backgroundColor="primary-opacity"
                textColor="primary"
                fullWidth
                onClick={() => this.addReceiptBySelectedCustomer()}
              />
            </Column>
          </Grid>
          <Column className="xs-12 sm-12">
            <Table
              refreshAction={() => this.getHistorySingleCustomer()}
              loaded={this.state.loaded}
              headings={{
                id: {
                  label: this.context.t(`receipts.headings.id`),
                  style: { width: 100 },
                },
                is_package: {
                  label: this.context.t(`receipts.headings.billType`),
                  style: { width: 150 },
                },
                created_at: {
                  label: this.context.t(`receipts.headings.createdAt`),
                  style: { width: 200 },
                },
                info: {
                  label: this.context.t(`receipts.headings.info`),
                  style: { width: 400 },
                  limited_line: 2,
                },
                all_amount: {
                  label: this.context.t(`receipts.headings.allAmount`),
                  suffix: this.context.state.currency
                    ? this.context.state.currency
                    : "₺",
                  style: { width: 100 },
                },
                paid: {
                  label: this.context.t(`receipts.headings.paid`),
                  suffix: this.context.state.currency
                    ? this.context.state.currency
                    : "₺",
                  style: { width: 100 },
                },
                debt_total: {
                  label: this.context.t(`receipts.headings.debtTotal`),
                  suffix: this.context.state.currency
                    ? this.context.state.currency
                    : "₺",
                  style: { width: 100 },
                },
                _: { label: this.context.t(`component.actionHeadingText`) },
              }}
              replacements={this.state.last_transactions.replacements}
              rows={this.state.last_transactions.data}
              pagination={this.state.last_transactions.pagination}
              buttons={[
                {
                  icon: "launch",
                  title: "Görüntüle",
                  pushEffect: true,
                  onClick: (row) =>
                    this.props.history.push(`/receipts/detail/${row.id}`),
                },
              ]}
            />
          </Column>
        </Grid>
      </React.Fragment>
    );
  };

  /**
   * Otomayik Randevu oluşturmak için açılan dialog.
   */

  _AutoAppointmentDialogBuilder = () => {
    return (
      <NewAppointmentDialog
        open={this.state.checkout_data_dialog}
        onNewAppointment={async () => {
          this.setState({ checkout_data_dialog: false });
          await this.getFilterByAppointmentsStatistics();
        }}
        onClose={() => this.setState({ checkout_data_dialog: false })}
      />
    );
  };

  /**
   * Müşteri sözleşmelerinin olduğu alan
   *
   */

  _CustomersContract = () => {
    return (
      <Grid>
        <Column className="xs-12 sm-12">
          <Table
            refreshAction={() =>
              this.getAgreement(this.state.paginationAgreement.page)
            }
            headings={{
              date: {
                label: "Tarih",
              },
              agreement_name: {
                label: "Sözleşme Adı",
              },
              bytes: {
                label: "Boyutu",
                suffix: "mb",
              },
              _: {
                label: "İşlemler",
              },
            }}
            rows={this.state.agreements}
            loaded={this.state.agreement_load}
            pagination={this.state.paginationAgreement}
            buttons={[
              {
                title: "",
                icon: "find_in_page",
                textColor: "blue",
                onClick: (row) => window.open(row.url, "_blank"),
              },
              {
                title: "",
                icon: <WhatsApp fontSize="small" />,
                textColor: "green",
                onClick: (row) => {
                  if (!this.context.state.whatsapp_permission) {
                    toast.warning(
                      "Ücretli içerik. Lütfen müşteri temsilciniz ile iletişime geçiniz."
                    );
                  } else {
                    this.setState({
                      wp_aproval_modal: true,
                      send_wp_id: row.id,
                      send_wp_customer_name: row.name,
                      send_wp_agreement_name: row.agreement_name,
                    });
                  }
                },
              },
              {
                title: "",
                icon: "sms",
                textColor: "orange",
                onClick: (row) => {
                  this.setState({
                    sms_aproval_modal: true,
                    send_sms_id: row.id,
                    send_sms_customer_name: this.state.customer.full_name,
                    send_sms_agreement_name: row.agreement_name,
                  });
                },
              },
              {
                title: "",
                icon: "delete_forever",
                textColor: "red",
                onClick: (row) => {
                  this.setState({
                    agreement_delete_id: row.id,
                    delete_agreement_dialog: true,
                  });
                },
              },
            ]}
          />
        </Column>

        {/* **
        sms onayı için açılan dialog
        */}
        <AlertDialog
          title="Kayıtlı sözleşmeyi SMS ile iletmek ister misiniz?"
          open={this.state.sms_aproval_modal}
          closeHandler={() => this.setState({ sms_aproval_modal: false })}
          buttons={[
            {
              title: "Gönder",
              icon: "send",
              backgroundColor: "primary-opacity",
              textColor: "primary",
              onClick: () => {
                this.sendSMSAgreement(this.state.send_sms_id);
              },
            },
            {
              title: "İptal",
              icon: "clear",
              textColor: "grey",
              onClick: () => {
                this.setState({
                  send_sms_id: null,
                  send_sms_customer_name: null,
                  send_sms_agreement_name: null,
                });
              },
            },
          ]}
        >
          {this.state.send_sms_customer_name} isimli müşteriye SMS ile '
          {this.state.send_sms_agreement_name}' göndermek istediğinize emin
          misiniz?
        </AlertDialog>

        {/* **
        wp onayı için açılan dialog
        */}
        <AlertDialog
          title="Kayıtlı sözleşmeyi Whatsapp ile iletmek ister misiniz?"
          // title="Kayıtlı sözleşmeyi Whatsapp ile iletmek ister misiniz?"
          open={this.state.wp_aproval_modal}
          closeHandler={() => this.setState({ wp_aproval_modal: false })}
          buttons={[
            {
              title: "Gönder",
              icon: "send",
              backgroundColor: "primary-opacity",
              textColor: "primary",
              onClick: () => {
                this.sendWPAgreement(this.state.send_wp_id);
              },
            },
            {
              title: "İptal",
              icon: "clear",
              textColor: "grey",
              onClick: () => {
                this.setState({
                  send_wp_id: null,
                  send_wp_customer_name: null,
                  send_wp_agreement_name: null,
                });
              },
            },
          ]}
        >
          {this.state.send_wp_customer_name} isimli müşteriye '
          {this.state.send_wp_agreement_name}' Whatsapp ile göndermek
          istediğinize emin misiniz?
        </AlertDialog>

        <AlertDialog
          title="Sözleşmeyi Silmeyi Onaylıyor Musunuz?"
          open={this.state.delete_agreement_dialog}
          closeHandler={() => this.setState({ delete_agreement_dialog: false })}
          buttons={[
            {
              title: "Onayla ve Sil",
              icon: "check",
              backgroundColor: "primary-opacity",
              textColor: "primary",
              onClick: () => {
                this.deleteAgreement(this.state.agreement_delete_id);
              },
            },
            {
              title: "Vazgeç",
              icon: "close",
              textColor: "grey",
            },
          ]}
        >
          <Alert severity="warning">
            <b>Sözleşme kalıcı olarak silinecektir.</b>
            <br />
            Bu işlemin geri dönüşü olmayacaktır. Sözleşme kalıcı olarak
            silinecektir.
          </Alert>
        </AlertDialog>
      </Grid>
    );
  };

  _CustomerAppointmentsBuilder = () => {
    return (
      <React.Fragment>
        <Grid>
          <Grid className="mb-2">
            <Column
              className={`xs-12 sm-12 md-12 ${
                this.state.filter_expanded_statistic ? "lg-3" : "lg-10"
              } mt-2`}
            >
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    checked={this.state.filter_expanded_statistic}
                    onChange={async (e) => {
                      await this.setState({
                        filter_expanded_statistic: e.target.checked,
                      });
                      await this.getFilterByAppointmentsStatistics();
                    }}
                  />
                }
                label={
                  <FCLabel>
                    <i className="material-icons">filter_list</i>{" "}
                    <span>
                      {this.context.t(`['appointments/filter'].filterTitle`)}
                    </span>
                  </FCLabel>
                }
              />
            </Column>
            {this.state.filter_expanded_statistic && (
              <>
                <Column className="xs-12 sm-12 md-12 lg-3">
                  <DatePickerInput
                    className="mb-1"
                    label={this.context.t(
                      `['appointments/filter'].startInputLabel`
                    )}
                    value={
                      new Date(
                        moment(
                          this.state.customerAppointmentsFilter_startDate
                        ).toISOString()
                      )
                    }
                    onChange={async (start_date) => {
                      this.setState({
                        loaded: false,
                        customerAppointmentsFilter_startDate: ConvertTimeToZulu(
                          moment(start_date).unix()
                        ),
                        selected_filter_picker_statistic: null,
                      });
                      this.getFilterByAppointmentsStatistics();
                    }}
                  />
                </Column>
                <Column className="xs-12 sm-12 md-12 lg-2">
                  <DatePickerInput
                    className="mb-1"
                    label={this.context.t(
                      `['appointments/filter'].endInputLabel`
                    )}
                    value={
                      new Date(
                        moment(
                          this.state.customerAppointmentsFilter_endDate
                        ).toISOString()
                      )
                    }
                    onChange={async (end_date) => {
                      await this.setState({
                        loaded: false,
                        customerAppointmentsFilter_endDate: ConvertTimeToZulu(
                          moment(end_date).unix()
                        ),
                        selected_filter_picker_statistic: null,
                      });
                      await this.getFilterByAppointmentsStatistics();
                    }}
                  />
                </Column>
                <Column className="xs-12 sm-12 md-12 lg-2">
                  <Select
                    label={this.context.t(`appointments.dayWeekSelectLabel`)}
                    items={this.state.filterPicker_statistic}
                    labelKey="label"
                    valueKey="id"
                    selected={
                      this.state.selected_filter_picker_statistic
                        ? `${this.state.selected_filter_picker_statistic.id}`
                        : null
                    }
                    returnFullObject
                    handler={async (filterPicker) => {
                      await this.setState({
                        loaded: false,
                        selected_filter_picker_statistic: filterPicker,
                      });
                      this.getFilterByAppointmentsStatistics();
                    }}
                  />
                </Column>
              </>
            )}
            <Column className="xs-12 sm-12 md-12 lg-2">
              <Button
                className="mt-1"
                type="submit"
                title={this.context.t(`scaffold.QAItems.createAppointment`)}
                icon="date_range"
                backgroundColor="primary-opacity"
                textColor="primary"
                onClick={() =>
                  this.setState({
                    checkout_data_dialog: true,
                  })
                }
              />
            </Column>
          </Grid>
        </Grid>
        <Grid
          style={{
            justifyContent: "space-between",
          }}
        >
          <Column
            className="xs-12 sm-12 md-12 lg-4"
            style={{
              fontSize: "14px",
            }}
          >
            <div
              style={{
                color: "#7c7c7c",
                fontSize: "24px",
              }}
            >
              {this.state.customer.name.concat(
                " " + this.state.customer.surname
              )}
            </div>
          </Column>

          {this.state.customerAppointmentsFilterData.length > 0 &&
            this.state.customerAppointmentsFilterData.map((item) => {
              return (
                <Column className="xs-12 sm-12 md-12 lg-4">
                  <StatsCard
                    key={item.title.concat(item.value)}
                    className="mb-2"
                    icon={{ label: item.label, color: item.color }}
                    title={
                      item.title === "İptal Edildi"
                        ? "İptal Edildi"
                        : this.context.t(item.title)
                    }
                    value={item.value}
                  />
                </Column>
              );
            })}
        </Grid>
        {/* To be used when the table structure changes */}

        <Grid>
          <Column className="xs-12 sm-12">
            {this.state.appointmentsListLoader ? (
              <Table
                refreshAction={() =>
                  this.getFilterByAppointmentsStatistics(
                    this.state.appointments.pagination.page
                  )
                }
                loaded={this.state.loaded}
                headings={{
                  created_at: {
                    label: this.context.t(
                      `['appointments/filter'].headings.createdAt`
                    ),
                  },
                  staff: {
                    label: this.context.t(
                      `['appointments/filter'].headings.staffFullName`
                    ),
                  },
                  appointment_start_date: {
                    label: this.context.t(
                      `['appointments/filter'].headings.appointmentStartDate`
                    ),
                  },
                  service: {
                    label:
                      this.context.t(
                        `['appointments/filter'].headings.serviceName`
                      ) +
                      " " +
                      " / Paketler",
                  },
                  note: {
                    label: this.context.t(
                      `['appointments/filter'].headings.appointmentNote`
                    ),
                  },
                  active: {
                    label: this.context.t(`['appointments/filter'].situations`),
                  },
                  _: { label: "İşlemler" },
                }}
                rows={this.state.appointments.data}
                pagination={this.state.appointments.pagination}
                buttons={[
                  {
                    title: "Randevuya git",
                    icon: "find_in_page",
                    textColor: "blue",
                    onClick: (row) => {
                      localStorage.setItem(
                        "selected_date",
                        moment(row.appointmentDate._i)
                      );
                      setTimeout(() => {
                        this.props.history.push({
                          pathname: "/appointments",
                          state: {
                            date: row.appointmentDate._i,
                            appointment_id: row.id,
                          },
                        });
                      }, 250);
                    },
                    disabled: (row) => {
                      if (row.appointment_status === 2) {
                        return true;
                      } else {
                        return false;
                      }
                    },
                  },
                  {
                    title: "İptal et",
                    icon: "cancel",
                    textColor: "red",
                    disabled: (row) => {
                      if (row.appointment_status === 2) {
                        return true;
                      } else {
                        return false;
                      }
                    },
                    onClick: (row) =>
                      this.setState({
                        delete_appointment_modal: true,
                        delete_appointment_id: row.id,
                      }),
                  },
                ]}
              />
            ) : (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <img src={loader} width="80" height="80" alt="loading" />
              </div>
            )}
          </Column>
        </Grid>

        <AlertDialog
          title="Randevuyu İptal Etmeyi Onaylıyor musunuz?"
          open={this.state.delete_appointment_modal}
          closeHandler={() =>
            this.setState({ delete_appointment_modal: false })
          }
          buttons={[
            {
              title: "Evet",
              icon: "check",
              backgroundColor: "primary-opacity",
              textColor: "primary",
              onClick: () =>
                this.cancelAppointment(this.state.delete_appointment_id),
            },
            {
              title: "İptal",
              icon: "close",
              textColor: "grey",
            },
          ]}
        >
          <Alert severity="warning">
            <b>{"Bu işlem geri alınamaz!"}</b>
            <br />
            {"Randevu, randevu takviminden silinecektir. Onaylıyor musunuz?"}
          </Alert>
        </AlertDialog>
      </React.Fragment>
    );
  };

  //TODO : Customer Photos
  _CustomerPhotosBuilder = () => {
    const GalleryPhotos = this._GalleryBuilder;
    const LoaderContainer = this._LoaderBuilder;

    const { loaded_customer } = this.state;
    return (
      <React.Fragment>
        <AuthContainer authorities={[1]} limited_permission="gallery">
          <Grid>
            <Column className="xs-12 sm-12 mb-3">
              <input
                id="imageUploadInput"
                type="file"
                multiple
                accept="image/*"
                style={{ display: "none" }}
                onChange={(e) => this.uploadMultiPhotos(e)}
              />
              <ImageUploadBox htmlFor="imageUploadInput">
                <i className="material-icons">cloud_upload</i>{" "}
                {this.context.t(
                  `['settings/service-gallery'].uploadButtonTitle`
                )}
              </ImageUploadBox>
            </Column>

            <Column className="xs-12 sm-12 mb-2">
              <Button
                icon="delete"
                title={this.context.t(
                  `['settings/service-gallery'].deleteSelectedButtonTitle`,
                  {
                    count: this.state.selected_photos.length,
                  }
                )}
                disabled={!this.state.selected_photos.length > 0}
                textColor="red"
                backgroundColor="red-opacity"
                transitionEffect
                onClick={() => this.setState({ delete_modal: true })}
              />

              {this.context.state.company_license.accounting && (
                <p style={{ display: "inline-block", marginLeft: "10px" }}>
                  {this.context.t(`[settings/service-gallery].numberPhoto`)}:{" "}
                  {this.state.photos ? this.state.photos.length : 0} /{" "}
                  {
                    this.state.accountings[
                      this.context.state.company_license.accounting === 2 &&
                      this.context.state.company_license
                        .appointment_limitation > 0
                        ? 3
                        : this.context.state.company_license.accounting
                    ]
                  }
                </p>
              )}
            </Column>

            {loaded_customer ? <GalleryPhotos /> : <LoaderContainer />}

            <AlertDialog
              title={this.context.t(
                `['settings/service-gallery'].delete.title`
              )}
              maxWidth="sm"
              fullWidth
              open={this.state.delete_modal}
              closeHandler={() => this.setState({ delete_modal: false })}
              buttons={[
                {
                  title: this.context.t(
                    `['settings/service-gallery'].delete.confirmButtonTitle`
                  ),
                  icon: "check",
                  backgroundColor: "primary-opacity",
                  textColor: "primary",
                  onClick: () => this.deleteMultiPhotos(),
                },
                {
                  title: this.context.t(
                    `['settings/service-gallery'].delete.discardButtonTitle`
                  ),
                  icon: "close",
                  textColor: "grey",
                },
              ]}
              textType
            >
              <b>
                {this.context.t(
                  `['settings/service-gallery'].delete.alertBoldText`
                )}
              </b>
              <br />
              {this.context.t(`['settings/service-gallery'].delete.alertText`)}
              <Select
                className="mt-2"
                label={this.context.t(
                  `['settings/service-gallery'].photoDelete`
                )}
                items={this.state.filter_before_insert}
                labelKey="label"
                valueKey="id"
                selected={
                  this.state.selected_filter_before_insert
                    ? `${this.state.selected_filter_before_insert.id}`
                    : null
                }
                returnFullObject
                handler={async (filterBefore) => {
                  await this.setState({
                    loaded: false,
                    selected_filter_before_insert: filterBefore,
                  });
                }}
              />
            </AlertDialog>
          </Grid>
        </AuthContainer>
      </React.Fragment>
    );
  };

  //TODO : Customer Gallery Builder
  _GalleryBuilder = () => {
    return (
      <>
        {this.state.photos &&
          this.state.photos.map((item, index) => (
            <Column key={index} className="xs-12 sm-12 md-6 lg-6 xl-6 mb-4">
              <PhotoContainer
                style={{ cursor: "pointer" }}
                onClick={() => {
                  this.setState({
                    selected_photos: this.state.selected_photos.includes(
                      item.id
                    )
                      ? this.state.selected_photos.filter(
                          (id) => id !== item.id
                        )
                      : [...this.state.selected_photos, item.id],
                  });
                }}
              >
                {item.before_photo_path ? (
                  <Column style={{ width: "100%" }}>
                    <Typography className="mb-1">Before Photo</Typography>
                    <GalleryPhoto
                      onClick={() => {
                        if (!this.state.selected_photos.includes(item.id)) {
                          this.setState({
                            [`customer_before_image_modal_${item.id}`]: true,
                          });
                        }
                      }}
                      src={`${this.context.api_endpoint.replace("api/v1", "")}${
                        this.context.state.company_id
                      }/${item.before_photo_path}`}
                    />
                    <Dialog
                      key={item.id}
                      onClose={() =>
                        this.setState({
                          [`customer_before_image_modal_${item.id}`]: false,
                        })
                      }
                      open={
                        this.state[`customer_before_image_modal_${item.id}`]
                      }
                    >
                      <div
                        style={{
                          width: "500px",
                          height: "500px",
                          overflow: "hidden",
                        }}
                      >
                        <img
                          style={{
                            height: "100%",
                            width: "100%",
                            cursor: "pointer",
                            objectFit: "contain",
                          }}
                          onClick={() =>
                            this.setState({
                              [`customer_before_image_modal_${item.id}`]: false,
                            })
                          }
                          src={`${this.context.api_endpoint.replace(
                            "api/v1",
                            ""
                          )}${this.context.state.company_id}/${
                            item.before_photo_path
                          }`}
                        />
                      </div>
                    </Dialog>
                  </Column>
                ) : (
                  <Grid
                    style={{ justifyContent: "center", position: "relative" }}
                  >
                    <Typography
                      className="mb-1"
                      style={{ position: "absolute", left: 12 }}
                    >
                      Before Photo
                    </Typography>
                    <img src={CloudComputing} alt="not found" />
                  </Grid>
                )}
                {item.after_photo_path ? (
                  <Column style={{ width: "100%" }}>
                    <Typography className="mb-1">After Photo</Typography>
                    <GalleryPhoto
                      onClick={() => {
                        if (!this.state.selected_photos.includes(item.id)) {
                          this.setState({
                            [`customer_after_image_modal_${item.id}`]: true,
                          });
                        }
                      }}
                      src={`${this.context.api_endpoint.replace("api/v1", "")}${
                        this.context.state.company_id
                      }/${item.after_photo_path}`}
                    />
                    <Dialog
                      key={item.id}
                      onClose={() =>
                        this.setState({
                          [`customer_after_image_modal_${item.id}`]: false,
                        })
                      }
                      open={this.state[`customer_after_image_modal_${item.id}`]}
                    >
                      <div
                        style={{
                          width: "500px",
                          height: "500px",
                          overflow: "hidden",
                        }}
                      >
                        <img
                          style={{
                            height: "100%",
                            width: "100%",
                            cursor: "pointer",
                            objectFit: "contain",
                          }}
                          onClick={() =>
                            this.setState({
                              [`customer_after_image_modal_${item.id}`]: false,
                            })
                          }
                          src={`${this.context.api_endpoint.replace(
                            "api/v1",
                            ""
                          )}${this.context.state.company_id}/${
                            item.after_photo_path
                          }`}
                        />
                      </div>
                    </Dialog>
                  </Column>
                ) : (
                  <Grid
                    style={{ justifyContent: "center", position: "relative" }}
                  >
                    <Typography
                      className="mb-1"
                      style={{ position: "absolute", left: 12 }}
                    >
                      After Photo
                    </Typography>
                    <img src={CloudComputing} alt="not found" />
                  </Grid>
                )}
                <PhotoProcesses>
                  <Checkbox
                    color="primary"
                    checked={this.state.selected_photos.includes(item.id)}
                  />
                  <div className="xs-12 sm-12 mr-2">
                    <input
                      id="imageUploadInputNext"
                      type="file"
                      multiple
                      accept="image/*"
                      style={{ display: "none" }}
                      onChange={async (e) => {
                        if (this.state.selected_photos.length > 0) {
                          await this.uploadMultiPhotosNext(
                            e,
                            this.state.selected_photos[0]
                          );
                        } else if (this.state.selected_photos.length === 0) {
                          await this.uploadMultiPhotosNext(e, item.id);
                        }
                      }}
                    />
                    <ImageUploadBoxNext htmlFor="imageUploadInputNext">
                      <i className="material-icons">cloud_upload</i>{" "}
                      {this.context.t(`['settings/gallery'].nextPhoto`)}
                    </ImageUploadBoxNext>
                  </div>
                </PhotoProcesses>
              </PhotoContainer>
            </Column>
          ))}
      </>
    );
  };

  //TODO : Customer Loader Builder
  _LoaderBuilder = () => (
    <LoadingContainer>
      <img src={Loader} width="100" height="100" alt="loading" />
    </LoadingContainer>
  );
}

export default SingleCustomer;

const LoadingContainer = styled.div`
  width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PhotoContainer = styled.div`
  display: flex;
  padding: 20px;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  position: relative;
  height: 240px;
  box-shadow: 2px 4px 16px rgba(0, 0, 0, 0.04);
`;

const PhotoProcesses = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 8px;
  background: rgba(255, 255, 255, 1);
  z-index: 200;

  span.MuiCheckbox-root {
    padding: 0 !important;
  }
`;

const GalleryPhoto = styled.img`
  width: 100%;
  height: 70%;
  margin-right: 8px;
  border: 1px solid #d3d3d3;
  object-fit: cover;
`;

const ImageUploadBox = styled.label`
  display: flex;
  width: -webkit-fill-available;
  padding: 32px;
  text-align: center;
  align-items: center;
  justify-content: center;
  background: #fefefe;
  border: 2px dotted #eaeaea;
  border-radius: 5px;
  cursor: pointer;
  color: #808080;
  font-size: 15px;
  font-weight: 500;
  transition: all ease 0.35s;

  &:hover {
    border: 2px dotted #dadada;
    color: #000;
  }

  i.material-icons {
    font-size: 21px;
    margin-right: 8px;
  }
`;

const ImageUploadBoxNext = styled.label`
  display: flex;
  width: 100%;
  padding: 5px;
  text-align: center;
  align-items: center;
  justify-content: center;
  background: #bbdefb;
  border-radius: 5px;
  cursor: pointer;
  color: #0d47a1;
  font-size: 15px;
  font-weight: 500;
  transition: all ease 0.35s;

  i.material-icons {
    font-size: 21px;
    margin-right: 8px;
  }
`;

const CostumSelect = styled.select`
  width: 100%;
  padding-left: 8px;
  padding-right: 8px;
  border: 1px solid #b2b2b2;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.03);
  border-radius: 5px;
  height: 46px;
  margin-bottom: 0px;
  margin-top: -2px;
`;
