import React, { useState, PureComponent } from "react";
import AppContext from "../../../../context/store";
import AuthContainer from "../../../../components/AuthContainer";
import Axios from "axios";
import CalculateMessageCount from "../../../../functions/CalculateMessageCount";
import {
  Block,
  FeaturedPlayList,
  FilterList,
  WhatsApp,
} from "@material-ui/icons";

import { Grid, Column } from "../../../../theme/Grid";
import Alert from "@material-ui/lab/Alert";
import styled from "styled-components";
import {
  Switch,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio,
  FormGroup,
  Slider,
  Tooltip,
  Chip,
} from "@material-ui/core";
import FCLabel from "../../../../theme/FCLabel";
import getFilterModel from "./_/FilterModel";
import Table from "../../../../theme/Table";
import Button from "../../../../theme/Button";
import SectionTitle from "../../../../theme/SectionTitle";
import CalculateMessageCharCount from "../../../../functions/CalculateMessageCharCount";
import Input from "../../../../theme/CustomMUI/Input";
import AlertDialog from "../../../../theme/CustomMUI/AlertDialog";
// import GenerateMessagePreview from '../../../../functions/GenerateMessagePreview';
import { toast } from "react-toastify";
import UndefinedSenderAlert from "../../../../components/Alerts/UndefinedSenderAlert";
import WpUndefinedAlert from "../../../../components/Alerts/WpUndefinedAlert";
import SenderFetchAlert from "../../../../components/Alerts/SenderFetchAlert";
import Select from "../../../../theme/CustomMUI/Select";
import StatsCard from "../../../../components/Cards/StatsCard";
import SMSReview from "../../../../components/Dialogs/SMSReview";
import { phoneNumberDisplay } from "../../../../functions/PhoneNumberDisplay";
import BetweenTimeGenerator from "../../../../functions/BetweenTimeGenerator";
import moment from "moment";
import DatePickerInput from "../../../../theme/CustomMUI/DatePickerInput";
import TimeSelect from "../../../../theme/TimeSelect";

class SendFilter extends PureComponent {
  static contextType = AppContext;

  state = {
    step: 0,
    services: [],
    staffs: [],
    categories: [],

    customers_table: {
      loaded: false,
      data: [],
      pagination: {
        page: 1,
        total_page: 1,
        onChange: (page) => this.getFilteredCustomers(page),
      },
    },

    check_dialog: false,

    filter: { ...getFilterModel() },
    message: "",
    except: [],
    send_date: new Date(), //Gönderilecek tarih
    selected_time: null, //Gönderilecek saat
    hours: [...BetweenTimeGenerator(this.context.state.company_shift)],
    future_date: false,

    templates: [],
    selected_template: null,

    open_sms_review_dialog: false,
    total_number: null,
    total_sms_credit: null,
    shortCode: null,

    sender: "",

    // Checknox for sms and wp
    checkedSMS: true,
    checkedWP: null,

    // whatsapp or sms
    api_key: "",

    // WPState and WP phone
    wp_state: null,
    wp_phone: null,
  };

  // checking dor wp accounts
  checkWp = async () => {
    await Axios.get(
      `${this.context.api_endpoint}/company/get/wp/message/settings`
    )
      .then(async (ress) => {
        if (ress.data.data.device_key !== "") {
          await Axios.post(
            `${this.context.api_endpoint}/salon/wp/login/check/active/${ress.data.data.device_key}`
          )
            .then((ress) => {
              if (ress.status === 200) {
                this.setState({
                  wp_state: ress.data.is_connect,
                  wp_phone: ress.data.data.device_number,
                  checkedWP: true,
                });
              }
            })
            .catch(() => {
              this.setState({
                wp_state: false,
                wp_phone: null,
                checkedWP: false,
              });
            });
        } else {
          this.setState({
            checkedWP: false,
          });
        }
      })
      .catch((err) => console.log(err));
  };

  loadData = () => {
    Axios.get(`${this.context.api_endpoint}/company/appointments/index2`).then(
      ({ data }) => {
        const x = {
          // services: [...data.data.services],
          staffs: [...data.data],
        };
        this.setState({ ...x });
      }
    );
  };

  filterFormatter = () => {
    const { filter, services, staffs } = this.state;
    const _filter = { ...getFilterModel() };

    let formatted = {};
    Object.keys(_filter).map((key) => {
      return Object.assign(formatted, {
        [key]:
          JSON.stringify(_filter[key]) === JSON.stringify(filter[key])
            ? getFilterModel({ type: "FULFILLED", services, staffs })[key]
            : filter[key],
      });
    });

    return formatted;
  };

  getAllCategories = async () => {
    await Axios.get(
      `${this.context.api_endpoint}/company/services/with/category/all`
    ).then(({ data }) => {
      console.log("cate", data);
      this.setState({
        categories: [...data.data.filter((m) => m.services.length !== 0)],
      });
    });
  };

  getFilteredCustomers = (page = 1) => {
    const { customers_table } = this.state;
    this.setState({ customers_table: { ...customers_table, loaded: false } });
    Axios.post(`${this.context.api_endpoint}/company/filter/sms?page=${page}`, {
      ...this.filterFormatter(),
    })
      .then(({ data }) =>
        this.setState({
          customers_table: {
            ...customers_table,
            loaded: true,
            pagination: {
              ...customers_table.pagination,
              page: data.data.page,
              total_page: data.data.records.length,
            },
            data: [
              ...data.data.records.map((item) => ({
                ...item,
                full_name: `${item.name} ${item.surname}`,
                phone:
                  this.context.state.user.permission === 2 &&
                  this.context.state.limited_permission.customer_info === 0
                    ? phoneNumberDisplay(item.phone)
                    : item.phone,
              })),
            ],
          },
        })
      )
      .catch((e) => {
        toast.error(e.response.data.message);
      });
  };

  getBalance = async () => {
    Axios.get(`${this.context.api_endpoint}/company/balance`)
      .then((data) => {
        // setWpBalance(data.data.data.wp_credit);
        this.context.dispatch({
          type: "SET_BALANCE",
          payload: { balance: data.data },
        });
      })
      .catch((err) => console.log(err));
  };

  sendFilteredSms = async () => {
    const { message, except, send_date, selected_time, future_date } =
      this.state;
    await this.setState({ sms_request_status: true });

    let appointment_date = null;
    if (future_date === true) {
      let appointment_time = selected_time.split(":");
      appointment_date = `${moment(send_date).format("YYYY-MM-DD")}T${
        appointment_time[0]
      }:${appointment_time[1]}:00Z`;
    }

    let sms_prefer = (() => {
      if (this.state.checkedSMS && this.state.checkedWP) {
        return 3;
      }
      if (this.state.checkedSMS) {
        return 1;
      } else {
        if (this.state.checkedWP) {
          return 2;
        } else {
          return 0;
        }
      }
    })();
    if (sms_prefer === 0) {
      return toast.error("Gönderim yöntemlerinden birisini seçin");
    }

    await Axios.post(`${this.context.api_endpoint}/company/filter/sms/send`, {
      // Tüm filtreler
      ...this.filterFormatter(),

      //Hariç tutulacak müşteriler listesi
      except: except.map((item) => item.id),

      //Gönderilecek mesaj içeriği
      message,

      send_date: future_date === true ? appointment_date : null,

      // hangi yöntem ile göndereceğini
      sms_prefer,
    })
      .then((response) => {
        if (response.status === 200) {
          toast.success("SMS gönderimine başarıyla başlandı!");
          this.props.history.push("/sms");
          this.getBalance();
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });

    await this.setState({ sms_request_status: false });
  };

  getTemplates = () => {
    Axios.get(`${this.context.api_endpoint}/company/sms/template/all`).then(
      ({ data }) => {
        this.setState({
          templates: [...data.data],
        });
      }
    );
  };

  getMessageSettings = () => {
    Axios.get(`${this.context.api_endpoint}/company/message/credit`).then(
      ({ data }) => {
        this.setState({
          available_credit: `${data.data.credit} SMS`,
          sender: data.data.settings.sender,
        });
        if (
          data.data.settings.sender === "" ||
          data.data.settings.sender === null
        ) {
          this.setState({
            checkedSMS: false,
          });
        }
      }
    );
  };

  getSMSReview = () => {
    let sms_prefer = (() => {
      if (this.state.checkedSMS && this.state.checkedWP) {
        return 3;
      }
      if (this.state.checkedSMS) {
        return 1;
      } else {
        if (this.state.checkedWP) {
          return 2;
        } else {
          return 0;
        }
      }
    })();
    if (sms_prefer === 0) {
      return toast.error("Gönderim yöntemlerinden birisini seçin");
    }
    Axios.post(`${this.context.api_endpoint}/company/filter/sms/send/preview`, {
      ...this.filterFormatter(),
      except: this.state.except.map((item) => item.id),
      message: this.state.message,
      sms_prefer,
    })
      .then(async (response) => {
        if (response.status === 200) {
          await this.setState({
            total_number: response.data.data.total_numbers,
            total_sms_credit: response.data.data.total_sms_credit,
            open_sms_review_dialog: true,
          });
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  getShortcode = () => {
    Axios.get(`${this.context.api_endpoint}/company/shortcode/list/keyword`)
      .then(async (response) => {
        if (response.status === 200) {
          await this.setState({
            shortCode: response.data.data.keyword,
          });
        }
      })
      .catch((e) => {});
  };

  componentDidMount = async () => {
    await this.loadData();
    await this.getFilteredCustomers();
    await this.getTemplates();
    await this.getMessageSettings();
    await this.getShortcode();
    await this.getAllCategories();
    await this.checkWp();
  };

  render() {
    const FilterArea = this._FilterAreaBuilder;
    const Customers = this._CustomersBuilder;
    const ExceptedCustomers = this._ExceptedCustomersBuilder;
    const SmsSettings = this._SmsSettingsBuilder;

    return (
      <>
        <AuthContainer authorities={[1]} limited_permission="sms">
          <Grid className="mb-2">
            <Column className="xs-12 sm-12 md-auto">
              <StatsCardContainer>
                <Grid
                  style={{ height: "100%", minHeight: 73 }}
                  className="no-gutters-all"
                >
                  <Column className="xs-12">
                    <IconAlignContainer className="fc-orange bg-orange-opacity">
                      <FeaturedPlayList
                        style={{
                          fontSize: "2em",
                        }}
                      />
                    </IconAlignContainer>
                  </Column>
                  <Column className="xs-12 col-auto">
                    <TextAlignContainer>
                      <StatsHeader>SMS Mesajı</StatsHeader>
                      <StatsValue>{this.state.sender}</StatsValue>
                    </TextAlignContainer>
                  </Column>
                </Grid>
              </StatsCardContainer>
            </Column>
            <Column className="xs-12 sm-12 md-auto">
              <StatsCardContainer>
                <Grid
                  style={{ height: "100%", minHeight: 73 }}
                  className="no-gutters-all"
                >
                  <Column className="xs-12">
                    <IconAlignContainer className="fc-green bg-green-opacity">
                      <WhatsApp
                        style={{
                          fontSize: "3em",
                        }}
                      />
                    </IconAlignContainer>
                  </Column>
                  <Column className="xs-12 col-auto">
                    <TextAlignContainer>
                      <StatsHeader>WhatsApp Mesajı</StatsHeader>
                      <StatsValue>{this.state.wp_phone}</StatsValue>
                    </TextAlignContainer>
                  </Column>
                </Grid>
              </StatsCardContainer>
            </Column>
            <Column className="xs-12 sm-12 md-auto">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        this.state.sender === "" ? false : this.state.checkedSMS
                      }
                      onChange={(e) => {
                        if (this.state.sender !== "") {
                          this.setState({
                            checkedSMS: e.target.checked,
                          });
                        }
                      }}
                      color="primary"
                    />
                  }
                  label={this.context.t(`sms.sendType.sendSMS`)}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.checkedWP}
                      onChange={(e) => {
                        if (!this.state.wp_state) {
                          this.props.history.push("/settings/wp");
                        }
                        this.setState({
                          checkedWP: e.target.checked,
                        });
                      }}
                      color="primary"
                    />
                  }
                  label={this.context.t(`sms.sendType.sendWP`)}
                />
              </FormGroup>
            </Column>
            <Column className="xs-12 sm-12 md-2">
              <StatsCardContainer>
                <Grid
                  style={{ height: "100%", minHeight: 73 }}
                  className="no-gutters-all"
                >
                  <Column className="xs-12">
                    <IconAlignContainer className="fc-orange bg-orange-opacity">
                      <FeaturedPlayList
                        style={{
                          fontSize: "2em",
                        }}
                      />
                    </IconAlignContainer>
                  </Column>
                  <Column className="xs-12 col-auto">
                    <TextAlignContainer>
                      <StatsHeader>
                        {this.context.t(`sms.credit.sms`)}
                      </StatsHeader>
                      <StatsValue>
                        {" "}
                        {this.context.state.balance?.data?.credit}
                      </StatsValue>
                    </TextAlignContainer>
                  </Column>
                </Grid>
              </StatsCardContainer>
            </Column>
            <Column className="xs-12 sm-12 md-2">
              <StatsCardContainer>
                <Grid
                  style={{ height: "100%", minHeight: 73 }}
                  className="no-gutters-all"
                >
                  <Column className="xs-12">
                    <IconAlignContainer className="fc-green bg-green-opacity">
                      <WhatsApp
                        style={{
                          fontSize: "3em",
                        }}
                      />
                    </IconAlignContainer>
                  </Column>
                  <Column className="xs-12 col-auto">
                    <TextAlignContainer>
                      <StatsHeader>
                        {this.context.t(`sms.credit.wp`)}
                      </StatsHeader>
                      <StatsValue>
                        {this.context.state.balance?.data?.wp_credit}
                      </StatsValue>
                    </TextAlignContainer>
                  </Column>
                </Grid>
              </StatsCardContainer>
            </Column>
          </Grid>
          <Grid>
            {(this.state.sender === "" || this.state.sender === null) &&
            !this.state.wp_state ? (
              <>
                <UndefinedSenderAlert />
                <WpUndefinedAlert />
              </>
            ) : (
              <></>
            )}
            <SenderFetchAlert />

            <FilterArea />
            <Customers />
            <SmsSettings />
            <ExceptedCustomers />
          </Grid>
          <SMSReview
            open={this.state.open_sms_review_dialog}
            closeHandler={() => {
              this.setState({
                open_sms_review_dialog: false,
              });
            }}
            sendSMS={this.sendFilteredSms}
            sms_content={this.state.message}
            total_number={this.state.total_number}
            total_sms_credit={this.context.state.balance?.data?.credit}
            in_use_sms_credit={this.state.total_sms_credit}
            total_wp_credit={this.context.state.balance?.data?.wp_credit}
            in_use_wp_credit={this.state.total_number}
          />
        </AuthContainer>
      </>
    );
  }

  /**
   * Filtreleme alanının yapıldığı sol menünün olduğu alan.
   */
  _FilterAreaBuilder = () => {
    const { step, filter, services, staffs } = this.state;
    const { FilterTab, FilterCheckboxGroup, FilterRadioGroup } = this;

    return (
      <Column
        className="xs-12 sm-12 md-12 lg-4 xl-3"
        style={{ display: step === 0 ? "block" : "none" }}
      >
        {(this.context.current_flag &
          this.context.state.FLAGS.SALON_RANDEVU && (
          <FilterTab
            title={this.context.t(`['sms/filter'].tabs.debt`)}
            typeKey="debt"
            openDefault={false}
          >
            <FilterCheckboxGroup
              name="debt"
              checkboxes={[
                {
                  label: this.context.t(`['sms/filter'].tabs.debtFalse`),
                  value: 0,
                },
                {
                  label: this.context.t(`['sms/filter'].tabs.debtTrue`),
                  value: 1,
                },
              ]}
            />
          </FilterTab>
        )) ||
          null}

        {(this.context.current_flag &
          this.context.state.FLAGS.SALON_RANDEVU && (
          <FilterTab
            title={this.context.t(`['sms/filter'].tabs.risk`)}
            typeKey="risk_list"
          >
            <FilterRadioGroup
              radios={[
                {
                  label: this.context.t(`['sms/filter'].tabs.risk15`),
                  value: 15,
                },
                {
                  label: this.context.t(`['sms/filter'].tabs.risk30`),
                  value: 30,
                },
                {
                  label: this.context.t(`['sms/filter'].tabs.risk45`),
                  value: 45,
                },
                {
                  label: this.context.t(`['sms/filter'].tabs.risk60`),
                  value: 60,
                },
              ]}
              value={filter.risk_list}
              onChange={(e) =>
                this.setState({
                  filter: { ...filter, risk_list: parseInt(e.target.value) },
                })
              }
            />
          </FilterTab>
        )) ||
          null}

        {(this.context.current_flag &
          this.context.state.FLAGS.SALON_RANDEVU && (
          <FilterTab
            title={this.context.t(`['sms/filter'].tabs.service`)}
            typeKey="services"
          >
            <FilterCheckboxGroup
              name="services"
              checkboxes={[
                ...this.state.categories.map((service) => ({
                  label: service.name,
                  value: service.id,
                })),
              ]}
            />
          </FilterTab>
        )) ||
          null}

        <FilterTab
          title={this.context.t(`['sms/filter'].tabs.staff`)}
          typeKey="staffs"
        >
          <FilterCheckboxGroup
            name="staffs"
            checkboxes={[
              ...staffs.map((staff) => ({
                label: staff.full_name,
                value: staff.id,
              })),
            ]}
          />
        </FilterTab>

        <FilterTab
          title={this.context.t(`['sms/filter'].tabs.gender`)}
          typeKey="gender"
        >
          <FilterCheckboxGroup
            name="gender"
            checkboxes={[
              {
                value: 1,
                label: this.context.t(`['sms/filter'].tabs.genderMale`),
              },
              {
                value: 0,
                label: this.context.t(`['sms/filter'].tabs.genderFemale`),
              },
            ]}
          />
        </FilterTab>

        <FilterTab
          title={this.context.t(`['sms/filter'].tabs.age`)}
          typeKey="age_range"
          onOpen={() =>
            this.setState({ filter: { ...filter, age_range: [1, 75] } })
          }
          onClose={() =>
            this.setState({ filter: { ...filter, age_range: null } })
          }
        >
          <div className="mb-3 pl-1 pr-1">
            <Slider
              defaultValue={[1, 75]}
              onChangeCommitted={async (_, value) =>
                this.setState({ filter: { ...filter, age_range: value } })
              }
              valueLabelDisplay="on"
              ValueLabelComponent={({ value, children }) => (
                <Tooltip
                  style={{ background: "#888" }}
                  open
                  placement="bottom"
                  title={value}
                >
                  {children}
                </Tooltip>
              )}
              min={1}
              step={1}
              max={75}
            />
          </div>
        </FilterTab>

        <Button
          iconComponent={FilterList}
          title={this.context.t(`['sms/filter'].applyFilterButtonTitle`)}
          textColor="primary"
          backgroundColor="primary-opacity"
          fullWidth
          onClick={() => this.getFilteredCustomers()}
        />

        {/*<pre style={{ fontSize: 12, whiteSpace: 'pre-wrap' }}>{JSON.stringify(this.filterFormatter(), 0, 1)}</pre>*/}
      </Column>
    );
  };

  /**
   * Filtreleme işlemleri sonrasında anlık güncellenen müşteriler tablosu.
   */
  _CustomersBuilder = () => {
    const { step, except, customers_table } = this.state;
    return (
      <Column
        className="xs-12 sm-12 md-12 lg-5 xl-5"
        style={{ display: step === 0 ? "block" : "none" }}
      >
        <SectionTitle>
          {this.context.t(`['sms/filter'].customer.title`)}
        </SectionTitle>
        <Table
          refreshAction={() =>
            this.getFilteredCustomers(
              this.state.customers_table.pagination.page
            )
          }
          loaded={customers_table.loaded}
          headings={{
            //"id": { label: "Müşteri Kodu" },
            full_name: {
              label: this.context.t(
                `['sms/filter'].customer.headings.fullName`
              ),
            },
            phone: {
              label: this.context.t(`['sms/filter'].customer.headings.phone`),
              style: { width: 150 },
            },
            sex: {
              label: this.context.t(`['sms/filter'].customer.headings.gender`),
            },
            _: { label: this.context.t(`component.actionHeadingText`) },
          }}
          replacements={{
            sex: {
              null: this.context.t(
                `['sms/filter'].customer.replacements.genderNull`
              ),
              0: this.context.t(
                `['sms/filter'].customer.replacements.genderFemale`
              ),
              1: this.context.t(
                `['sms/filter'].customer.replacements.genderMale`
              ),
              3: this.context.t(`customers.replacements.sex.undef`),
            },
          }}
          rows={customers_table.data}
          pagination={customers_table.pagination}
          buttons={[
            {
              iconComponent: Block,
              textColor: "red",
              disabled: (row) =>
                except.filter((_row) => _row.id === row.id).length > 0,
              transitionEffect: true,
              onClick: (row) =>
                this.setState({
                  except: except.includes(row)
                    ? [...except.filter((_row) => _row !== row)]
                    : [...except, row],
                }),
            },
          ]}
        />
      </Column>
    );
  };

  /**
   * Gönderimden hariç tutulacak müşteri kolonu.
   */
  _ExceptedCustomersBuilder = () => {
    const { step, except } = this.state;

    return (
      <Column className="xs-12 sm-12 md-12 lg-auto xl-auto">
        <SectionTitle className="mb-1">
          {this.context.t(`['sms/filter'].except.title`)}
        </SectionTitle>
        <ChipContainer>
          {except.length > 0 ? (
            except.map((item) => (
              <Chip
                style={{ height: 46, borderRadius: 5 }}
                className="mr-2 mb-2 bg-red-opacity fc-red fw-500"
                label={item.full_name}
                onDelete={() =>
                  this.setState({
                    except: [...except.filter((_item) => _item !== item)],
                  })
                }
              />
            ))
          ) : (
            <Alert
              severity="info"
              style={{ width: "100%", boxSizing: "border-box" }}
            >
              <b>{this.context.t(`['sms/filter'].except.alertBoldText`)}</b>
              <br />
              {this.context.t(`['sms/filter'].except.alertText`)}
            </Alert>
          )}
        </ChipContainer>

        {step === 0 && (
          <Button
            className="mt-3"
            title={this.context.t(`['sms/filter'].continueButtonTitle`)}
            icon="chevron_right"
            backgroundColor="primary"
            textColor="white"
            fullWidth
            onClick={() => this.setState({ step: 1 })}
          />
        )}
      </Column>
    );
  };

  /**
   * SMS gönderim alanıdır.
   */
  _SmsSettingsBuilder = () => {
    const {
      step,
      sms_request_status,
      message,
      check_dialog,
      selected_template,
      selected_time,
      send_date,
      hours,
      templates,
    } = this.state;
    return (
      <>
        <Column
          className="xs-12 sm-12 md-12 lg-8"
          style={{ display: step === 1 ? "block" : "none" }}
        >
          <Column className="xs-12 sm-12 md-12 lg-8">
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  checked={this.state.future_date}
                  onChange={async (e) => {
                    await this.setState({
                      future_date: e.target.checked,
                    });
                  }}
                />
              }
              label={
                <FCLabel>
                  <i className="material-icons">filter_list</i>{" "}
                  <span>{this.context.t(`sms.send.futureDate`)}</span>
                </FCLabel>
              }
            />
          </Column>
          <Grid>
            <Column className="xs-12 sm-12 md-12 lg-12 xl-3">
              <Select
                label={this.context.t(`sms.send.template`)}
                items={[...templates]}
                selected={selected_template?.name ?? ""}
                labelKey="name"
                valueKey="name"
                returnFullObject
                handler={(template) =>
                  this.setState({
                    selected_template: template,
                    message: template.content,
                  })
                }
              />
            </Column>
            {this.state.future_date && (
              <>
                <Column className="xs-12 sm-12 md-12 lg-12 xl-3">
                  <DatePickerInput
                    label={this.context.t(`sms.send.sendDate`)}
                    value={new Date(send_date).toISOString()}
                    onChange={(date) => {
                      this.setState({
                        send_date: new Date(date).toISOString(),
                      });
                    }}
                  />
                </Column>
                <Column className="xs-12 sm-12 md-12 lg-12 xl-3">
                  <TimeSelect
                    label={this.context.t(
                      `['settings/hours'].startInputLabelHour`
                    )}
                    type="hour"
                    value={
                      selected_time !== null ? selected_time.split(":")[0] : ""
                    }
                    fullWidth={true}
                    size="medium"
                    companyShiftTime={true}
                    startHour={1}
                    endHour={23}
                    // minuteStep={5}
                    handler={async (value) => {
                      this.setState({
                        selected_time:
                          selected_time !== null
                            ? `${value}:${selected_time.split(":")[1]}`
                            : `${value}:00`,
                      });
                    }}
                  />
                </Column>
                <Column className="xs-12 sm-12 md-12 lg-12 xl-3">
                  <TimeSelect
                    label={this.context.t(
                      `['settings/hours'].startInputLabelMinute`
                    )}
                    type="minute"
                    value={
                      selected_time !== null ? selected_time.split(":")[1] : ""
                    }
                    selectedHour={selected_time}
                    lastHour={hours[hours.length - 1]}
                    fullWidth={true}
                    size="medium"
                    handler={async (time) => {
                      this.setState({
                        selected_time:
                          selected_time !== null
                            ? `${selected_time.split(":")[0]}:${time}`
                            : null,
                      });
                    }}
                  />
                </Column>
              </>
            )}
          </Grid>
          <Input
            label={this.context.t(`sms.send.messageInputLabel`)}
            autoFocus={step === 1}
            multiline={true}
            rows={10}
            value={message}
            onChange={(e) => this.setState({ message: e.target.value })}
            helperText={CalculateMessageCharCount(
              this.state.message,
              `${
                this.state.checkedSMS && this.state.checkedWP
                  ? "1"
                  : !this.state.checkedSMS && this.state.checkedWP
                  ? "3"
                  : "1"
              } `
            )}
          />

          <Grid>
            <Column className="xs-12 sm-12 md-12 lg-6">
              <Button
                className="mb-1"
                title={this.context.t(`sms.send.autoText.nameSurname`)}
                size="xs"
                textColor="primary"
                outlined
                onClick={() =>
                  this.setState({ message: `${message}|ad soyad|` })
                }
              />
              <Button
                className="mb-1 ml-1"
                title={this.context.t(`sms.send.autoText.shortCode`)}
                size="xs"
                textColor="purple"
                outlined
                disabled={this.state.shortcode === ""}
                onClick={() =>
                  this.setState({
                    message: `${message}SMS iptali için ${this.state.shortCode} yazın 4607'a gönderin.`,
                  })
                }
              />
            </Column>
          </Grid>

          <Button
            disabled={
              message.length === 0 ||
              !CalculateMessageCount(
                message,
                `${
                  this.state.checkedSMS && this.state.checkedWP
                    ? "1"
                    : !this.state.checkedSMS && this.state.checkedWP
                    ? "3"
                    : "1"
                } `
              )
            }
            icon="send"
            title={this.context.t("menuItems.sms.children.sendFilter")}
            backgroundColor="primary"
            textColor="white"
            fullWidth={true}
            onClick={() => this.getSMSReview()}
          />

          <Button
            className="mt-1"
            icon="chevron_left"
            title={this.context.t(`['sms/filter'].backToFilterButtonTitle`)}
            fullWidth
            onClick={() => this.setState({ step: 0 })}
          />
        </Column>

        <AlertDialog
          title={this.context.t(`sms.send.check.title`)}
          open={check_dialog}
          textType={true}
          closeHandler={() => this.setState({ check_dialog: false })}
          buttons={[
            {
              icon: "check",
              title: this.context.t(`sms.send.check.confirmButtonTitle`),
              backgroundColor: "primary-opacity",
              textColor: "primary",
              onClick: () => this.getSMSReview(),
            },
            {
              icon: "close",
              title: this.context.t(`sms.send.check.discardButtonTitle`),
              textColor: "grey",
            },
          ]}
        >
          {/*
          <MessagePreview className="mb-3">
            <h5>Gönderilecek Örnek Mesaj:</h5>
            <p>
              GenerateMessagePreview(message ?? '', {
                '|ad soyad|': this.state.customers_table.data.filter(customer => !this.state.except.includes(customer))?.[0]?.full_name
              })
            </p>
          </MessagePreview>*/}

          <Alert severity="warning">
            {this.context.t(`sms.send.check.alertText`)}
          </Alert>
        </AlertDialog>
      </>
    );
  };

  FilterCheckboxGroup = ({ checkboxes, name }) => {
    const { filter } = this.state;
    return (
      <FormGroup>
        {checkboxes?.map((item, index) => (
          <FormControlLabel
            style={{
              width: "100%",
              margin: checkboxes.length - 1 === index ? 0 : "0 0 8px",
            }}
            label={<FCLabel>{item.label}</FCLabel>}
            control={
              <Checkbox
                style={{ padding: 0, marginRight: 4 }}
                checked={item.checked}
                onChange={(e) => {
                  filter[name].includes(item.value)
                    ? this.setState({
                        filter: {
                          ...filter,
                          [name]: filter[name].filter((i) => i !== item.value),
                        },
                      })
                    : this.setState({
                        filter: {
                          ...filter,
                          [name]: [...filter[name], item.value],
                        },
                      });
                }}
                color="primary"
              />
            }
          />
        ))}
      </FormGroup>
    );
  };

  FilterRadioGroup = ({ name, value, onChange, radios }) => {
    return (
      <RadioGroup
        aria-label={name}
        name={name}
        value={value}
        onChange={onChange}
      >
        {radios?.map((item, index) => (
          <FormControlLabel
            style={{
              width: "100%",
              margin: radios.length - 1 === index ? 0 : "0 0 8px",
            }}
            label={<FCLabel>{item.label}</FCLabel>}
            value={item.value}
            control={
              <Radio style={{ padding: 0, marginRight: 4 }} color="primary" />
            }
          />
        ))}
      </RadioGroup>
    );
  };

  FilterTab = (props) => {
    const { filter } = this.state;
    const [expanded, setExpanded] = useState(props.openDefault ?? false);

    return (
      <FTabComponent>
        <div
          className="header"
          onClick={async () => {
            await setExpanded(!expanded);
            await this.setState({
              filter: {
                ...filter,
                [props.typeKey]: getFilterModel()[props.typeKey],
              },
            });

            if (!expanded && props.onOpen) await props.onOpen();
            if (expanded && props.onClose) await props.onClose();
          }}
        >
          <span className="title">{props.title ?? ""}</span>
          <Switch checked={expanded} size="small" color="primary" />
        </div>
        {expanded && <div className="inner">{props.children}</div>}
      </FTabComponent>
    );
  };
}

export default SendFilter;

const FTabComponent = styled.div`
  width: 100%;
  box-shadow: 0 4px 8px rgba(0,0,0,0.04);
  margin-bottom: 16px;

  .header{
    height: 54px;
    box-sizing: border-box;
    cursor: pointer;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding 0 16px;

    span.title {
      font-size: 15.6px;
      line-height: 1;
      font-weight: 500;
      margin: 0;
      padding: 0;
    }
  }

  .inner{
    padding: 0 16px 16px;
  }

`;

const ChipContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;
/*
const MessagePreview = styled.div`
  h5{ font-size: 14px; padding: 0; margin: 0; font-weight: 500; color: #555 }
  p{font-size: 15px; white-space: pre-wrap; line-height: 1.4}
`;*/

const StatsCardContainer = styled.div`
  position: relative;
  display: block;
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
  min-height: 73px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.04);
`;

const IconAlignContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  box-sizing: border-box;
  border-radius: 5px;
`;

const TextAlignContainer = styled.div`
  height: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding: 0 24px;

  @media (max-width: 768px) {
    align-items: center;
    text-align: center;
    padding: 16px;
  }
`;

const StatsHeader = styled.h1`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  padding: 0;
  color: #aaa;
  padding-bottom: 10px;
`;

const StatsTitle = styled.h3`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  padding: 0;
  color: #a0a0a0;
`;

const StatsValue = styled.p`
  font-weight: 600;
  font-size: 20px;
  line-height: 1.2;
  margin: 0;
  padding: 0;
`;
