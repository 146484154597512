import React, { Component } from "react";
import AppContext from "../../../context/store";
import AuthContainer from "../../../components/AuthContainer";

import { Grid, Column } from "../../../theme/Grid";
import Axios from "axios";
// import Select from "../../../theme/CustomMUI/Select";
import { toast } from "react-toastify";
import Button from "../../../theme/Button";
import styled from "styled-components";
import { FormControlLabel, Switch } from "@material-ui/core";
import FCLabel from "../../../theme/FCLabel";
import { ClosedCaptionSharp, Update } from "@material-ui/icons";
import AlertDialog from "../../../theme/CustomMUI/AlertDialog";
import DoneIcon from "@material-ui/icons/Done";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Input from "../../../theme/CustomMUI/Input";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
class Settings extends Component {
  static contextType = AppContext;

  state = {
    show_in_platform: null,
    show_amount_platform: null,
    show_comment_platform: null,
    appointment_auto_accept: null,
    platform_color: null,
    platform_video_url: null,
    selectedValue: null,
    open_color_dialog: false,

    selectedColorCode: null,
  };

  getSettings = async () => {
    Axios.get(
      `${this.context.api_endpoint}/company/platform/setting/index`
    ).then(({ data }) => {
      this.setState({
        ...data.data,
        selectedColorCode: data.data.platform_color
          ? data.data.platform_color
          : null,
      });
    });
  };

  updateSettings = async () => {
    const {
      show_amount_platform,
      show_comment_platform,
      show_in_platform,
      appointment_auto_accept,
      selectedColorCode,
      platform_video_url,
    } = this.state;
    await Axios.put(
      `${this.context.api_endpoint}/company/platform/setting/update`,
      {
        show_amount_platform: show_amount_platform,
        show_comment_platform: show_comment_platform,
        show_in_platform: show_in_platform,
        appointment_auto_accept: appointment_auto_accept,
        platform_color: selectedColorCode,
        platform_video_url: platform_video_url,
      }
    )
      .then(({ data }) => {
        toast.success("Platform ayarları başarıyla güncellendi.");
      })
      .catch((e) => {
        if (e.response.status === 404) {
          toast.error(e.response.data.data);
        }
      });
  };

  componentDidMount() {
    this.getSettings();
  }

  render() {
    const HOCSettingBox = this._SettingsBoxBuilder;
    const ColorSelection = this._ColorSelection;
    const ColorSelectionDialog = this._ColorSelectionDialog;

    return (
      <AuthContainer authorities={[1]} limited_permission="system">
        <Grid>
          <HOCSettingBox
            boxProps={{
              title: this.context.t(
                `['platform/settings'].platformVisibleTitle`
              ),
              description: this.context.t(
                `['platform/settings'].platformVisibleDescription`
              ),
            }}
            optionList={[
              {
                label: this.context.t(`['platform/settings'].offOn`),
                key: "show_in_platform",
                disabled: false,
              },
            ]}
          />{" "}
          <HOCSettingBox
            boxProps={{
              title: this.context.t(`['platform/settings'].platformPriceTitle`),
              description: this.context.t(
                `['platform/settings'].platformPriceDescription`
              ),
            }}
            optionList={[
              {
                label: this.context.t(`['platform/settings'].offOn`),
                key: "show_amount_platform",
                disabled: false,
              },
            ]}
          />
          <HOCSettingBox
            boxProps={{
              title: this.context.t(
                `['platform/settings'].platformCommentTitle`
              ),
              description: this.context.t(
                `['platform/settings'].platformCommentDescription`
              ),
            }}
            optionList={[
              {
                label: this.context.t(`['platform/settings'].offOn`),
                key: "show_comment_platform",
                disabled: false,
              },
            ]}
          />
          <HOCSettingBox
            boxProps={{
              title: this.context.t(
                `['platform/settings'].platfromAppointmentTitle`
              ),
              description: this.context.t(
                `['platform/settings'].platfromAppointmentDescription`
              ),
            }}
            optionList={[
              {
                label: this.context.t(`['platform/settings'].offOn`),
                key: "appointment_auto_accept",
                disabled: false, // It will be changed.
              },
            ]}
          />
          <ColorSelection />
          <Column className="xs-12 sm-12 md-4">
            <SettingsBox>
              <h3>
                {this.context.t(`['platform/settings'].platformVideoURLTitle`)}
              </h3>
              <p>
                {this.context.t(
                  `['platform/settings'].platformVideoURLDescription`
                )}
              </p>
              <Input
                label={this.context.t(
                  `['platform/settings'].platformVideoURLInputLabel`
                )}
                placeholder={this.context.t(
                  `['platform/settings'].platformVideoURLInputPlaceholder`
                )}
                value={this.state.platform_video_url ?? ""}
                onChange={(e) => {
                  this.setState({ platform_video_url: e.target.value });
                }}
              />
            </SettingsBox>
          </Column>
          <Column className="xs-12 sm-12">
            <Button
              iconComponent={Update}
              title={"Ayarları Güncelle"}
              backgroundColor="primary"
              textColor="white"
              fullWidth
              onClick={() => this.updateSettings()}
            />
          </Column>
        </Grid>
        <ColorSelectionDialog />
      </AuthContainer>
    );
  }

  _SettingsBoxBuilder = ({ children, boxProps, optionList }) => {
    return (
      <Column className="xs-12 sm-12 md-4">
        <SettingsBox>
          <h3>{boxProps.title}</h3>
          {!Array.isArray(boxProps.description) ? (
            <p>{boxProps.description}</p>
          ) : (
            boxProps.description.map((descItem) => {
              return <p className="mb-0">{descItem}</p>;
            })
          )}

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flex: 1,
              alignItems: "center",
            }}
          >
            {optionList?.map((item) => (
              <FormControlLabel
                key={item.key}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flex: 1,
                  alignItems: "center",
                  justifyContent: "center",
                }}
                label={<FCLabel>{item.label}</FCLabel>}
                control={
                  <Switch
                    disabled={item.disabled}
                    color="primary"
                    checked={
                      this.state[item.key] === true
                        ? 1
                        : 0 || this.state[item.key] === 1
                        ? true
                        : false
                    }
                    onChange={(e) => {
                      if (
                        item.key === "show_in_platform" &&
                        e.target.checked === false
                      ) {
                        this.setState({
                          [item.key]: 0,
                        });
                      } else {
                        this.setState({
                          [item.key]: e.target.checked ? 1 : 0,
                        });
                      }
                    }}
                  />
                }
              />
            ))}
          </div>
          {children}
        </SettingsBox>
      </Column>
    );
  };

  _ColorSelection = () => {
    const { open_color_dialog } = this.state;
    return (
      <Column className="xs-12 sm-12 md-4">
        <SettingsBox>
          <h3>
            {this.context.t(
              `['platform/settings'].platformColorSelectionTitle`
            )}
          </h3>
          <p>
            {this.context.t(
              `['platform/settings'].platformColorSelectionDescription`
            )}
          </p>

          <Button
            title={this.context.t(`['platform/settings'].chooseColor`)}
            backgroundColor="blue-opacity"
            textColor="blue"
            fullWidth
            onClick={() =>
              this.setState({ open_color_dialog: !open_color_dialog })
            }
          />
        </SettingsBox>
      </Column>
    );
  };

  _ColorSelectionDialog = () => {
    const { open_color_dialog, selectedColorCode } = this.state;
    const colors = [
      "#339bcc",
      "#cc3333",
      "#cc3370",
      "#33cc69",
      "#cc8133",
      "#cacc33",
    ];

    const selectColorHandler = (colorItem) => {
      this.setState({ selectedColorCode: colorItem });
    };

    const cancelColorHandler = () => {
      this.setState({ selectedColorCode: null });
    };

    const closeDialogHandler = () => {
      this.setState({ open_color_dialog: false });
    };

    return (
      <AlertDialog
        title={
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {this.context.t(`['platform/settings'].colorDialogTitle`)}
            <IconButton aria-label="close" onClick={closeDialogHandler}>
              <CloseIcon />
            </IconButton>
          </div>
        }
        buttons={[
          {
            title: this.context.t(
              `['platform/settings'].colorDialogCancelTitle`
            ),
            icon: "close",
            backgroundColor: "white",
            textColor: "grey",
            disabled: selectedColorCode ? false : true,
            disabledHandleClose: true,
            onClick: cancelColorHandler,
          },
          {
            title: this.context.t(
              `['platform/settings'].colorDialogSelectTitle`
            ),
            icon: "check",
            backgroundColor: "primary-opacity",
            textColor: "primary",
            onClick: () => {},
          },
        ]}
        fullWidth
        //disableBackdropClick={false}
        maxWidth={"sm"}
        open={open_color_dialog}
        closeHandler={() => this.setState({ open_color_dialog: false })}
      >
        <div>
          {colors.map((item, index) => (
            <ColorBox
              color={item}
              onClick={() => {
                selectColorHandler(item);
              }}
            >
              <div
                className={`selected-box ${
                  selectedColorCode === item ? "active" : "hide"
                }`}
              >
                <DoneIcon />
              </div>
            </ColorBox>
          ))}
        </div>
      </AlertDialog>
    );
  };
}

export default Settings;

const SettingsBox = styled.div`
  width: 100%;
  min-height: 147px;
  display: flex;
  flex-direction: column;
  font-family: "Inter", sans-serif;

  padding: 16px;
  margin: 0 0 16px;
  box-sizing: border-box;

  border-radius: 5px;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.04);

  h3 {
    font-size: 16px;
    font-weight: 500;
    padding: 0;
    margin: 0 0 4px;
  }

  p {
    color: #808080;
    font-size: 12.6px;
    line-height: 1.614;
    margin: 0 0 8px;
  }
`;

const ColorBox = styled.div`
  width: 100%;
  height: 40px;
  margin-bottom: 10px;
  background: ${(props) => (props.color ? props.color : "#fff")};
  cursor: pointer;

  .selected-box,
  active,
  hide {
    width: 0px;
    height: 100%;
    background: #fff;
    display: flex;
    align-items: center;
    transition: all 250ms linear;
  }

  .active {
    width: 40px;
    opacity: 1;
  }

  .hide {
    width: 0px;
    opacity: 0;
  }
`;
