import React, { useState, useContext, useEffect } from "react";
import AlertDialog from "../../theme/CustomMUI/AlertDialog";
import { Grid, Column } from "../../theme/Grid";
import MaskedInput from "../../theme/CustomMUI/MaskedInput";
import Select from "../../theme/CustomMUI/Select";
import DatePickerInput from "../../theme/CustomMUI/DatePickerInput";
import Validate from "../../functions/Validate";
import Input from "../../theme/CustomMUI/Input";
import moment from "moment";
import Button from "../../theme/Button";
import Axios from "axios";
import AppContext from "../../context/store";
import { toast } from "react-toastify";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";

const CustomerAddDialog = (props) => {
  const context = useContext(AppContext);
  const [expanded, setExpanded] = useState(false);
  const [customer, setCustomer] = useState({
    name: "",
    surname: "",
    phone: null,
    dialCode: null,
    secondary_phone: null,
    s_dialCode: null,
    email: null,
    sex: null,
    description: null,
    birthday: null,

    related_company: null,
    send_sms: true,
    nationalityID: null,

    //Picker için
    real_selected_birthday: null,
  });

  const [selectPhoneNumberList, setSelectPhoneNumberList] = useState([
    {
      id: 1,
      name: context.t(`['customers/add'].firstPhoneNumber`),
      type: 0,
    },
    {
      id: 2,
      name: context.t(`['customers/add'].secondPhoneNumber`),
      type: 1,
    },
    {
      id: 3,
      name: context.t(`['customers/add'].allPhoneNumber`),
      type: 2,
    },
  ]);
  const [selectPhoneNumber, setSelectPhoneNumber] = useState({
    id: 1,
    name: context.t(`['customers/add'].firstPhoneNumber`),
    type: 0,
  });

  const [foreingNumber, setForeignNumber] = useState(false);

  const validateCustomer = () => {
    let validate_for_phone_number;

    if (customer.secondary_phone === null) {
      validate_for_phone_number = {
        field: "Telefon Numarası",
        value: customer.phone,
        condition: /\S{10}/,
      };
    } else {
      validate_for_phone_number = {
        field: "Telefon Numarası",
        value: customer.phone,
        condition: /^\s*|\S{10}/,
      };
    }

    return Validate([
      {
        field: "İsim",
        value: customer.name,
        condition: /.+/,
      },
      {
        field: "Soyisim",
        value: customer.surname,
        condition: /.+/,
      },
      validate_for_phone_number,
    ]);
  };

  const insertCustomer = () => {
    let birthday =
      customer.real_selected_birthday !== null
        ? customer.real_selected_birthday.toISOString()
        : null;
    if (customer.birthday !== null && customer.birthday.length === 9) {
      const birthday_arr = customer.birthday.split("/");
      birthday = new Date(
        `${birthday_arr[2]}-${birthday_arr[1]}-${birthday_arr[0]}`
      );
      birthday.setHours(0, 0, 0, 0);
      birthday = birthday.toISOString();
    }
    validateCustomer() &&
      Axios.post(`${context.api_endpoint}/company/customer/insert`, {
        ...customer,
        phone: `${customer.phone}`.length === 10 ? customer.phone : "",
        dialCode: "+90",
        secondary_phone: customer?.secondary_phone?.slice(
          customer.s_dialCode.length
        ),
        s_dialCode:
          customer.s_dialCode !== null && customer.s_dialCode !== undefined
            ? "+" + customer.s_dialCode
            : null,

        birthday,
        selectedPhone: selectPhoneNumber && selectPhoneNumber.type,
      })
        .then((response) => {
          if (response.status === 200) {
            toast.success(context.t(`component.CADialog.insertToast`));
            props.createdCustomerHandler(response.data.data);
          }
        })
        .catch((e) => {
          toast.error(e.response?.data.message);
        });
  };

  useEffect(() => {
    if (props.open === true) {
      setCustomer({
        name: "",
        surname: "",
        phone: null,
        secondary_phone: null,
        email: null,
        sex: null,
        description: null,
        birthday: null,

        related_company: null,
        send_sms: true,

        //Picker için
        real_selected_birthday: null,
      });
    }
  }, [props.open === true]);

  const SecondaryPhone_handleOnChange = (
    value,
    data,
    event,
    formattedValue
  ) => {
    setCustomer((prevCustomer) => ({
      ...prevCustomer,
      secondary_phone: value,
      s_dialCode: data.dialCode,
    }));
  };

  return (
    <AlertDialog
      title={context.t(`component.CADialog.title`)}
      buttons={
        props.createNewUnSign
          ? [
              {
                title: context.t(`component.CADialog.createButtonTitle`),
                icon: "check",
                backgroundColor: "primary-opacity",
                textColor: "primary",
                onClick: () => {
                  /* validateCustomer() === false
                    ? props.setOpen(true)
                    :  */ insertCustomer();
                },
              },
              {
                icon: "add",
                title: context.t(
                  `appointments.create.createNewWalkinCustomerButtonTitle`
                ),
                backgroundColor: "secondary-opacity",
                textColor: "secondary",
                onClick: () => {
                  props.createNewUnSign();
                },
              },
              {
                title: context.t(`component.CADialog.discardButtonTitle`),
                icon: "close",
                backgroundColor: "grey-opacity",
                textColor: "grey",
              },
            ]
          : [
              {
                title: context.t(`component.CADialog.createButtonTitle`),
                icon: "check",
                backgroundColor: "primary-opacity",
                textColor: "primary",
                onClick: () => {
                  validateCustomer() === false
                    ? props.setOpen(true)
                    : insertCustomer();
                },
              },
              {
                title: context.t(`component.CADialog.discardButtonTitle`),
                icon: "close",
                backgroundColor: "grey-opacity",
                textColor: "grey",
              },
            ]
      }
      fullWidth
      maxWidth={expanded ? "lg" : "md"}
      open={props.open}
      closeHandler={props.closeHandler}
    >
      <Grid className="no-gutters-all">
        <Column className="xs-12 sm-12 md-6 lg-6">
          <Input
            required
            label={context.t(`component.CADialog.nameInputLabel`)}
            placeholder={context.t(`component.CADialog.nameInputPlaceholder`)}
            value={customer.name !== null ? customer.name : ""}
            onChange={(e) =>
              setCustomer({
                ...customer,
                name: e.target.value,
              })
            }
          />
        </Column>

        <Column className="xs-12 sm-12 md-6 lg-6">
          <Input
            required
            label={context.t(`component.CADialog.surnameInputLabel`)}
            placeholder={context.t(
              `component.CADialog.surnameInputPlaceholder`
            )}
            value={customer.surname !== null ? customer.surname : ""}
            onChange={(e) =>
              setCustomer({
                ...customer,
                surname: e.target.value,
              })
            }
          />
        </Column>

        <Column className="xs-12 sm-12 md-6 lg-6">
          <MaskedInput
            required
            disabled={foreingNumber}
            label={context.t(`component.CADialog.phoneInputLabel`)}
            maskChar={""}
            mask="999 999 9999"
            startAdornment="0"
            value={customer.phone !== null ? customer.phone : ""}
            placeholder="500 000 0000"
            onChange={(e) =>
              setCustomer({
                ...customer,
                phone:
                  e.target.value.substring(0, 1) === "5" &&
                  e.target.value.replace(/\s/g, ""),
              })
            }
          />
        </Column>
        <Column className="xs-12 sm-12 md-6 lg-6">
          <Select
            label={context.t(`component.CADialog.genderInputLabel`)}
            items={[
              {
                value: 0,
                name: context.t(`component.CADialog.genderFemale`),
              },
              {
                value: 1,
                name: context.t(`component.CADialog.genderMale`),
              },
            ]}
            selected={customer.sex !== null ? `${customer.sex}` : ""}
            labelKey="name"
            valueKey="value"
            handler={(sex) =>
              setCustomer({
                ...customer,

                sex: parseInt(sex),
              })
            }
          />
        </Column>
        <Column className="xs-12 sm-12 md-12 lg-12">
          <Button
            className="mb-1"
            icon={!expanded ? "arrow_right" : "arrow_drop_down"}
            title={`${context.t(`component.CADialog.advancedOptions`)} - ${
              expanded
                ? context.t(`component.CADialog.advancedOptionsHide`)
                : context.t(`component.CADialog.advancedOptionsShow`)
            }`}
            size="xs"
            fullWidth
            onClick={() => setExpanded(!expanded)}
          />
        </Column>
      </Grid>
      {expanded && (
        <>
          <Grid className="no-gutters-row">
            <Column className="xs-12 sm-12 md-12 lg-6">
              <DatePickerInput
                label={context.t(`component.CADialog.birthdayInputLabel`)}
                error={false}
                invalidDateMessage={context.t(
                  `component.CADialog.birthdayInputInvalidMessage`
                )}
                minDate={new Date("01-01-1900")}
                value={
                  customer.real_selected_birthday !== null
                    ? customer.real_selected_birthday
                    : null
                }
                onChange={(date) =>
                  setCustomer({
                    ...customer,
                    birthday: moment(date).format("DD/MM/YYYY"),
                    real_selected_birthday: date,
                  })
                }
              />
            </Column>
            <Column className="xs-12 sm-12 md-12 lg-6">
              <Input
                label={context.t(`['customers/add/bulk'].preview.nationalID`)}
                placeholder={context.t(
                  `['customers/add/bulk'].preview.nationalID`
                )}
                value={
                  customer.nationalityID !== null ? customer.nationalityID : ""
                }
                onChange={(e) => {
                  const value = e.target.value;
                  if (value.length > 11) {
                    return;
                  }
                  setCustomer({
                    ...customer,
                    nationalityID: value,
                  });
                }}
              />
            </Column>
          </Grid>

          <Grid className="no-gutters-row mb-3">
            <Column className="xs-12 sm-12 md-12 lg-6">
              <PhoneInput
                inputStyle={{ width: "100%" }}
                country={"tr"}
                countryCodeEditable={false}
                value={customer.secondary_phone}
                onChange={SecondaryPhone_handleOnChange}
                specialLabel={context.t(
                  `['customers/add'].secondPhoneInputLabel`
                )}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={foreingNumber}
                    onChange={(e) => {
                      setCustomer({
                        ...customer,
                        phone: e.target.checked ? "" : "",
                      });
                      setForeignNumber(e.target.checked);
                    }}
                    name="foreign_number"
                    color="primary"
                  />
                }
                label={
                  <span style={{ fontSize: "14px" }}>
                    {context.t(`component.CADialog.checkingForeingNumber`)}
                  </span>
                }
              />
            </Column>

            <Column className="xs-12 sm-12 md-12 lg-6">
              <Input
                label={context.t(`component.CADialog.mailInputLabel`)}
                value={customer.email !== null ? customer.email : ""}
                placeholder={context.t(
                  `component.CADialog.mailInputPlaceholder`
                )}
                onChange={(e) =>
                  setCustomer({
                    ...customer,
                    email: e.target.value,
                  })
                }
              />
            </Column>
            <Column className="xs-12 sm-12 md-12 lg-4"></Column>
          </Grid>

          <Grid className="no-gutters-row">
            <Column className="xs-12 sm-12 md-12 lg-6">
              <Select
                label={context.t(`component.CADialog.sendSmsInputLabel`)}
                items={[
                  {
                    label: context.t(`component.CADialog.sendSmsTrue`),
                    value: true,
                  },
                  {
                    label: context.t(`component.CADialog.sendSmsFalse`),
                    value: false,
                  },
                ]}
                selected={
                  customer.send_sms !== null ? `${customer.send_sms}` : true
                }
                labelKey="label"
                valueKey="value"
                handler={(send_sms) =>
                  setCustomer({
                    ...customer,
                    send_sms: send_sms === "true",
                  })
                }
              />
            </Column>
            <Column className="xs-12 sm-12 md-12 lg-6">
              <Select
                label={context.t(
                  `['customers/add'].selectPhoneNumberForMessage`
                )}
                items={selectPhoneNumberList}
                labelKey="name"
                valueKey="id"
                selected={
                  selectPhoneNumber
                    ? selectPhoneNumber.id
                    : selectPhoneNumberList[0].id
                }
                returnFullObject
                handler={async (filterPicker) => {
                  console.log(filterPicker);
                  setSelectPhoneNumber(filterPicker);
                }}
              />
            </Column>
          </Grid>

          <Grid className="no-gutters-row">
            <Column className="xs-12 sm-12 md-12 lg-6">
              <Input
                label={context.t(`component.CADialog.descriptionInputLabel`)}
                value={
                  customer.description !== null ? customer.description : ""
                }
                multiline={true}
                rows={3}
                onChange={(e) =>
                  setCustomer({
                    ...customer,
                    description: e.target.value,
                  })
                }
              />
            </Column>
            <Column className="xs-12 sm-12 md-12 lg-6">
              <Input
                label={context.t(
                  `component.CADialog.relatedBusinessInputLabel`
                )}
                placeholder={context.t(
                  `component.CADialog.relatedBusinessInputPlaceholder`
                )}
                value={
                  customer.related_company !== null
                    ? customer.related_company
                    : ""
                }
                onChange={(e) =>
                  setCustomer({
                    ...customer,
                    related_company: e.target.value,
                  })
                }
              />
            </Column>
          </Grid>
        </>
      )}
    </AlertDialog>
  );
};

export default CustomerAddDialog;
