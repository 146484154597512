import Axios from "axios";
import React from "react";
import { toast } from "react-toastify";
import AuthContainer from "../../../components/AuthContainer";
import AppContext from "../../../context/store";
import Button from "../../../theme/Button";
import AlertDialog from "../../../theme/CustomMUI/AlertDialog";
import Input from "../../../theme/CustomMUI/Input";
import Select from "../../../theme/CustomMUI/Select";
import { Column, Grid } from "../../../theme/Grid";
import Table from "../../../theme/Table";
import { Alert } from "@material-ui/lab";

class Bounty extends React.Component {
  static contextType = AppContext;

  state = {
    loaded: false,
    pagination: {
      page: 1,
      total_page: null,
      onChange: (page) => this.getBouties(page),
    },
    bounties: [],

    staffs: [],
    selectedStaff: null,

    categories: [],
    selectedCategory: null,

    services: [],
    selectedService: null,

    bounty: null,

    delete_id: null,

    isAddBountyButtonClicked: false, // Control that button is checked to stop sending too request.

    open_bounty_delete_dialog: false,
  };

  getBouties = (page = 1) => {
    Axios.get(
      `${this.context.api_endpoint}/company/services/bounty/list?page=${page}`
    ).then((response) => {
      this.setState({
        loaded: true,
        bounties: [...response.data.data.records],
      });
    });
  };

  addBounty = () => {
    Axios.post(`${this.context.api_endpoint}/company/services/bounty/insert`, {
      bounty: this.state.bounty,
      service_id: this.state.selectedService.id,
      staff_id: this.state.selectedStaff.id,
    })
      .then((response) => {
        if (response.status === 201) {
          this.setState({ isAddBountyButtonClicked: false });
          toast.success(
            this.context.t(`['services/bounty'].addBountySuccessToast`)
          );
          this.getBouties();
          this.setState({
            selectedStaff: null,
            selectedCategory: null,
            selectedService: null,
            bounty: null,
          });
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
        this.setState({ isAddBountyButtonClicked: false });
      });
  };

  deleteBounty = (id) => {
    Axios.delete(
      `${this.context.api_endpoint}/company/services/bounty/delete/${id}`
    )
      .then((response) => {
        toast.success(
          this.context.t(`['services/bounty'].deleteBountySuccessToast`)
        );
        this.getBouties();
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  getStaffs = () => {
    Axios.get(`${this.context.api_endpoint}/company/staffs/unsafe`).then(
      ({ data }) => {
        this.setState({
          staffs: [
            ...data.data.map((staff) => ({
              id: staff.id,
              full_name: `${staff.name} ${staff.surname}`,
            })),
          ],
        });
      }
    );
  };

  getServiceNames = async (key) => {
    this.state.selectedCategory &&
      (await Axios.get(
        `${this.context.api_endpoint}/company/platform/services/list/all`,
        {
          params: {
            categoryid: this.state.selectedCategory.id,
            name: key,
          },
        }
      )
        .then(async (response) => {
          const { data } = response;
          await this.setState({ services: [...data.data] });
        })
        .catch((e) => {
          if (e.response.status === 401) {
            toast.error(e.response.data.message);
          } else {
            toast.warning(e.response.data.message);
          }
        }));

    return this.state.services;
  };

  getServices = () => {
    Axios.get(
      `${this.context.api_endpoint}/company/services/with/category/all`
    ).then(async ({ data }) => {
      this.setState({
        categories: [...data.data.filter((m) => m.services.length !== 0)],
      });
    });
  };

  componentDidMount() {
    this.getBouties();
    this.getStaffs();
    this.getServices();
  }

  render() {
    const SelectionBounty = this.SelectionBounty;
    return (
      <AuthContainer limited_permission="services">
        <Grid>
          <Column className="xs-12 sm-12 md-12">
            <Alert severity="info">
              <b>{this.context.t(`['services/bounty'].bountyDescription`)}</b>
            </Alert>
          </Column>
          <Column className="xs-12 sm-12 md-12">
            <h2>{this.context.t(`['services/bounty'].addBountyTitle`)}</h2>
          </Column>
        </Grid>
        <Grid className="no-gutters-all">
          <Column className="xs-12 sm-12 md-12 xl-6">
            <SelectionBounty />
          </Column>
          <Column className="xs-12 sm-12 md-12 xl-6">
            <Table
              refreshAction={() => this.getBouties()}
              loaded={this.state.loaded}
              headings={{
                bounty: {
                  label: this.context.t(`['services/bounty'].headings.bounty`),
                  style: { width: 150 },
                },
                "service.name": {
                  label: this.context.t(
                    `['services/bounty'].headings.serviceName`
                  ),
                  limited_line: 3,
                  style: { width: "200px" },
                },
                "staff.full_name": {
                  label: this.context.t(
                    `['services/bounty'].headings.staffName`
                  ),
                },
                _: { label: this.context.t(`component.actionHeadingText`) },
              }}
              rows={this.state.bounties}
              pagination={this.state.pagination}
              buttons={[
                {
                  title: this.context.t(
                    `['services/bounty'].headings.deleteBountyButtonTitle`
                  ),
                  icon: "delete",
                  textColor: "red",
                  transitionEffect: true,
                  pushEffect: true,
                  disabled: this.context.state.user.permission === 0,
                  onClick: (row) =>
                    this.setState({
                      delete_id: row.id,
                      open_bounty_delete_dialog: true,
                    }),
                },
              ]}
            />
          </Column>
        </Grid>
        <AlertDialog
          title={this.context.t(`['services/bounty'].deleteBountyDialog.title`)}
          open={this.state.open_bounty_delete_dialog}
          closeHandler={() =>
            this.setState({ open_bounty_delete_dialog: false })
          }
          buttons={[
            {
              title: this.context.t(
                `['services/bounty'].deleteBountyDialog.confirmButtonTitle`
              ),
              icon: "check",
              backgroundColor: "primary-opacity",
              textColor: "primary",
              timer: 5,
              onClick: () => this.deleteBounty(this.state.delete_id),
            },
            {
              title: this.context.t(
                `['services/bounty'].deleteBountyDialog.discardButtonTitle`
              ),
              icon: "close",
              textColor: "grey",
            },
          ]}
        >
          <Alert severity="warning">
            <b>
              {this.context.t(
                `['services/bounty'].deleteBountyDialog.alertBoldText`
              )}
            </b>
            <br />
            {this.context.t(`['services/bounty'].deleteBountyDialog.alertText`)}
          </Alert>
        </AlertDialog>
      </AuthContainer>
    );
  }

  SelectionBounty = (index) => {
    return (
      <React.Fragment>
        <Column className="xs-12 sm-12 md-6">
          <Select
            label={this.context.t(`['services/bounty'].staffInputLabel`)}
            items={this.state.staffs}
            selected={
              this.state.selectedStaff ? this.state.selectedStaff.id : null
            }
            labelKey="full_name"
            valueKey="id"
            returnFullObject
            handler={(selected_staff) =>
              this.setState({ selectedStaff: selected_staff })
            }
          />
        </Column>
        <Column className="xs-12 sm-12 md-6">
          <Select
            label={this.context.t(`['services/bounty'].categoryInputLabel`)}
            items={this.state.categories}
            labelKey="name"
            valueKey="id"
            selected={
              this.state.selectedCategory !== null
                ? `${this.state.selectedCategory.id}`
                : ""
            }
            returnFullObject
            handler={(selected_category) =>
              this.setState({
                selectedCategory: selected_category,
              })
            }
          />
        </Column>
        <Column className="xs-12 sm-12 md-6">
          <Select
            label={this.context.t(`['services/bounty'].serviceInputLabel`)}
            items={
              this.state.selectedCategory
                ? this.state.selectedCategory.services
                : []
            }
            labelKey="name"
            valueKey="id"
            selected={
              this.state.selectedService !== null
                ? `${this.state.selectedService.id}`
                : ""
            }
            returnFullObject
            disabled={this.state.selectedCategory === null}
            handler={(selected_service) =>
              this.setState({
                selectedService: selected_service,
              })
            }
          />
        </Column>
        <Column className="xs-12 sm-12 md-3 xl-6">
          <Input
            required
            label={`${this.context.t(
              `['services/bounty'].bountyAmountInputLabel`
            )}(%)`}
            value={this.state.bounty ?? ""}
            //helperText={this.context.t(`['services/detail'].amountInputHint`)}
            hoverHelperText={this.context.t(
              `['services/detail'].amountInputHint`
            )}
            type="number"
            InputProps={{
              min: 0,
              step: 1,
            }}
            onChange={(e) =>
              this.setState({
                bounty:
                  e.target.value.length > 0 && parseInt(e.target.value) >= 0
                    ? parseInt(e.target.value)
                    : null,
              })
            }
          />
        </Column>
        <Column className="xs-12 sm-12 md-12 xl-12">
          <Button
            title={this.context.t(`['services/bounty'].addBountyTitle`)}
            backgroundColor="primary-opacity"
            textColor="primary"
            size="lg"
            fullWidth={true}
            disabled={
              this.state.isAddBountyButtonClicked ||
              this.state.selectedStaff === null ||
              this.state.selectedCategory === null ||
              this.state.selectedService === null ||
              this.state.bounty === null
            }
            onClick={() => {
              this.setState({ isAddBountyButtonClicked: true });
              this.addBounty();
            }}
          />
        </Column>
      </React.Fragment>
    );
  };
}

export default Bounty;
