import React from "react";
import AppContext from "../../context/store";

import { Grid, Column } from "../../theme/Grid";
import styled from "styled-components";

// import SemiCirclePie from "../../components/Charts/SemiCirclePie";

class NotFound extends React.Component {
  static contextType = AppContext;

  render() {
    return (
      <Grid>
        <Column className="xs-12 sm-12">
          <Container404
            className={
              this.context.state.is_authenticated === false ? "mt-5" : null
            }
          >
            <Image404 src={require("../../assets/images/undraw/404.svg")} />
            <InfoCaption>{this.context.t('notFound.heroHeadingText')}</InfoCaption>
            <InfoText>
              <b>{this.context.t('notFound.boldText')} <br />{this.context.t('notFound.text')}</b>
            </InfoText>
          </Container404>
        </Column>
      </Grid>
    );
  }
}

export default NotFound;

const Container404 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const Image404 = styled.img`
  width: 100%;
  height: 120px;
  object-fit: contain;
  margin-bottom: 32px;
`;

const InfoCaption = styled.h2`
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 0;
`;

const InfoText = styled.p`
  font-size: 15px;
  font-weight: 300;
  line-height: 2;
  padding: 0;
  margin: 0 0 16px;

  b {
    font-weight: 500;
  }
`;
