import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import CheckIcon from "@material-ui/icons/Check";

import AppContext from "../context/store";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableCell: {
    borderBottom: "1px solid #dddddd",
    borderRight: "1px solid #dddddd",
    textAlign: "center",
  },
  lastCell: {
    borderBottom: "1px solid #dddddd",
    textAlign: "center",
  },
});

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

// Online Randevu Sitesi
export default function WalletTable() {
  const classes = useStyles();
  const context = useContext(AppContext);
  const rows = [
    createData(
      context.t("licenseDetails.includesAllLicence")[5],
      <CheckIcon style={{ fontSize: 24 }} />,
      <CheckIcon style={{ fontSize: 24, color: "#FF8C00" }} />,
      <CheckIcon style={{ fontSize: 24, color: "#597088" }} />,
      <CheckIcon style={{ fontSize: 24, color: "#123456" }} />
    ),
    createData(
      context.t("licenseDetails.includesAllLicence")[1],
      <CheckIcon style={{ fontSize: 24 }} />,
      <CheckIcon style={{ fontSize: 24, color: "#FF8C00" }} />,
      <CheckIcon style={{ fontSize: 24, color: "#597088" }} />,
      <CheckIcon style={{ fontSize: 24, color: "#123456" }} />
    ),
    createData(
      context.t("licenseDetails.includesAllLicence")[2],
      <CheckIcon style={{ fontSize: 24 }} />,
      <CheckIcon style={{ fontSize: 24, color: "#FF8C00" }} />,
      <CheckIcon style={{ fontSize: 24, color: "#597088" }} />,
      <CheckIcon style={{ fontSize: 24, color: "#123456" }} />
    ),
    createData(
      context.t("licenseDetails.includesAllLicence")[3],
      <CheckIcon style={{ fontSize: 24 }} />,
      <CheckIcon style={{ fontSize: 24, color: "#FF8C00" }} />,
      <CheckIcon style={{ fontSize: 24, color: "#597088" }} />,
      <CheckIcon style={{ fontSize: 24, color: "#123456" }} />
    ),
    createData(
      context.t("licenseDetails.includesAllLicence")[4],
      <CheckIcon style={{ fontSize: 24 }} />,
      <CheckIcon style={{ fontSize: 24, color: "#FF8C00" }} />,
      <CheckIcon style={{ fontSize: 24, color: "#597088" }} />,
      <CheckIcon style={{ fontSize: 24, color: "#123456" }} />
    ),
    createData(
      context.t("licenseDetails.includesAllLicence")[0],
      <CheckIcon style={{ fontSize: 24 }} />,
      <CheckIcon style={{ fontSize: 24, color: "#FF8C00" }} />,
      <CheckIcon style={{ fontSize: 24, color: "#597088" }} />,
      <CheckIcon style={{ fontSize: 24, color: "#123456" }} />
    ),
    createData(
      context.t("licenseDetails.includesAllLicence")[6],
      <CheckIcon style={{ fontSize: 24 }} />,
      <CheckIcon style={{ fontSize: 24, color: "#FF8C00" }} />,
      <CheckIcon style={{ fontSize: 24, color: "#597088" }} />,
      <CheckIcon style={{ fontSize: 24, color: "#123456" }} />
    ),
    createData(
      context.t("licenseDetails.includesAllLicence")[7],
      <CheckIcon style={{ fontSize: 24 }} />,
      <CheckIcon style={{ fontSize: 24, color: "#FF8C00" }} />,
      <CheckIcon style={{ fontSize: 24, color: "#597088" }} />,
      <CheckIcon style={{ fontSize: 24, color: "#123456" }} />
    ),
    createData(
      context.t("licenseDetails.diamond")[9],
      "",
      <CheckIcon style={{ fontSize: 24, color: "#FF8C00" }} />,
      <CheckIcon style={{ fontSize: 24, color: "#597088" }} />,
      <CheckIcon style={{ fontSize: 24, color: "#123456" }} />
    ),
    createData(
      context.t("licenseDetails.diamond")[11],
      "",
      <CheckIcon style={{ fontSize: 24, color: "#FF8C00" }} />,
      <CheckIcon style={{ fontSize: 24, color: "#597088" }} />,
      <CheckIcon style={{ fontSize: 24, color: "#123456" }} />
    ),
    createData(
      context.t("licenseDetails.diamond")[12],
      "",
      <CheckIcon style={{ fontSize: 24, color: "#FF8C00" }} />,
      <CheckIcon style={{ fontSize: 24, color: "#597088" }} />,
      <CheckIcon style={{ fontSize: 24, color: "#123456" }} />
    ),

    createData(
      context.t("licenseDetails.diamond")[6],
      "",
      "",
      <CheckIcon style={{ fontSize: 24, color: "#597088" }} />,
      <CheckIcon style={{ fontSize: 24, color: "#123456" }} />
    ),
    createData(
      context.t("licenseDetails.diamond")[7],
      "",
      "",
      <CheckIcon style={{ fontSize: 24, color: "#597088" }} />,
      <CheckIcon style={{ fontSize: 24, color: "#123456" }} />
    ),
    createData(
      context.t("licenseDetails.diamond")[8],
      "",
      "",
      <CheckIcon style={{ fontSize: 24, color: "#597088" }} />,
      <CheckIcon style={{ fontSize: 24, color: "#123456" }} />
    ),
    createData(
      context.t("licenseDetails.diamond")[10],
      "",
      "",
      <CheckIcon style={{ fontSize: 24, color: "#597088" }} />,
      <CheckIcon style={{ fontSize: 24, color: "#123456" }} />
    ),
    createData(
      context.t("licenseDetails.diamond")[2],
      "",
      "",
      "",
      <CheckIcon style={{ fontSize: 24, color: "#123456" }} />
    ),
    createData(
      context.t("licenseDetails.diamond")[1],
      "",
      "",
      "",
      <CheckIcon style={{ fontSize: 24, color: "#123456" }} />
    ),
    createData(
      context.t("licenseDetails.diamond")[13],
      "",
      "",
      "",
      <CheckIcon style={{ fontSize: 24, color: "#123456" }} />
    ),
    createData(
      context.t("licenseDetails.diamond")[14],
      "",
      "",
      "",
      <CheckIcon style={{ fontSize: 24, color: "#123456" }} />
    ),
    createData(
      context.t("licenseDetails.diamond")[15],
      "",
      "",
      "",
      <CheckIcon style={{ fontSize: 24, color: "#123456" }} />
    ),
    createData(
      context.t("licenseDetails.diamond")[16],
      "",
      "",
      "",
      <CheckIcon style={{ fontSize: 24, color: "#123456" }} />
    ),
  ];
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell
              style={{ fontSize: "20px", fontWeight: "bold" }}
              className={classes.tableCell}
            >
              {context.t("licenseDetails.features")}
            </TableCell>
            <TableCell
              style={{ fontSize: "18px", fontWeight: "bold" }}
              align="center"
              className={classes.tableCell}
            >
              Silver
            </TableCell>
            <TableCell
              style={{
                backgroundColor: "#ffbf00",
                fontSize: "18px",
                fontWeight: "bold",
              }}
              align="center"
              className={classes.tableCell}
            >
              Gold
            </TableCell>
            <TableCell
              style={{
                backgroundColor: "#E6F2FD",
                fontSize: "18px",
                fontWeight: "bold",
              }}
              align="center"
              className={classes.tableCell}
            >
              Platinium
            </TableCell>
            <TableCell
              style={{
                backgroundColor: "#b8d8e7",
                fontSize: "18px",
                fontWeight: "bold",
              }}
              align="center"
              className={`${classes.tableCell} ${classes.lastCell}`}
            >
              Diamond
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.name}>
              <TableCell
                component="th"
                scope="row"
                className={classes.tableCell}
              >
                {row.name}
              </TableCell>
              <TableCell align="center" className={classes.tableCell}>
                {row.calories}
              </TableCell>
              <TableCell align="center" className={classes.tableCell}>
                {row.fat}
              </TableCell>
              <TableCell align="center" className={classes.tableCell}>
                {row.carbs}
              </TableCell>
              <TableCell
                align="center"
                className={`${classes.tableCell} ${classes.lastCell}`}
              >
                {row.protein}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
