import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import AppContext from "../../../context/store";
import AuthContainer from "../../../components/AuthContainer";
import styled from "styled-components";

import Button from "../../../theme/Button";

import { Grid, Column } from "../../../theme/Grid";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import "../../../assets/style/views/Employees.scss";
import Axios from "axios";
import { toast } from "react-toastify";
import StaffOrderDialog from "../../../components/Dialogs/StaffOrderDialog";
import { Tooltip } from "@material-ui/core";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";

const EmployeesIndex = () => {
  const theme = createTheme({
    overrides: {
      MuiTooltip: {
        tooltip: {
          color: "#3189ec",
          fontSize: "12px",
          backgroundColor: "white",
          boxShadow: "0px 0px 5px rgba(0,0,0,0.1)",
        },
      },
    },
  });

  const context = useContext(AppContext);
  const history = useHistory();

  const [employees, setEmployees] = useState([...Array(6).keys()]);
  const [loaded, setLoaded] = useState(false);
  const [pagination, setPagination] = useState({
    page: null,
    total_page: 1,
  });

  const [limitResult, SetLimitResult] = useState(false);
  const [limitMessage, SetLimitMessage] = useState("");

  // const [totalEmployeeRecord, setTotalEmployeeRecord] = useState(null);
  const [allEmployees, setAllEmployees] = useState([]);
  const [open_order_staff_dialog, setOpen_order_staff_dialog] = useState(false);

  const getEmployeesLimit = async () => {
    Axios.get(`${context.api_endpoint}/company/staffs/limit/control`)
      .then((data) => {
        SetLimitResult(data.data.data.result);
        SetLimitMessage(data.data.data.message);
      })
      .catch((err) => console.log(err));
  };

  const getStaffs = async () => {
    Axios.get(
      `${context.api_endpoint}/company/staffs?page=${pagination.page ?? 1}`
    )
      .then(async ({ data }) => {
        setLoaded(true);
        setEmployees([...data.data.records]);
        setPagination({
          ...pagination,
          total_page: data.data.records.length,
          page: data.data.page,
        });
        // setTotalEmployeeRecord(data.data.records);
        getOrderedStaffs();
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  const getAllStaffs = async () => {
    await Axios.get(`${context.api_endpoint}/company/staffs/unsafe`).then(
      (response) => {
        setAllEmployees([...response.data.data.map((staff) => staff)]);
      }
    );
  };

  const getOrderedStaffs = () => {
    Axios.get(`${context.api_endpoint}/company/appointments/order/list`).then(
      (response) => {
        if (response.data.data.length !== 0) {
          setAllEmployees([...response.data.data.map((staff) => staff.staff)]);
        } else {
          getAllStaffs();
        }
      }
    );
  };

  const updateStaffOrder = (orderedList) => {
    Axios.post(`${context.api_endpoint}/company/appointments/order/save`, {
      order_list: [...orderedList],
    })
      .then(async (response) => {})
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  useEffect(() => {
    getStaffs();
    getEmployeesLimit();
  }, []);

  return (
    <AuthContainer authorities={[1]} limited_permission="system">
      <Grid>
        <Column className="xs-12 sm-12 md-6 lg-4 xl-3">
          <div className="employee-card">
            <MuiThemeProvider theme={theme}>
              <Tooltip title={<div>{limitMessage}</div>} placement="top">
                <div>
                  <Button
                    title={context.t(`employees.createNewEmployee`)}
                    textColor="white"
                    backgroundColor="primary"
                    disabled={
                      (context.state.user.permission === 2 &&
                        context.state.limited_permission.system == 1) ||
                      !limitResult
                    }
                    onClick={() => {
                      history.push("/settings/employees/add");
                    }}
                  />
                </div>
              </Tooltip>
            </MuiThemeProvider>
            <Button
              title={context.t(`employees.orderStaffs`)}
              textColor="white"
              backgroundColor="blue"
              className="mt-2"
              disabled={
                context.state.user.permission === 2 &&
                context.state.limited_permission.system === 1
              }
              onClick={() => {
                setOpen_order_staff_dialog(true);
              }}
            />
          </div>
        </Column>
        {employees.map((employee, index) => {
          return loaded && employee.detail ? (
            <Column className="xs-12 sm-12 md-6 lg-4 xl-3" key={employee.id}>
              <div className="employee-card">
                <div className="img-circle">
                  <img
                    src={
                      employee.detail.profile_photo
                        ? `${context.api_endpoint}/${context.state.company_id}/${employee.detail.profile_photo}`.replace(
                            "api/v1/",
                            ""
                          )
                        : employee.sex === 0
                        ? require("../../../assets/images/staff_woman.jpg")
                        : employee.sex === 1
                        ? require("../../../assets/images/staff_man.jpg")
                        : require("../../../assets/images/profile_photo.svg")
                    }
                    alt=""
                  />
                </div>
                <span className="employee-name">{employee.full_name}</span>
                <span className="job-title">{employee.detail.job_title}</span>
                <div className="edit-button mt-1">
                  <Button
                    title={context.t(`employees.editButtonTitle`)}
                    icon="settings"
                    backgroundColor="white"
                    textColor="primary"
                    pushEffect={true}
                    onClick={() =>
                      history.push(`/settings/employees/detail/${employee.id}`)
                    }
                  />
                </div>
              </div>
            </Column>
          ) : (
            <Column className="xs-12 sm-12 md-6 lg-4 xl-3" key={index}>
              <div className="employee-card">
                <SkeletonImageCircle className="skeleton" />
                <SkeletonEmployeeName className="skeleton" />
                <SkeletonJobTitle className="skeleton" />
                <SkeletonActionButton className="skeleton" />
              </div>
            </Column>
          );
        })}
      </Grid>
      <Grid>
        <PaginationContainer>
          {/* <Pagination
            count={pagination.total_page}
            page={pagination.page ?? 1}
            onChange={async (event, value) => {
              //window.history.pushState({ path: "" }, "", "?page=" + value);
              await this.setState({
                pagination: { ...pagination, page: value },
              });
              this.getStaffs();
            }}
          /> */}
          <div className="pagination p7">
            <ul>
              <button
                disabled={pagination.page < 2}
                onClick={() => {
                  setPagination({
                    ...pagination,
                    page: pagination.page--,
                  });
                  getStaffs();
                }}
              >
                <li>
                  <ArrowBackIosIcon className="icon-back" fontSize="small" />
                </li>
              </button>
              <button
                disabled={employees.length < 10}
                onClick={() => {
                  setPagination({
                    ...pagination,
                    page: pagination.page++,
                  });
                  getStaffs();
                }}
              >
                <li>
                  <ArrowForwardIosIcon fontSize="small" />
                </li>
              </button>
            </ul>
          </div>
        </PaginationContainer>
      </Grid>
      <StaffOrderDialog
        open={open_order_staff_dialog}
        staffs={allEmployees}
        orderedHandler={(orderedList) => updateStaffOrder(orderedList)}
        closeHandler={() => {
          setOpen_order_staff_dialog(false);
        }}
      />
    </AuthContainer>
  );
};

const SkeletonImageCircle = styled.div`
  width: 56px;
  height: 56px;
  border-radius: 30px;
  margin-bottom: 8px;
`;

const SkeletonEmployeeName = styled.div`
  width: 100px;
  max-width: 100%;
  height: 20px;
  margin-bottom: 4px;
`;

const SkeletonJobTitle = styled.div`
  width: 124px;
  height: 10px;
`;

const SkeletonActionButton = styled.div`
  width: 139px;
  height: 20px;
  margin-top: 26px;
  box-sizing: border-box;
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export default EmployeesIndex;
