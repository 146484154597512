import React, { Component } from "react";
import AppContext from "../../../context/store";

import { Column, Grid } from "../../../theme/Grid";
import AuthContainer from "../../../components/AuthContainer";

import "../../../assets/style/component/Form.scss";
import Button from "../../../theme/Button";
import MaskedInput from "../../../theme/CustomMUI/MaskedInput";
import Axios from "axios";
import { toast } from "react-toastify";
import Validate from "../../../functions/Validate";
import moment from "moment";

class WorkHourSettings extends Component {
  static contextType = AppContext;

  state = {
    hours: {
      start_hour: null,
      end_hour: null,
      slice: null,
    },
  };

  componentDidMount() {
    this.getCompanyDetails();
  }

  getCompanyDetails = () => {
    Axios.get(`${this.context.api_endpoint}/company/itself`).then(
      ({ data: { data } }) => {
        this.setState({ hours: { ...data.hours } });
      }
    );
  };

  updateWorkHours = () => {
    const { start_hour, end_hour } = this.state.hours;

    if (this.validateHours())
      if (
        moment(`1970-01-01 ${end_hour}:00`).isAfter(
          moment(`1970-01-01 ${start_hour}:00`)
        )
      )
        Axios.put(`${this.context.api_endpoint}/company/update/hours`, {
          ...this.state.hours,
        })
          .then((response) => {
            if (response.status === 201) {
              this.context.dispatch({
                type: "SET_COMPANY_SHIFT",
                payload: { ...this.state.hours },
              });
              toast.success(this.context.t(`['settings/hours'].updateToast`));
              this.getCompanyDetails();
            }
          })
          .catch((e) => {
            if (e.response.status === 401) {
              toast.error(e.response.data.message);
            }
          });
      else
        toast.warning(this.context.t(`['settings/hours'].compareErrorToast`));
  };

  render() {
    const { hours } = this.state;

    return (
      <AuthContainer authorities={[1]} limited_permission="system">
        <Grid>
          <Column className="xs-12 sm-12">
            <MaskedInput
              label={this.context.t(`['settings/hours'].startInputLabel`)}
              mask="99:99"
              maskChar=""
              type="text"
              value={hours.start_hour !== null ? hours.start_hour : ""}
              onChange={(e) =>
                this.setState({
                  hours: { ...hours, start_hour: e.target.value },
                })
              }
            />

            <MaskedInput
              label={this.context.t(`['settings/hours'].endInputLabel`)}
              mask="99:99"
              maskChar=""
              type="text"
              value={hours.end_hour !== null ? hours.end_hour : ""}
              onChange={(e) =>
                this.setState({ hours: { ...hours, end_hour: e.target.value } })
              }
            />

            <MaskedInput
              label={this.context.t(
                `['settings/hours'].periodBetweenInputLabel`
              )}
              mask="999"
              maskChar=""
              type="text"
              value={hours.slice !== null ? hours.slice : ""}
              onChange={(e) =>
                this.setState({
                  hours: {
                    ...hours,
                    slice:
                      e.target.value.substring(0, 1) !== "0"
                        ? e.target.value
                        : null,
                  },
                })
              }
            />

            <Button
              icon="update"
              title={this.context.t(`['settings/hours'].updateButtonTitle`)}
              backgroundColor="primary"
              textColor="white"
              fullWidth={true}
              onClick={() => this.updateWorkHours()}
            />
          </Column>
        </Grid>
      </AuthContainer>
    );
  }

  validateHours = () => {
    const { hours } = this.state;
    return Validate([
      {
        value: hours.start_hour,
        condition: /^([0-1]?[0-9]|[2][0-3]):([0-5][0-9])(:[0-5][0-9])?$/,
        field: this.context.t(`['settings/hours'].startInputLabel`),
      },
      {
        value: hours.end_hour,
        condition: /^([0-1]?[0-9]|[2][0-3]):([0-5][0-9])(:[0-5][0-9])?$/,
        field: this.context.t(`['settings/hours'].endInputLabel`),
      },
      {
        value: hours.slice,
        condition: /^.+$/,
        field: this.context.t(`['settings/hours'].periodBetweenInputLabel`),
      },
    ]);
  };
}

export default WorkHourSettings;
