/* eslint-disable array-callback-return */
import { toast } from "react-toastify";

const validify = require("validify");
// const CryptoJS = require("crypto-js");

const Validate = (fields) => {
  if (fields.length === 0) {
    return false;
  }

  let fieldsArray = fields.map((field) => {
    if (
      ((field.value === "" || field.value === null) &&
        field.type === "email") ||
      ((field.value === "" || field.value === null) &&
        field.type === "password")
    ) {
      toast.error("Lütfen gerekli alanları doldurun.");
      return false;
    } else {
      if (field.type === "email") {
        if (field.required) {
          if (field.value === "") {
            toast.error(
              "Girdiğiniz e-posta veya cep telefonu numarası bir hesaba bağlı değil."
            );
            return false;
          }
        }
        if (!validify(field.value, "email")) {
          toast.error(`${field.label} must be a valid email`);
          return false;
        }
      } else if (field.type === "password") {
        if (field.required) {
          if (field.value === null) {
            toast.error("Girdiğiniz şifre yanlış.");
            return false;
          }
        }
        if (!validify(field.value, "password")) {
          toast.error(`${field.label} must be a valid password`);
          return false;
        }
      }
    }
  });

  return fieldsArray[0] === undefined && fieldsArray[1] === undefined;
};

export default Validate;
